import { FC } from 'react';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  convertDecToWei,
  convertWeiToDec,
  formatWeiNumber,
} from 'src/utils/utils-formats';
import rf from 'src/requests/RequestFactory';
import { processTransaction } from 'src/store/transactions';
import useAuth from 'src/hooks/useAuth';
import { RootState } from 'src/store';
import { NOT_AVAILABLE_TEXT } from 'src/utils/common';
import { makeAppointWithdrawParams } from 'src/utils/utils-farm';
import { AppBroadcast } from 'src/utils/utils-broadcast';
import AppInputTokenMax from 'src/components/AppInputTokenMax';
import BigNumber from 'bignumber.js';
import styles from 'src/styles/modals/ModalUnStakeFarm.module.scss';
import BaseModal from './BaseModal';

interface ModalUnStakeFarmProps {
  open: boolean;
  poolAddress?: string;
  tokenDetails?: any;
  stakingAmount: string | BigNumber;
  reloadData?: () => void;
  onClose?: () => void;
}

const ModalUnStakeFarm: FC<ModalUnStakeFarmProps> = ({
  open,
  poolAddress,
  tokenDetails,
  stakingAmount,
  reloadData,
  onClose,
}) => {
  const { user } = useAuth();

  const { tiers } = useSelector((state: RootState) => state.metadata);
  const { myTier } = useSelector((state: RootState) => state.myAccount);
  const dispatch = useDispatch();

  const [delayTime, setDelayTime] = useState('0');
  const [amount, setAmount] = useState('');

  useEffect(() => {
    const delay =
      _.find(tiers, (i: any) => Number(i.level) === Number(myTier?.level))
        ?.withdrawDelay || NOT_AVAILABLE_TEXT;
    setDelayTime(delay);
  }, [tiers, myTier]);

  const unStake = async () => {
    try {
      if (
        !amount ||
        !user ||
        !poolAddress ||
        !ethers.utils.isAddress(poolAddress)
      ) {
        return;
      }

      const appointInfo = await rf
        .getRequest('TransactionRequest')
        .appointWithdraw({
          userAddress: user.getAddress(),
          amount: convertDecToWei(amount),
        });

      const params = makeAppointWithdrawParams(poolAddress, appointInfo);
      setAmount('');
      handleClose();
      await dispatch(
        processTransaction({ provider: user?.getProvider(), params }),
      );
      AppBroadcast.dispatch('LOAD_USER_INFORMATION');
      reloadData && reloadData();
    } catch (err) {
      console.log(err);
    }
  };

  const ItemInfo = ({ name = '', value = '' }) => {
    return (
      <div className={styles['item']}>
        <div className={styles['label']}>
          <span className={styles['adornment']}></span>
          <div className={styles['name']}>{name}</div>
        </div>
        <div className={styles['value']}>{value}</div>
      </div>
    );
  };

  const changeAmountInput = (value: string) => {
    if (+value < 0) {
      setAmount('0');
      return;
    }

    if (+value > +convertWeiToDec(stakingAmount, tokenDetails?.decimals)) {
      setAmount(convertWeiToDec(stakingAmount, tokenDetails?.decimals));
      return;
    }

    setAmount(value);
  };

  const handleClose = () => {
    onClose && onClose();
    setAmount('');
  };

  return (
    <BaseModal
      open={open}
      title={'Unstake BPT Token'}
      onClose={handleClose}
      onActionLeft={handleClose}
      onActionRight={unStake}
      fullScreenMobile
    >
      <>
        <div className={styles['list-info']}>
          <ItemInfo name="Token" value={tokenDetails?.symbol} />
          <ItemInfo
            name={`${tokenDetails?.symbol} staked in the pool`}
            value={formatWeiNumber(stakingAmount, tokenDetails?.decimals)}
          />
          <ItemInfo name="Withdraw delay" value={delayTime} />
        </div>
        <div className={styles['note']}>
          Please note that if you have received allocation of an IDO project
          corresponding with a Tier and unstaking lowers your Tier before the
          sales is complete, you would be ineligible for joining any IDO pool of
          that project.
        </div>
        <div className={styles['box-input']}>
          <div className={styles['label-input-max']}>Unstake amount</div>
          <AppInputTokenMax
            tokenDetails={tokenDetails}
            value={amount}
            handleChange={changeAmountInput}
            icon={
              tokenDetails?.symbol?.includes('BUNI') ? (
                <img src="/images/BuniLogo.svg" alt="" />
              ) : (
                ''
              )
            }
            maxValue={convertWeiToDec(stakingAmount)}
          />
        </div>
      </>
    </BaseModal>
  );
};

export default ModalUnStakeFarm;
