import { FC, useEffect, useState } from 'react';
import PartAuctionPriceChart from 'src/pages/PageAuctionCreate/parts/PartAuctionPriceChart';
import styles from 'src/styles/pages/LBPDetails/LBPChart.module.scss';
import {
  AuctionPriceChartData,
  calcAuctionChartData,
  IAuctionResponseType,
  ITokenBase,
  ITokenMain,
  SwapListResponseType,
} from 'src/utils/utils-auction';
import rf from 'src/requests/RequestFactory';
import moment from 'moment';
import { checkIsTokenBase } from 'src/utils/utils-token';
import { convertWeiToDec } from 'src/utils/utils-formats';

interface PartLBPChartProps {
  auction: IAuctionResponseType;
  mainToken: ITokenMain;
  baseToken: ITokenBase;
  mainTokenIndex: number;
  baseTokenIndex: number;
  balanceStart: string[];
  balanceCurrent: string[];
  tokenWeights: string[];
  swapFee: string;
}

const PartLBPChart: FC<PartLBPChartProps> = ({
  auction,
  mainToken,
  baseToken,
  mainTokenIndex,
  baseTokenIndex,
  balanceStart,
  balanceCurrent,
  tokenWeights,
  swapFee,
}) => {
  const [chartData, setChartData] = useState<AuctionPriceChartData[]>([]);

  const getPrice = (swapHistory: SwapListResponseType): number => {
    if (checkIsTokenBase(swapHistory.tokenIn.address, swapHistory.network)) {
      return +swapHistory.tokenAmountIn / +swapHistory.tokenAmountOut;
    }
    return +swapHistory.tokenAmountOut / +swapHistory.tokenAmountIn;
  };

  const fetchData = async () => {
    if (
      !auction ||
      balanceCurrent.length <= 0 ||
      tokenWeights.length <= 0 ||
      !swapFee
    ) {
      return;
    }

    const result = await rf.getRequest('AuctionsRequest').getSwapList({
      network: auction.network,
      poolId: auction.pool.id,
      limit: 100,
    });

    if (result.docs) {
      const predictedPriceWithoutSwapData = calcAuctionChartData(
        +balanceStart[baseTokenIndex],
        +balanceStart[mainTokenIndex],
        +convertWeiToDec(
          auction.pool.startWeights[mainTokenIndex].toString(),
          mainToken.decimals,
        ),
        +convertWeiToDec(
          auction.pool.startWeights[baseTokenIndex].toString(),
          baseToken.decimals,
        ),
        new Date(auction.pool.startTime * 1000),
        new Date(auction.pool.endTime * 1000),
        Number(auction.pool.swapFee),
      );
      if (result.docs.length === 0) {
        setChartData(predictedPriceWithoutSwapData);
      } else {
        const historyData: AuctionPriceChartData[] = result.docs
          .reverse()
          .map((item: any) => ({
            time: item.timestamp * 1000,
            value: getPrice(item),
            isHistory: true,
          }));

        const lastHistoryData = historyData[historyData.length - 1];
        const predictedPriceData = calcAuctionChartData(
          +balanceCurrent[baseTokenIndex],
          +balanceCurrent[mainTokenIndex],
          +convertWeiToDec(
            tokenWeights[mainTokenIndex].toString(),
            mainToken.decimals,
          ),
          +convertWeiToDec(
            tokenWeights[baseTokenIndex].toString(),
            baseToken.decimals,
          ),
          new Date(moment(lastHistoryData.time).add(1, 'h').valueOf()),
          new Date(auction.pool.endTime * 1000),
          Number(auction.pool.swapFee),
        );
        const intergratedPriceData: AuctionPriceChartData[] = [
          {
            time: predictedPriceWithoutSwapData[0].time,
            value: predictedPriceWithoutSwapData[0].value,
            isHistory: true,
          },
          ...historyData,
          ...predictedPriceData,
        ];
        setChartData(intergratedPriceData);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [auction, balanceCurrent, tokenWeights, swapFee]);

  return (
    <div className={styles['lbp-detail-chart']}>
      <PartAuctionPriceChart
        id="historySwap"
        priceData={chartData}
        symbol={auction.pool.name}
        hasLegends
        isCreate={false}
      />
    </div>
  );
};

export default PartLBPChart;
