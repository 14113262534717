import { useState, useMemo, useEffect } from 'react';
import { LinearProgress } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';
import BigNumber from 'bignumber.js';
import styles from 'src/styles/components/CardPurchaseToken.module.scss';
import {
  convertWeiToDec,
  formatNumber,
  formatTimestamp,
  formatWeiNumber,
} from 'src/utils/utils-formats';
import AppButton from 'src/components/AppButton';
import useAuth from 'src/hooks/useAuth';
import { isMobile } from 'react-device-detect';
import useIDOPool from 'src/hooks/useIDOPool';
import { getCollateralBalance } from 'src/utils/utils-pool';
import { AppBroadcast } from 'src/utils/utils-broadcast';
import { RELOAD_PURCHASED_TOKENS, RELOAD_SOLD_TOKENS } from './PoolContents';
import { IDOPool } from 'src/utils/pool';
import AppInputTokenMax from 'src/components/AppInputTokenMax';
import { UserInterface } from 'src/utils/user';

interface CardPurchaseTokenProps extends RouteComponentProps {
  idoPool: IDOPool;
  purchasedTokens: number;
  soldTokens: number;
  isRegisteredWhitelist: boolean;
  isModal: boolean;
  maxTokenCanBuy: string;
  handleCloseModal?: () => void;
}

const CardPurchaseToken = (props: CardPurchaseTokenProps) => {
  const {
    idoPool,
    purchasedTokens,
    soldTokens,
    isRegisteredWhitelist,
    isModal,
    maxTokenCanBuy,
    handleCloseModal,
  } = props;

  const timeline = idoPool.getTimeline();
  const { user } = useAuth();
  const { purchaseToken } = useIDOPool();
  const [amountInput, setAmountInput] = useState<string>('');
  const [collateralBalance, setCollateralBalance] = useState<string>('');

  const fetchCollateralBalance = async (
    pool: IDOPool,
    account: UserInterface | null,
  ) => {
    if (!pool || !account) {
      return;
    }
    const balance = await getCollateralBalance(pool, account);
    setCollateralBalance(balance);
  };

  useEffect(() => {
    fetchCollateralBalance(idoPool, user);
  }, [
    user,
    idoPool.getNetwork(),
    idoPool.getCollateralCurrencySymbol(),
    user?.getLinkedProviders(),
  ]);

  const TIME_FORMAT = 'HH:mm:ss, MMM D, YYYY';
  const DECIMAL_SCALE = 6;
  const MAX_LENGTH = 255;

  const estimateNumberOfPurchaseToken = () => {
    const tokens = new BigNumber(amountInput.replaceAll(',', ''))
      .div(new BigNumber(idoPool.getSwapRate()))
      .toNumber();
    const tokenWithDecimal = tokens
      ? new BigNumber(tokens).decimalPlaces(4).toNumber()
      : 0;
    return tokenWithDecimal;
  };

  const maxCurrencyUserCanBuy = useMemo(() => {
    let remainingTokenCanBuy = new BigNumber(maxTokenCanBuy)
      .minus(purchasedTokens)
      .toString();
    const remainingTokenOfPool = new BigNumber(idoPool.getCapacity())
      .minus(soldTokens)
      .toString();
    if (
      new BigNumber(remainingTokenCanBuy).gt(
        new BigNumber(remainingTokenOfPool),
      )
    ) {
      // compare to remaining sold tokens of pool
      remainingTokenCanBuy = new BigNumber(remainingTokenOfPool).toString();
    }
    const remainingCurrency = new BigNumber(
      convertWeiToDec(remainingTokenCanBuy, idoPool.getSwapTokenDecimals()),
    )
      .multipliedBy(idoPool.getSwapRate())
      .toString();
    const userCurrencyBalance = convertWeiToDec(
      collateralBalance,
      idoPool.getCollateralCurrencyDecimals(),
    );
    if (new BigNumber(remainingCurrency).comparedTo(userCurrencyBalance) > 0) {
      return userCurrencyBalance;
    }
    return remainingCurrency;
  }, [maxTokenCanBuy, purchasedTokens, collateralBalance, soldTokens]);

  const onChangeAmountInput = (e: any) => {
    setAmountInput(e);
  };

  const checkDisablePurchaseButton = () => {
    if (timeline?.beforeSwapPhase()) {
      return true;
    }
    if (!isRegisteredWhitelist) {
      return true;
    }
    if (!user || !idoPool.canPurchaseToken(user)) {
      return true;
    }
    if (
      !amountInput ||
      +amountInput <= 0 ||
      +amountInput > +maxCurrencyUserCanBuy
    ) {
      return true;
    }
    return false;
  };

  const _renderSubCardTimeBuyOrSale = (
    { title = '', from = '', to = '' },
    isCurrentPhase: boolean,
  ) => {
    return (
      <div className={styles['sub-card']}>
        <div className={styles['title']}>
          <i className={styles['icon']} />
          <span className={styles['text']}>{title}</span>
        </div>
        <div className={styles['content-text']}>
          <div className={styles['from']}>
            <div className={styles['label']}>From</div>
            <div
              className={`${styles['value']} ${
                isCurrentPhase ? styles['highlight'] : ''
              }`}
            >
              {from}
            </div>
          </div>
          <div className={styles['to']}>
            <div className={styles['label']}>To</div>
            <div
              className={`${styles['value']} ${
                isCurrentPhase ? styles['highlight'] : ''
              }`}
            >
              {to}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const _renderSaleProgress = () => {
    const saleProgress = soldTokens
      ? Math.floor(
          (+convertWeiToDec(
            soldTokens.toString(),
            idoPool.getSwapTokenDecimals(),
          ) /
            +convertWeiToDec(
              idoPool.getCapacity().toString(),
              idoPool.getSwapTokenDecimals(),
            )) *
            100,
        )
      : 0;
    return (
      <div className={styles['sub-card-progress']}>
        <div className={styles['title']}>
          <span className={styles['text']}>
            {`${formatWeiNumber(
              soldTokens.toString(),
              idoPool.getSwapTokenDecimals(),
            )}/${formatWeiNumber(
              idoPool.getCapacity().toString(),
              idoPool.getSwapTokenDecimals(),
            )} tokens sold (${saleProgress}%)`}
          </span>
        </div>
        <LinearProgress
          classes={{
            root: styles['root-progress'],
            bar: styles['bar-progress'],
          }}
          variant="determinate"
          value={saleProgress}
        />
      </div>
    );
  };

  const onPurchaseToken = async () => {
    await purchaseToken(idoPool, user, amountInput);
    await fetchCollateralBalance(idoPool, user);
    AppBroadcast.dispatch(RELOAD_PURCHASED_TOKENS);
    AppBroadcast.dispatch(RELOAD_SOLD_TOKENS);
  };

  const _renderButtonPurchase = () => {
    return (
      <AppButton
        sizes="medium"
        isDisable={checkDisablePurchaseButton()}
        onClick={onPurchaseToken}
      >
        Purchase Token
      </AppButton>
    );
  };

  const _renderSubCardBalanceDesktop = () => {
    return (
      <div
        className={`${styles['sub-card']} ${
          isModal ? styles['card-in-modal'] : ''
        }`}
      >
        <div className={styles['top']}>
          <div className={styles['label']}>Your Wallet Balance</div>
          <div className={styles['value']}>{`${formatWeiNumber(
            collateralBalance,
            idoPool.getCollateralCurrencyDecimals(),
          )} ${idoPool.getCollateralCurrencySymbol()}`}</div>
        </div>
        <div className={styles['middle']}>
          <div className={styles['form-control']}>
            <AppInputTokenMax
              handleChange={onChangeAmountInput}
              icon={<img src={idoPool.getNetworkIcon()} alt="" />}
              tokenDetails={{ symbol: idoPool.getCollateralCurrencySymbol() }}
              decimalScale={DECIMAL_SCALE}
              value={amountInput}
              maxValue={maxCurrencyUserCanBuy}
              maxLength={MAX_LENGTH}
              placeholder="Amount"
            />
          </div>
          <div className={styles['form-control']}>
            <label className={styles['input-control']}>
              <span className={styles['text-input']}>
                {formatNumber(estimateNumberOfPurchaseToken())}
              </span>
              <span className={styles['label']}>
                {idoPool.getSwapTokenSymbol()}
              </span>
            </label>
          </div>
        </div>
        {!isModal ? (
          <div className={styles['bottom']}>{_renderButtonPurchase()}</div>
        ) : (
          <div className={styles['modal-footer']}>
            <div className={styles['row']}>
              <div className={styles['col-btn']}>
                <AppButton
                  variant="outline"
                  sizes="medium"
                  onClick={handleCloseModal}
                >
                  Cancel
                </AppButton>
              </div>
              <div className={styles['col-btn']}>
                <>{_renderButtonPurchase()}</>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const _renderExclusiveSale = () => {
    if (!timeline) {
      return;
    }
    const isPrivateSwapPhase = timeline.isPrivateSwapPhase();
    const { startTime, endTime } = timeline.getPrivateSwapPhase();
    return _renderSubCardTimeBuyOrSale(
      {
        title: 'Time exclusive sale',
        from: formatTimestamp(startTime, TIME_FORMAT),
        to: formatTimestamp(endTime, TIME_FORMAT),
      },
      isPrivateSwapPhase,
    );
  };

  const _renderFCFSSale = () => {
    if (!timeline) {
      return;
    }
    const isPublicSwapPhase = timeline.isPublicSwapPhase();
    const publicSwapPhase = timeline.getPublicSwapPhase();
    if (!publicSwapPhase) {
      return;
    }
    const { startTime, endTime } = publicSwapPhase;
    return idoPool.doesPoolHasFreeBuyPhase() ? (
      _renderSubCardTimeBuyOrSale(
        {
          title: 'Time for FCFS Sale',
          from: formatTimestamp(startTime, TIME_FORMAT),
          to: formatTimestamp(endTime, TIME_FORMAT),
        },
        isPublicSwapPhase,
      )
    ) : (
      <div className={styles['sub-card']}>
        <div className={styles['title']}>
          <span className={`${styles['text']} ${styles['text--warning']}`}>
            This pool does not have FCFS Sale.
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={styles['card']}>
      {isModal ? (
        _renderSubCardBalanceDesktop()
      ) : (
        <>
          <div className={`${styles['sub-card']} ${styles['group-inner']}`}>
            <div className={styles['card-inner']}>
              {_renderExclusiveSale()}
              {_renderFCFSSale()}
              {_renderSaleProgress()}
            </div>
          </div>
          <>{!isMobile && _renderSubCardBalanceDesktop()}</>
        </>
      )}
    </div>
  );
};

export default withRouter(CardPurchaseToken);
