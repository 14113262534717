import config from 'src/config';
import _ from 'lodash';
import {
  BaseProvider,
  FallbackProvider,
  StaticJsonRpcProvider,
} from '@ethersproject/providers';

export const getLogoNetwork = (networkId: string): string => {
  const network = _.find(
    config.networks,
    (network) => network.id.toUpperCase() === networkId.toUpperCase(),
  );
  if (!network) {
    return '';
  }
  return network.icon || '';
};

export const getNetworkConfig = (key: string) => {
  if (!key) {
    return null;
  }
  const network =
    config.networks[key] ||
    _.find(
      config.networks,
      (network) => network.id.toUpperCase() === key.toUpperCase(),
    );
  if (!network) {
    return null;
  }
  return network;
};

export const getNetworkConfigs = () => {
  return Object.values(config.networks) || [];
};

type FallbackProviderType = {
  provider: BaseProvider;
  priority: number;
  stallTimeout: number;
};

export const getNetworkProvider = (network = ''): FallbackProvider => {
  network = network ? network : config.defaultNetwork;
  const networkConfig = getNetworkConfig(network);
  if (!networkConfig) {
    console.error(
      `[getNetworkProvider] throw error: networkConfig ${network} not found`,
    );
  }
  const rpcUrls = _.shuffle(networkConfig?.rpcUrls);

  const providers: FallbackProviderType[] = [];
  rpcUrls.forEach((rpcUrl, index) => {
    const provider: BaseProvider = new StaticJsonRpcProvider(
      rpcUrl,
      networkConfig?.chainId,
    );
    const priority = index + 1;
    providers.push({
      provider,
      priority,
      stallTimeout: 4000 + 100 * priority,
    });
  });

  return new FallbackProvider(providers, 1);
};

export const getSolanaNetworkId = (): string => {
  return process.env.REACT_APP_ENV === 'dev'
    ? 'SOLANA_DEVNET'
    : 'SOLANA_MAINNET';
};

export const isNetworkEVM = (network: string): boolean => {
  if (network === config.networks[getSolanaNetworkId()].id) {
    return false;
  }
  return true;
};
