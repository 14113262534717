import { useEffect } from 'react';
import { Chart, registerables, ScriptableLineSegmentContext } from 'chart.js';
import styles from 'src/styles/pages/LPB/AuctionPriceChart.module.scss';
import moment from 'moment';
import { formatNumber } from 'src/utils/utils-formats';
import { AuctionPriceChartData } from 'src/utils/utils-auction';

Chart.register(...registerables);

interface AuctionDrawData {
  labels: string[];
  data: number[];
}

interface AuctionPriceChartProps {
  id: string;
  priceData: AuctionPriceChartData[];
  isPreview?: boolean;
  hasLegends?: boolean;
  symbol?: string;
  isCreate?: boolean;
}

const PartAuctionPriceChart: React.FC<AuctionPriceChartProps> = (
  props: AuctionPriceChartProps,
) => {
  const {
    id,
    priceData,
    isPreview = false,
    hasLegends = false,
    symbol,
    isCreate = true,
  } = props;

  const CHART_TYPE = 'line';
  const TIME_FORMAT = 'DD MMM';
  const DATASET_COLORS = ['rgba(181, 62, 250, 1)', 'rgba(255, 152, 229, 1)'];
  const POINT_RADIUS = 4;

  useEffect(() => {
    const data = getAuctionDrawData(priceData);
    onDrawChart(data);
  }, [priceData]);

  const getAuctionDrawData = (
    data: AuctionPriceChartData[],
  ): AuctionDrawData => {
    return {
      labels: data.map((item) =>
        item.time ? moment(item.time).format(TIME_FORMAT) : '',
      ),
      data: data.map((item) => +item.value),
    };
  };

  const getChartOptions = () => ({
    fill: false,
    lineTension: 0.4, // make curve for line
    borderJoinStyle: 'round',
    borderWidth: 2,
    scales: {
      x: {
        grid: {
          color: 'transparent',
        },
        offset: true,
      },
      y: {
        grid: {
          color: 'rgba(31, 38, 70, 1)',
        },
        ticks: {
          callback: (label: string) => `${(+label).toFixed(4)}`,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  });

  const isPredictedPrice = (time: number | string, isPreviewMode: boolean) => {
    const current = moment().endOf('hour');
    if (isPreviewMode) {
      return false;
    }
    return moment(time).isAfter(current);
  };

  const getChartData = (
    drawData: AuctionDrawData,
    priceDataProps: AuctionPriceChartData[],
    isPreviewMode: boolean,
  ) => {
    let findFirstPredictedPrice = false;
    const pointRadius = priceDataProps.map((item) => {
      if (item.isHistory) {
        return 2;
      }
      if (isPredictedPrice(item.time, isPreviewMode)) {
        const radius = findFirstPredictedPrice ? 0 : POINT_RADIUS;
        findFirstPredictedPrice = true;
        return radius;
      }
      return 0;
    });

    const pointBackgroundColor = priceDataProps.map((item) => {
      if (item.isHistory) {
        return DATASET_COLORS[0];
      }
      return '#D9D9D9';
    });

    return {
      labels: drawData.labels,
      datasets: [
        {
          data: drawData.data,
          borderColor: DATASET_COLORS[0],
          pointRadius: pointRadius,
          pointHoverRadius: pointRadius,
          pointBackgroundColor: pointBackgroundColor,
          segment: {
            borderColor: (ctx: ScriptableLineSegmentContext) => {
              return isPredictedPrice(
                priceDataProps[ctx.p0DataIndex].time,
                isPreviewMode,
              )
                ? DATASET_COLORS[1]
                : DATASET_COLORS[0];
            },
          },
        },
      ],
    };
  };

  const onDrawChart = (drawData: AuctionDrawData) => {
    const areaChart = document.getElementById(id);
    let chart = Chart.getChart(id);
    if (chart) chart.destroy();
    const options = getChartOptions();
    const data = getChartData(drawData, priceData, isPreview);
    // @ts-ignore
    chart = new Chart(areaChart, {
      type: CHART_TYPE,
      data,
      options,
    });
  };

  const _renderLegends = () => {
    if (!hasLegends) return null;
    return (
      <div className={styles['legends']}>
        <ul className={styles['list']}>
          <li className={styles['item']}>
            <span className={styles['color-box']}></span>
            <span className={styles['text']}>
              {symbol?.toUpperCase()} price
            </span>
          </li>
          <li className={styles['item']}>
            <span
              className={`${styles['color-box']} ${styles['color-box-predicted']}`}
            ></span>
            <span className={styles['text']}>
              {symbol?.toUpperCase()} predicted price
            </span>
          </li>
        </ul>
      </div>
    );
  };

  const _renderChartInfo = () => {
    if (!priceData.length || !isCreate) return null;
    const firstData = priceData[0];
    const lastData = priceData[priceData.length - 1];
    const durationOfTime = moment(lastData.time).diff(moment(firstData.time));
    const dayDuration = moment.duration(durationOfTime).days();
    const hourDuration = moment.duration(durationOfTime).hours();

    return (
      <div className={styles['information']}>
        <div className={styles['text']}>
          <span className={styles['title']}>Duration: </span>
          <span
            className={styles['value']}
          >{`${dayDuration} days ${hourDuration} hours`}</span>
        </div>
        <div className={styles['text']}>
          <span className={styles['title']}>Price range: </span>
          <span className={styles['value']}>{`$${formatNumber(
            firstData.value,
          )} - $${formatNumber(lastData.value)}`}</span>
        </div>
      </div>
    );
  };

  return (
    <div className={styles[isCreate ? 'container' : 'container-detail']}>
      {_renderLegends()}
      <div className={styles['chart']}>
        <canvas id={id} />
      </div>
      {_renderChartInfo()}
    </div>
  );
};

export default PartAuctionPriceChart;
