import BaseConnector from './BaseConnector';
import { SolletWalletAdapter } from '@solana/wallet-adapter-wallets';

class SolletConnector extends BaseConnector {
  network = {
    chainId: 'sollet-chainId', // temporarily chainId
  };

  getName() {
    return 'Sollet';
  }

  /**
   * connect wallet
   * @returns provider
   */
  async connect() {
    const adapter = new SolletWalletAdapter();
    this.provider = adapter;
    return adapter.connect();
  }

  /**
   * get account and network of the logged in wallet
   * @returns account
   */
  async getAccount(provider: any = this.provider): Promise<any> {
    try {
      const account = provider.publicKey.toString();
      this.account = account;
      return account;
    } catch (error) {
      throw new Error('Wallet has not been connected yet!');
    }
  }

  async signMessage(message: any): Promise<any> {
    const encodeMessage = new TextEncoder().encode(JSON.stringify(message));
    return this.provider.signMessage(encodeMessage);
  }
}

export default SolletConnector;
