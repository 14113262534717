import styles from 'src/styles/pages/LBPDetails/LBPDetails.module.scss';
import { isMobile } from 'react-device-detect';
import React, { useMemo, useState, FC, ReactNode } from 'react';
import PartLBPSwap from 'src/pages/PageAuctionDetail/parts/PartLBPSwap';
import config from 'src/config';
import {
  CopyIcon,
  DetailOverviewIcon,
  DiscordIcon,
  LinkIcon,
  MediumIcon,
  TelegramIcon,
  TwitterIcon,
  WebsiteGrayIcon,
  ExternalLinkIcon,
} from 'src/assets/icons';
import {
  formatNumber,
  formatShortAddress,
  formatTimestamp,
  formatWeiNumber,
} from 'src/utils/utils-formats';
import { copyToClipboard } from 'src/utils/utils-helpers';
import { Collapse, Tooltip } from '@material-ui/core';
import {
  IAuctionResponseType,
  SwapListResponseType,
  ITokenMain,
  ITokenBase,
} from 'src/utils/utils-auction';
import PartLBPChart from './PartLBPChart';
import AppDataTable from 'src/components/AppDataTable';
import rf from 'src/requests/RequestFactory';
import { StatusLBP } from 'src/pages/PageAuctionList';
import { checkIsTokenBase } from 'src/utils/utils-token';

interface IPartLBPDetail {
  auction: IAuctionResponseType;
  mainToken: ITokenMain;
  baseToken: ITokenBase;
  mainTokenIndex: number;
  baseTokenIndex: number;
  baseTokenAccrued: number;
  mainTokenReleased: number;
  balanceStart: string[];
  balanceCurrent: string[];
  tokenWeights: string[];
  swapFee: string;
  priceTokenMain: string;
  isEnabledSwap: boolean;
  fetchData: () => void;
}

const TAB_LBP_DETAIL = {
  swap: 0,
  detail: 1,
  history: 2,
};

const CollapHistory = (props: {
  swapHistory: SwapListResponseType;
  blockExplorerUrl?: string;
  iconExplorerUrl?: string;
}) => {
  const { swapHistory, blockExplorerUrl, iconExplorerUrl } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className={styles['collapse']}>
      <div className={styles['collapse-header']}>
        <div className={styles['head']}>
          <a href={`${blockExplorerUrl}tx/${swapHistory.txid}`} target="_blank">
            <div className={styles['title']}>
              {formatTimestamp(
                swapHistory.timestamp * 1000,
                'MMM DD, YYYY, HH:mm A',
              )}
            </div>

            <img src={iconExplorerUrl} alt="" />
          </a>
          <button
            type="button"
            className={`${styles['btn-collap']} ${
              isOpen ? '' : styles['show']
            }`}
            onClick={() => setIsOpen((open) => !open)}
          >
            <span className={styles['icon-collap']} />
          </button>
        </div>
        <div className={styles['row']}>
          <div className={styles['field']}>
            <div className={styles['label']}>Type</div>
          </div>
          <div className={`${styles['field']} ${styles['grow']}`}>
            <div
              className={`${styles['value']} ${
                styles[swapHistory.tokenIn.symbol === 'BUNI' ? 'sell' : 'buy']
              }`}
            >
              {swapHistory.tokenIn.symbol === 'BUNI' ? 'Sell' : 'Buy'}
            </div>
          </div>
        </div>
      </div>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <div className={styles['collapse-body']}>
          <div className={styles['row']}>
            <div className={styles['field']}>
              <div className={styles['label']}>Input</div>
            </div>
            <div className={`${styles['field']} ${styles['grow']}`}>
              <div className={styles['value']}>
                {formatNumber(swapHistory.tokenAmountIn)}{' '}
                {swapHistory.tokenIn.symbol}
              </div>
            </div>
          </div>
          <div className={styles['row']}>
            <div className={styles['field']}>
              <div className={styles['label']}>Output</div>
            </div>
            <div className={`${styles['field']} ${styles['grow']}`}>
              <div className={styles['value']}>
                {formatNumber(swapHistory.tokenAmountOut)}{' '}
                {swapHistory.tokenOut.symbol}
              </div>
            </div>
          </div>
          <div className={styles['row']}>
            <div className={styles['field']}>
              <div className={styles['label']}>Price</div>
            </div>
            <div className={`${styles['field']} ${styles['grow']}`}>
              <div className={styles['value']}>{swapHistory.price}</div>
            </div>
          </div>
          <div className={styles['row']}>
            <div className={styles['field']}>
              <div className={styles['label']}>Wallet</div>
            </div>
            <div className={`${styles['field']} ${styles['grow']}`}>
              <div className={styles['value']}>
                {formatShortAddress(swapHistory.caller.address)}
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

interface GridItemDetailProps {
  blockName: string;
  label?: string | ReactNode;
  value?: string | ReactNode;
  startAdornmentCustom?: ReactNode;
  endAdornmentCustom?: ReactNode;
  colPart?: boolean;
  expandRow?: ReactNode;
}

const GridItemDetail: FC<GridItemDetailProps> = ({
  blockName,
  label,
  value,
  startAdornmentCustom,
  endAdornmentCustom,
  colPart,
  expandRow,
}) => {
  return (
    <div
      className={`${styles['grid-item']} ${colPart ? styles['col-lg-6'] : ''}`}
    >
      <div className={styles['collection']}>
        <div className={styles['row']}>
          <div className={styles['col']}>
            <div className={styles[blockName + '-field']}>
              {startAdornmentCustom && (
                <div className={styles['adornment-start']}>
                  {startAdornmentCustom}
                </div>
              )}
              <span className={styles['label']}>{label}</span>
            </div>
          </div>
          <div className={`${styles['col']} ${styles['grow']}`}>
            <div className={styles[blockName + '-field']}>
              <div className={styles['value']}>{value}</div>
              {endAdornmentCustom && (
                <div className={styles['adornment-end']}>
                  {endAdornmentCustom}
                </div>
              )}
            </div>
          </div>
        </div>
        {expandRow && <>{expandRow}</>}
      </div>
    </div>
  );
};

const PartLBPDetail: FC<IPartLBPDetail> = ({
  auction,
  mainToken,
  baseToken,
  baseTokenIndex,
  mainTokenIndex,
  baseTokenAccrued,
  mainTokenReleased,
  balanceStart,
  balanceCurrent,
  swapFee,
  tokenWeights,
  priceTokenMain,
  isEnabledSwap,
  fetchData,
}) => {
  const { pool, network: poolNetwork, socialLinks } = auction;
  const { id: poolId } = pool;
  const [activeTab, setActiveTab] = useState<number>(
    isMobile ? TAB_LBP_DETAIL.swap : TAB_LBP_DETAIL.detail,
  );

  const { blockExplorer } = config.networks[poolNetwork];

  const blockExplorerUrl = blockExplorer.url;
  const iconExplorerUrl = blockExplorer.icon;

  const isActiveTab = (tab: number) => {
    return tab === activeTab;
  };

  const _renderDetailsTab = () => {
    if (!pool) {
      return;
    }
    const logoNetwork = config.networks[poolNetwork].icon;
    const nameBlockExplorer = blockExplorer.name;
    const { startTime, endTime, totalSwapVolume, endWeights, startWeights } =
      pool;

    const socialsMedia = [
      {
        icon: <TelegramIcon />,
        label: 'Telegram',
        link: socialLinks?.telegram,
      },
      {
        icon: <TwitterIcon />,
        label: 'Twitter',
        link: socialLinks?.twitter,
      },
      {
        icon: <WebsiteGrayIcon />,
        label: 'Website',
        link: socialLinks?.website,
      },
      {
        icon: <DiscordIcon />,
        label: 'Discord',
        link: socialLinks?.discord,
      },
      {
        icon: <MediumIcon />,
        label: 'Medium',
        link: socialLinks?.medium,
      },
    ];

    const tokenNetworkLinks = [
      {
        icon: logoNetwork,
        label: `${baseToken.symbol} Token on ${nameBlockExplorer}`,
        link: blockExplorerUrl + 'address/' + baseToken.address,
      },
      {
        icon: logoNetwork,
        label: `LBP Owner on ${nameBlockExplorer}`,
        link: blockExplorerUrl + 'address/' + pool.owner,
      },
      {
        icon: logoNetwork,
        label: `LBP Address on ${nameBlockExplorer}`,
        link: blockExplorerUrl + 'address/' + pool.address,
      },
      {
        icon: '/images/document-icon.svg',
        label: 'LBP Documentation',
        link: socialLinks?.website,
      },
    ];

    const _renderSocialBlock = () => {
      return (
        <div className={styles['social-block']}>
          <div className={styles['grid-container']}>
            {socialsMedia.map((item, index) => (
              <GridItemDetail
                key={index}
                blockName={'social'}
                startAdornmentCustom={item.icon}
                label={item.label}
                endAdornmentCustom={
                  item.link && item.link.length ? (
                    <a
                      href={item.link}
                      target={'_blank'}
                      rel="noopener noreferrer"
                    >
                      <LinkIcon />
                    </a>
                  ) : (
                    <div className={styles['no-value']}>No url</div>
                  )
                }
              />
            ))}
          </div>
        </div>
      );
    };

    const _renderTokenNetworkBlock = () => {
      return (
        <div className={styles['token-network-block']}>
          <div className={styles['grid-container']}>
            {tokenNetworkLinks.map((tokenNetwork, index) => (
              <GridItemDetail
                key={index}
                blockName={'tn'}
                startAdornmentCustom={<img src={tokenNetwork.icon} alt="" />}
                label={tokenNetwork.label}
                endAdornmentCustom={
                  tokenNetwork.link &&
                  tokenNetwork.link.length && (
                    <a
                      href={tokenNetwork.link}
                      target={'_blank'}
                      rel="noopener noreferrer"
                    >
                      <LinkIcon />
                    </a>
                  )
                }
              />
            ))}
          </div>
        </div>
      );
    };

    const _renderLBPDetailsBlock = () => {
      return (
        <div className={styles['detail-block']}>
          <div className={styles['grid-container']}>
            <GridItemDetail
              blockName={'detail'}
              colPart
              startAdornmentCustom={
                <span className={styles['adornment-blue']} />
              }
              label={'Start Date'}
              value={formatTimestamp(startTime * 1000, 'HH:mm MMM DD, YYYY')}
            />
            <GridItemDetail
              blockName={'detail'}
              colPart
              startAdornmentCustom={
                <span className={styles['adornment-blue']} />
              }
              label={'End Date'}
              value={formatTimestamp(endTime * 1000, 'HH:mm MMM DD, YYYY')}
            />
          </div>
        </div>
      );
    };

    const _renderLBPStatistic = () => {
      return (
        <div className={styles['statistic-block']}>
          <div className={styles['grid-container']}>
            <GridItemDetail
              blockName={'statistic'}
              colPart
              startAdornmentCustom={
                <span className={styles['adornment-blue']} />
              }
              label={'Start Balances'}
              value={formatNumber(balanceStart[mainTokenIndex])}
              endAdornmentCustom={<img src={mainToken.icon} alt="" />}
              expandRow={
                <div className={styles['row']}>
                  <div className={`${styles['col']} ${styles['grow']}`}>
                    <div className={styles['statistic-field']}>
                      <div className={styles['value']}>
                        {formatNumber(balanceStart[baseTokenIndex])}
                      </div>
                      <div className={styles['adornment-end']}>
                        <img src={baseToken.icon} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              }
            />

            <GridItemDetail
              blockName={'statistic'}
              colPart
              startAdornmentCustom={
                <span className={styles['adornment-blue']} />
              }
              label={'Current Balances'}
              value={formatNumber(balanceCurrent[mainTokenIndex])}
              endAdornmentCustom={<img src={mainToken.icon} alt="" />}
              expandRow={
                <div className={styles['row']}>
                  <div className={`${styles['col']} ${styles['grow']}`}>
                    <div className={styles['statistic-field']}>
                      <div className={styles['value']}>
                        {formatNumber(balanceCurrent[baseTokenIndex])}
                      </div>
                      <div className={styles['adornment-end']}>
                        <img src={baseToken.icon} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              }
            />

            <GridItemDetail
              blockName={'statistic'}
              colPart
              startAdornmentCustom={
                <span className={styles['adornment-blue']} />
              }
              label={'Main Tokens Released'}
              value={formatNumber(mainTokenReleased)}
              endAdornmentCustom={<img src={mainToken.icon} alt="" />}
            />

            <GridItemDetail
              blockName={'statistic'}
              colPart
              startAdornmentCustom={
                <span className={styles['adornment-blue']} />
              }
              label={'Base Tokens Accrued'}
              value={formatNumber(baseTokenAccrued)}
              endAdornmentCustom={<img src={baseToken.icon} alt="" />}
            />
          </div>
        </div>
      );
    };

    const _renderLBPSettingBlock = () => {
      return (
        <div className={styles['setting-block']}>
          <div className={styles['grid-container']}>
            <GridItemDetail
              blockName={'setting'}
              colPart
              startAdornmentCustom={
                <span className={styles['adornment-blue']} />
              }
              label={'Start Weights'}
              value={
                <>
                  {Number(
                    formatWeiNumber(
                      startWeights[mainTokenIndex],
                      mainToken.decimals,
                    ),
                  ) * 100}
                  %
                </>
              }
              endAdornmentCustom={<img src={mainToken.icon} alt="" />}
              expandRow={
                <div className={styles['row']}>
                  <div className={`${styles['col']} ${styles['grow']}`}>
                    <div className={styles['setting-field']}>
                      <div className={styles['value']}>
                        +
                        {Number(
                          formatWeiNumber(
                            startWeights[baseTokenIndex],
                            baseToken.decimals,
                          ),
                        ) * 100}
                        %
                      </div>
                      <div className={styles['adornment-end']}>
                        <img src={baseToken.icon} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              }
            />

            <GridItemDetail
              blockName={'setting'}
              colPart
              startAdornmentCustom={
                <span className={styles['adornment-blue']} />
              }
              label={'End Weights'}
              value={
                <>
                  {Number(
                    formatWeiNumber(
                      endWeights[mainTokenIndex],
                      mainToken.decimals,
                    ),
                  ) * 100}
                  %
                </>
              }
              endAdornmentCustom={<img src={mainToken.icon} alt="" />}
              expandRow={
                <div className={styles['row']}>
                  <div className={`${styles['col']} ${styles['grow']}`}>
                    <div className={styles['setting-field']}>
                      <div className={styles['value']}>
                        +
                        {Number(
                          formatWeiNumber(
                            endWeights[baseTokenIndex],
                            baseToken.decimals,
                          ),
                        ) * 100}
                        %
                      </div>
                      <div className={styles['adornment-end']}>
                        <img src={baseToken.icon} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              }
            />
          </div>
        </div>
      );
    };

    const _renderOwnerRightBlock = () => {
      return (
        <div className={styles['owner-right-block']}>
          <div className={styles['grid-container']}>
            <GridItemDetail
              blockName={'owner'}
              colPart
              startAdornmentCustom={
                <span className={styles['adornment-blue']} />
              }
              label={'Total Volume'}
              value={<>${formatNumber(totalSwapVolume)}</>}
            />

            <GridItemDetail
              blockName={'owner'}
              colPart
              startAdornmentCustom={
                <span className={styles['adornment-blue']} />
              }
              label={<>Swap Fee (Collected by {pool.name} Project)</>}
              value={<>{+swapFee * 100}%</>}
            />
          </div>
        </div>
      );
    };

    return (
      <div className={styles['detail-tab']}>
        <div className={styles['detail-header']}>
          <div className={styles['brand-name']}>
            <div className={styles['brand-symbol']}>
              <img src={auction.logoUrl} />
            </div>
            <div className={styles['brand-title']}>
              {pool.name}
              <span className={styles['symbol']}>{pool.symbol}</span>
            </div>
          </div>
          <div className={styles['contact-address']}>
            <div className={styles['logo-token']}>
              <img src={logoNetwork} alt="" />
            </div>
            <div className={styles['address']}>
              {formatShortAddress(pool.address)}
            </div>
            <button
              type="button"
              className={styles['btn-copy']}
              onClick={() => copyToClipboard(pool.address)}
            >
              <CopyIcon />
            </button>
          </div>
          <div className={styles['detail-description']}>
            <div className={styles['detail-label']}>
              <span className={styles['icon-dot']} />
              LBP Description
            </div>
            <div className={styles['detail-desc']}>{auction.description}</div>
          </div>
        </div>

        {!isMobile && (
          <div className={styles['detail-body']}>
            <div className={`${styles['grid-container']}`}>
              <div className={`${styles['grid-item']} ${styles['col-lg-6']}`}>
                <div className={styles['the-detail']}>
                  {_renderSocialBlock()}
                </div>
              </div>
              <div className={`${styles['grid-item']} ${styles['col-lg-6']}`}>
                <div className={styles['the-detail']}>
                  {_renderTokenNetworkBlock()}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={styles['detail-body']}>
          <div className={`${styles['grid-container']}`}>
            <div className={`${styles['grid-item']}`}>
              <div className={styles['the-detail']}>
                <div className={styles['the-header']}>
                  <div className={styles['title']}>
                    <span className={styles['icon-dot']} />
                    LBP Details
                  </div>
                  <div className={styles['sub-header']}>
                    <div className={styles['label']}>Status:</div>
                    <StatusLBP startTime={startTime} endTime={endTime} />
                  </div>
                </div>
                <div className={styles['the-body']}>
                  {_renderLBPDetailsBlock()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles['detail-body']}>
          <div className={`${styles['grid-container']}`}>
            <div className={`${styles['grid-item']}`}>
              <div className={styles['the-detail']}>
                <div className={styles['the-header']}>
                  <div className={styles['title']}>
                    <span className={styles['icon-dot']} />
                    LBP Statistics
                  </div>
                </div>
                <div className={styles['the-body']}>
                  {_renderLBPStatistic()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles['detail-body']}>
          <div className={`${styles['grid-container']}`}>
            <div className={`${styles['grid-item']}`}>
              <div className={styles['the-detail']}>
                <div className={styles['the-header']}>
                  <div className={styles['title']}>
                    <span className={styles['icon-dot']} />
                    LBP Settings
                  </div>
                </div>
                <div className={styles['the-body']}>
                  {_renderLBPSettingBlock()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles['detail-body']}>
          <div className={`${styles['grid-container']}`}>
            <div className={`${styles['grid-item']}`}>
              <div className={styles['the-detail']}>
                <div className={styles['the-header']}>
                  <div className={styles['title']}>
                    <span className={styles['icon-dot']} /> Owner rights
                  </div>
                  <div className={styles['sub-header']}>
                    <div className={styles['box-info']}>
                      <div className={styles['text']}>Pause swapping </div>
                      <Tooltip
                        title={
                          'Allows the LBP owner to stop the LBP early while retaining the ability to restart it.'
                        }
                        className={styles['adornment-end']}
                      >
                        <DetailOverviewIcon />
                      </Tooltip>
                    </div>
                    <div className={styles['box-info']}>
                      <div className={styles['text']}>Pull liquidity </div>
                      <Tooltip
                        title={
                          'Allows the LBP owner to withdraw liquidity at any point during the LBP.'
                        }
                        className={styles['adornment-end']}
                      >
                        <DetailOverviewIcon />
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div className={styles['the-body']}>
                  {_renderOwnerRightBlock()}
                </div>
              </div>
            </div>
          </div>
        </div>

        {isMobile && (
          <div className={styles['detail-description-body']}>
            <div className={`${styles['grid-container']}`}>
              <div className={`${styles['grid-item']} ${styles['col-lg-6']}`}>
                <div className={styles['the-detail']}>
                  {_renderSocialBlock()}
                </div>
              </div>
              <div className={`${styles['grid-item']} ${styles['col-lg-6']}`}>
                <div className={styles['the-detail']}>
                  {_renderTokenNetworkBlock()}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={styles['detail-body']}>
          <div className={`${styles['grid-container']}`}>
            <div className={`${styles['grid-item']}`}>
              <div className={styles['the-detail']}>
                <div className={styles['the-header']}>
                  <div className={styles['title']}>
                    <span className={styles['icon-dot']} /> Blocked countries
                  </div>
                  <div className={styles['sub-header']}>
                    <div className={styles['description']}>
                      <p className={styles['text']}>
                        <span>
                          To comply with local laws and regulations, LBP hosts
                          may be required to block access to the LBP depending
                          on their local jurisdiction.
                        </span>
                        <a
                          href="https://home.treasury.gov/policy-issues/office-of-foreign-assets-control-sanctions-programs-and-information"
                          className={styles['link']}
                          target="_blank"
                          rel="noreferrer"
                        >
                          OFAC Restricted Countries
                          <ExternalLinkIcon />
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getPrice = (pool: SwapListResponseType) => {
    if (checkIsTokenBase(pool.tokenIn.address, poolNetwork)) {
      return `${formatNumber(+pool.tokenAmountIn / +pool.tokenAmountOut)} ${
        pool.tokenIn.symbol
      }`;
    }

    return `${formatNumber(+pool.tokenAmountOut / +pool.tokenAmountIn)} ${
      pool.tokenOut.symbol
    }`;
  };

  const paramsHistory = useMemo(() => {
    return {
      network: poolNetwork,
      poolId: poolId,
    };
  }, [poolNetwork, poolId]);

  const _renderHistoryTab = () => {
    return (
      <div className={styles['history-tab']}>
        <AppDataTable
          limit={10}
          wrapperClassName={styles['history-body']}
          requestParams={paramsHistory}
          fetchData={(payload) =>
            rf.getRequest('AuctionsRequest').getSwapList(payload)
          }
          renderHeader={() => {
            if (isMobile) {
              return null;
            }
            return (
              <div className={styles['table-head']}>
                <div className={styles['table-row']}>
                  <div className={`${styles['col']} ${styles['col-time']}`}>
                    Time
                  </div>
                  <div className={`${styles['col']} ${styles['col-type']}`}>
                    Type
                  </div>
                  <div className={`${styles['col']} ${styles['col-input']}`}>
                    Input
                  </div>
                  <div className={`${styles['col']} ${styles['col-output']}`}>
                    Output
                  </div>
                  <div className={`${styles['col']} ${styles['col-price']}`}>
                    Price
                  </div>
                  <div className={`${styles['col']} ${styles['col-wallet']}`}>
                    Wallet
                  </div>

                  <div className={`${styles['col']} ${styles['link-tx']}`} />
                </div>
              </div>
            );
          }}
          renderBody={(data: SwapListResponseType[]) => {
            if (isMobile) {
              return (
                <div className={styles['history-list']}>
                  {data.map((swap: SwapListResponseType) => (
                    <div className={styles['history-item']} key={swap.id}>
                      <CollapHistory
                        swapHistory={{ ...swap, price: getPrice(swap) }}
                        blockExplorerUrl={blockExplorerUrl}
                        iconExplorerUrl={iconExplorerUrl}
                      />
                    </div>
                  ))}
                </div>
              );
            }
            return (
              <div className={styles['table-body']}>
                {data.map((swap: SwapListResponseType) => (
                  <div className={styles['table-row']} key={swap.id}>
                    <div className={`${styles['col']} ${styles['col-time']}`}>
                      {formatTimestamp(
                        swap.timestamp * 1000,
                        'MMM DD, YYYY, HH:mm A',
                      )}
                    </div>
                    <div
                      className={`${styles['col']} ${styles['col-type']} ${
                        styles[swap.tokenIn.symbol === 'BUNI' ? 'sell' : 'buy']
                      }`}
                    >
                      {swap.tokenIn.symbol === 'BUNI' ? 'Sell' : 'Buy'}
                    </div>
                    <div className={`${styles['col']} ${styles['col-input']}`}>
                      {formatNumber(swap.tokenAmountIn, 8)}{' '}
                      {swap.tokenIn.symbol}
                    </div>
                    <div className={`${styles['col']} ${styles['col-output']}`}>
                      {formatNumber(swap.tokenAmountOut, 8)}{' '}
                      {swap.tokenOut.symbol}
                    </div>
                    <div className={`${styles['col']} ${styles['col-price']}`}>
                      {getPrice(swap)}
                    </div>
                    <div className={`${styles['col']} ${styles['col-wallet']}`}>
                      {formatShortAddress(swap.caller.address)}
                    </div>

                    <div className={`${styles['col']} ${styles['link-tx']}`}>
                      <a
                        href={`${blockExplorerUrl}tx/${swap.txid}`}
                        target="_blank"
                      >
                        <img src={iconExplorerUrl} alt="" />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            );
          }}
        />
      </div>
    );
  };

  return (
    <>
      <nav className={styles['nav-tab']}>
        <ul className={styles['list']}>
          {isMobile && (
            <li
              className={`${styles['item']} ${
                isActiveTab(TAB_LBP_DETAIL.swap) ? styles['active'] : ''
              }`}
              onClick={() => setActiveTab(TAB_LBP_DETAIL.swap)}
            >
              Swap
            </li>
          )}
          <li
            className={`${styles['item']} ${
              isActiveTab(TAB_LBP_DETAIL.detail) ? styles['active'] : ''
            }`}
            onClick={() => setActiveTab(TAB_LBP_DETAIL.detail)}
          >
            LBP Details
          </li>
          <li
            className={`${styles['item']} ${
              isActiveTab(TAB_LBP_DETAIL.history) ? styles['active'] : ''
            }`}
            onClick={() => setActiveTab(TAB_LBP_DETAIL.history)}
          >
            Swap History
          </li>
        </ul>
      </nav>
      <div className={styles['tabs-wapper']}>
        {isMobile && (
          <div
            style={{
              display: isActiveTab(TAB_LBP_DETAIL.swap) ? 'block' : 'none',
            }}
          >
            <div className={styles['chart']}>
              <PartLBPChart
                auction={auction}
                mainToken={mainToken}
                baseToken={baseToken}
                baseTokenIndex={baseTokenIndex}
                mainTokenIndex={mainTokenIndex}
                balanceStart={balanceStart}
                balanceCurrent={balanceCurrent}
                tokenWeights={tokenWeights}
                swapFee={swapFee}
              />
            </div>
            <PartLBPSwap
              auction={auction}
              mainToken={mainToken}
              baseToken={baseToken}
              baseTokenIndex={baseTokenIndex}
              mainTokenIndex={mainTokenIndex}
              balanceCurrent={balanceCurrent}
              tokenWeights={tokenWeights}
              swapFee={swapFee}
              priceTokenMain={priceTokenMain}
              isEnabledSwap={isEnabledSwap}
              fetchData={fetchData}
            />
          </div>
        )}
        <div
          style={{
            display: isActiveTab(TAB_LBP_DETAIL.detail) ? 'block' : 'none',
          }}
        >
          {_renderDetailsTab()}
        </div>
        <div
          style={{
            display: isActiveTab(TAB_LBP_DETAIL.history) ? 'block' : 'none',
          }}
        >
          {_renderHistoryTab()}
        </div>
      </div>
    </>
  );
};

export default PartLBPDetail;
