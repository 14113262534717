import styles from 'src/styles/pages/LBPDetails/LBPOverview.module.scss';
import React, { FC, useMemo, useState } from 'react';
import moment from 'moment';
import {
  ArrowLeftIcon,
  CalendarIcon,
  ClockIcon,
  SettingIcon,
  UnverifiedIcon,
  VerifiedIcon,
} from 'src/assets/icons';
import { formatNumber, formatTimestamp } from 'src/utils/utils-formats';
import AppCountdown from 'src/components/AppCountdown';
import ModalSettingLBP from 'src/modals/ModalSettingLBP';
import { Link } from 'react-router-dom';
import {
  IAuctionResponseType,
  ITokenMain,
  ITokenBase,
} from 'src/utils/utils-auction';
import useAuth from 'src/hooks/useAuth';
import { Auction } from 'src/utils/auction';

interface IPartLBPOverview {
  auction: IAuctionResponseType;
  mainToken: ITokenMain;
  baseToken: ITokenBase;
  mainTokenIndex: number;
  baseTokenIndex: number;
  baseTokenAccrued: number;
  mainTokenReleased: number;
  balanceStart: string[];
  balanceCurrent: string[];
  priceTokenMain: string;
  isEnabledSwap: boolean;
  fetchStatusEnableSwap: () => void;
}

interface IHeader {
  auction: IAuctionResponseType;
  mainToken: ITokenMain;
  baseToken: ITokenBase;
  mainTokenIndex: number;
  baseTokenIndex: number;
  baseTokenAccrued: number;
  balanceCurrent: string[];
  isEnabledSwap: boolean;
  fetchStatusEnableSwap: () => void;
}

const Header: FC<IHeader> = ({
  auction,
  mainToken,
  baseToken,
  baseTokenIndex,
  mainTokenIndex,
  baseTokenAccrued,
  balanceCurrent,
  isEnabledSwap,
  fetchStatusEnableSwap,
}) => {
  const [isOpenModalSetting, setIsOpenModalSetting] = useState<boolean>(false);
  const { user } = useAuth();

  return (
    <>
      <div className={styles['detail-back']}>
        <div className={styles['content-left']}>
          <Link to={`/lbp-list`}>
            <div className={styles['icon']}>
              <ArrowLeftIcon />
            </div>
            <div className={styles['name']}>All LBPs</div>
          </Link>
        </div>
        {user?.getAddress() === auction?.ownerAddress && (
          <div className={styles['settings']}>
            Settings <SettingIcon onClick={() => setIsOpenModalSetting(true)} />
          </div>
        )}
      </div>
      <ModalSettingLBP
        open={isOpenModalSetting}
        onClose={() => setIsOpenModalSetting(false)}
        auction={auction}
        mainToken={mainToken}
        baseToken={baseToken}
        baseTokenIndex={baseTokenIndex}
        mainTokenIndex={mainTokenIndex}
        baseTokenAccrued={baseTokenAccrued}
        balanceCurrent={balanceCurrent}
        isEnabledSwap={isEnabledSwap}
        fetchStatusEnableSwap={fetchStatusEnableSwap}
      />
    </>
  );
};

const PartLBPOverview: FC<IPartLBPOverview> = ({
  auction,
  mainToken,
  baseToken,
  baseTokenIndex,
  mainTokenIndex,
  baseTokenAccrued,
  mainTokenReleased,
  balanceStart,
  balanceCurrent,
  priceTokenMain,
  isEnabledSwap,
  fetchStatusEnableSwap,
}) => {
  const { pool } = auction;

  const lbpAuction = useMemo(() => new Auction(auction), [auction]);

  const _renderLBPDuration = () => {
    const startTime = lbpAuction.getStartTime();
    const endTime = lbpAuction.getEndTime();
    const durationDay = Math.round(
      moment(endTime * 1000).diff(moment(startTime * 1000), 'hours') / 24,
    );
    const now = moment().unix();
    const isBeforeStart = now < startTime;
    const isDuring = now >= startTime && now <= endTime;
    const isEnded = now > endTime;

    const getLabelTimeLine = () => {
      if (isBeforeStart) {
        return 'Start in';
      }

      if (isDuring) {
        return 'Ends in';
      }

      return 'Ended';
    };

    const getCountdownEndTime = () => {
      if (isBeforeStart) {
        return startTime * 1000;
      }

      if (isDuring) {
        return endTime * 1000;
      }

      return 0;
    };

    const renderCountdown = (durationAuction: number) => {
      return (
        <>
          {Math.floor(durationAuction / (3600 * 24)) > 0 && (
            <>
              <span>{Math.floor(durationAuction / (3600 * 24))}</span>
              {' : '}
            </>
          )}
          <span>{moment.utc(durationAuction * 1000).format('HH')}</span>
          {' : '}
          <span>{moment.utc(durationAuction * 1000).format('mm')}</span>
          {' : '}
          <span>{moment.utc(durationAuction * 1000).format('ss')}</span>
        </>
      );
    };

    return (
      <div className={styles['lbp-duration']}>
        <div className={styles['card-duration']}>
          <div className={styles['title']}>
            {pool.name}
            {auction.isVerified ? <VerifiedIcon /> : <UnverifiedIcon />}
          </div>
          <div className={styles['content']}>
            <div className={styles['detail']}>
              <div className={`${styles['item']} ${styles['icon']}`}>
                <CalendarIcon />
              </div>
              <div className={`${styles['item']} ${styles['label']}`}>
                Duration
              </div>
              <div className={`${styles['item']} ${styles['value']}`}>
                {durationDay} {durationDay > 1 ? 'days' : 'day'}
              </div>
            </div>
            <div className={styles['detail']}>
              <div className={`${styles['item']} ${styles['icon']}`}>
                <ClockIcon />
              </div>
              <div className={`${styles['item']} ${styles['label']}`}>
                {getLabelTimeLine()}
              </div>
              <div className={`${styles['item']} ${styles['value']}`}>
                {isEnded ? (
                  <>{formatTimestamp(endTime * 1000, 'DD/MM/YYYY')} </>
                ) : (
                  <>
                    <AppCountdown
                      endDate={getCountdownEndTime()}
                      render={renderCountdown}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const _renderLBPOverview = () => {
    if (!pool) {
      return;
    }

    const { totalLiquidity, totalSwapVolume } = pool;
    return (
      <div className={styles['lbp-overview']}>
        <div className={styles['overview-item']}>
          <div className={styles['info']}>
            <div className={styles['label']}>
              <span className={styles['icon']} />
              <span className={styles['text']}>Total Volume</span>
            </div>
            <div className={styles['value']}>
              <span className={styles['text']}>
                ${formatNumber(totalSwapVolume)}
              </span>
            </div>
          </div>
        </div>
        <div className={styles['overview-item']}>
          <div className={styles['info']}>
            <div className={styles['label']}>
              <span className={styles['icon']} />
              <span className={styles['text']}>Liquidity</span>
            </div>
            <div className={styles['value']}>
              <span className={styles['text']}>
                ${formatNumber(totalLiquidity)}
              </span>
            </div>
          </div>
        </div>
        <div className={styles['overview-item']}>
          <div className={styles['info']}>
            <div className={styles['label']}>
              <span className={styles['icon']} />
              <span className={styles['text']}>Price</span>
            </div>
            <div className={styles['value']}>
              <span className={styles['text']}>
                {formatNumber(priceTokenMain)} {baseToken.symbol}
              </span>
            </div>
          </div>
        </div>
        <div className={styles['overview-item']}>
          <div className={styles['info']}>
            <div className={styles['label']}>
              <span className={styles['icon']} />
              <span className={styles['text']}>Main Tokens Released</span>
            </div>
            <div className={styles['value']}>
              <span className={styles['text']}>
                {formatNumber(
                  (
                    (+mainTokenReleased / +balanceStart[mainTokenIndex]) *
                    100
                  ).toFixed(2),
                )}
                %
              </span>
              {/*<span className={styles['note']}>*/}
              {/*  {formatNumber(mainTokenReleased)} of{' '}*/}
              {/*  {formatNumber(balanceStart[mainTokenIndex])}*/}
              {/*</span>*/}
            </div>
          </div>
        </div>
        <div className={styles['overview-item']}>
          <div className={styles['info']}>
            <div className={styles['label']}>
              <span className={styles['icon']} />
              <span className={styles['text']}>Base Token Accrued</span>
            </div>
            <div className={styles['value']}>
              <span className={styles['text']}>
                {formatNumber(baseTokenAccrued)}
              </span>
              <span className={styles['note']}>{baseToken?.symbol}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Header
        auction={auction}
        mainToken={mainToken}
        baseToken={baseToken}
        baseTokenIndex={baseTokenIndex}
        mainTokenIndex={mainTokenIndex}
        baseTokenAccrued={baseTokenAccrued}
        balanceCurrent={balanceCurrent}
        isEnabledSwap={isEnabledSwap}
        fetchStatusEnableSwap={fetchStatusEnableSwap}
      />
      <div className={styles['content-item']}>{_renderLBPDuration()}</div>
      <div className={styles['content-item']}>{_renderLBPOverview()}</div>
    </>
  );
};

export default PartLBPOverview;
