import { TokenType } from './utils-token';

interface AuctionDraftInfo {
  amounts: string[];
  endTime: number;
  endWeights: string[];
  startTime: number;
  startWeights: string[];
  swapFee: number;
  tokens: TokenType[];
}

export class Auction {
  protected isDraft = false;
  protected isVerified = false;
  protected draftInfo: AuctionDraftInfo | null = null;
  protected pool = null;

  constructor(auction: any) {
    if (!auction) {
      return;
    }
    this.setIsDraft(auction.isDraft);
    this.setIsVerified(auction.isVerified);
    this.setDraftInfo(auction.draftInfo);
    this.setPool(auction.pool);
  }

  setIsDraft(isDraft: boolean): boolean {
    this.isDraft = isDraft;
    return this.isDraft;
  }

  getIsDraft(): boolean {
    return this.isDraft;
  }

  setIsVerified(isVerified: boolean): boolean {
    this.isVerified = isVerified;
    return this.isVerified;
  }

  getIsVerified(): boolean {
    return this.isVerified;
  }

  setDraftInfo(draftInfo: AuctionDraftInfo | null) {
    this.draftInfo = draftInfo;
    return this.draftInfo;
  }

  getDraftInfo(): AuctionDraftInfo | null {
    return this.draftInfo;
  }

  setPool(pool: any | null) {
    this.pool = pool;
    return this.pool;
  }

  getPool(): any | null {
    return this.pool;
  }

  getStartTime(): number {
    return this.getIsDraft()
      ? this.getDraftInfo()?.startTime
      : this.getPool().startTime;
  }

  getEndTime(): number {
    return this.getIsDraft()
      ? this.getDraftInfo()?.endTime
      : this.getPool().endTime;
  }

  getStartWeights(): string[] {
    return this.getIsDraft()
      ? this.getDraftInfo()?.startWeights
      : this.getPool().startWeights;
  }

  getEndWeights(): string[] {
    return this.getIsDraft()
      ? this.getDraftInfo()?.endWeights
      : this.getPool().endWeights;
  }

  getTokens() {
    return this.getIsDraft()
      ? this.getDraftInfo()?.tokens
      : this.getPool().tokens;
  }
}
