import React from 'react';
import ProjectContentView from 'src/components/project/ProjectContentView';
import { ProjectInterface } from 'src/utils/project';

interface IPartProjectData {
  project: ProjectInterface;
}

const PartProjectInfo: React.FC<IPartProjectData> = ({ project }) => {
  return (
    <div>
      <ProjectContentView
        project={project}
        currency={{
          symbol: 'NA',
          decimals: 32,
          logoUrl: 'NA',
        }}
        namePage="project-detail"
      />
    </div>
  );
};

export default PartProjectInfo;
