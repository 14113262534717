import { useDispatch } from 'react-redux';
import BigNumber from 'bignumber.js';
import {
  getPurchaseIDOSignature,
  isPurchaseWithNativeToken,
  makePurchaseIDOWithNativeTokenParams,
  makePurchaseIDOWithTokenParams,
  claimSolanaIDO,
  getClaimIdoTokenParams,
  purchaseSolanaIDO,
} from 'src/utils/utils-pool';
import { IDOPool } from 'src/utils/pool';
import { UserInterface } from 'src/utils/user';
import { processTransaction } from 'src/store/transactions';
import { convertDecToWei } from 'src/utils/utils-formats';
import _ from 'lodash';
import { toastInfo } from 'src/utils/utils-notify';
import { getAllowance, makeApproveParams } from 'src/utils/utils-token';

type ReturnType = {
  claimToken: (pool: IDOPool, user: UserInterface | null) => Promise<void>;
  purchaseToken: (
    pool: IDOPool,
    user: UserInterface | null,
    buyAmount: string,
  ) => Promise<void>;
};

const useIDOPool = (): ReturnType => {
  const dispatch = useDispatch();

  const claimEvmIDO = async (
    pool: IDOPool,
    user: UserInterface,
  ): Promise<any> => {
    const params = await getClaimIdoTokenParams(pool, user);
    await dispatch(
      processTransaction({
        provider: user?.getProvider(),
        params,
        title: 'Claim Tokens',
      }),
    );
  };

  const claimToken = async (
    pool: IDOPool,
    user: UserInterface | null,
  ): Promise<void> => {
    if (!pool || !user) {
      return;
    }
    if (pool.isNetworkSolana()) {
      return claimSolanaIDO(pool, user);
    }
    return claimEvmIDO(pool, user);
  };

  const isTokenApproved = async (pool: IDOPool, user: UserInterface) => {
    if (
      isPurchaseWithNativeToken(
        pool.getNetwork(),
        pool.getCollateralCurrencySymbol(),
      )
    ) {
      return true;
    }
    const allowance = await getAllowance(
      pool.getNetwork(),
      pool.getCollateralCurrencyAddress(),
      user.getAddress(),
      pool.getContractAddress(),
    );

    return new BigNumber(allowance).gt(0);
  };

  const approveToken = async (pool: IDOPool, user: UserInterface) => {
    const isApproved = await isTokenApproved(pool, user);
    if (isApproved) {
      return;
    }
    toastInfo({
      message: `You need to give permission to access your ${pool.getCollateralCurrencySymbol()}`,
    });
    const params = makeApproveParams(
      pool.getCollateralCurrencyAddress(),
      pool.getContractAddress(),
    );
    await dispatch(
      processTransaction({ provider: user?.getProvider(), params }),
    );
  };

  const makePurchaseParams = async (
    user: UserInterface,
    pool: IDOPool,
    buyAmount: string,
  ) => {
    const res = await getPurchaseIDOSignature(user, pool, buyAmount);
    let methodParams = [];
    if (
      isPurchaseWithNativeToken(
        pool.getNetwork(),
        pool.getCollateralCurrencySymbol(),
      )
    ) {
      methodParams = _.pick(res, [
        'fundingWallet',
        'outToken',
        'outAmount',
        'maxAmount',
        'minAmount',
        'maxCap',
        'deadline',
        'signature',
      ]);
      return makePurchaseIDOWithNativeTokenParams(
        pool?.getContractAddress(),
        methodParams,
        buyAmount,
      );
    }
    methodParams = _.pick(res, [
      'fundingWallet',
      'inToken',
      'inAmount',
      'outToken',
      'outAmount',
      'maxAmount',
      'minAmount',
      'maxCap',
      'deadline',
      'signature',
    ]);
    return makePurchaseIDOWithTokenParams(
      pool?.getContractAddress(),
      methodParams,
    );
  };

  const purchaseEvmIDO = async (
    pool: IDOPool,
    user: UserInterface,
    buyAmount: string,
  ): Promise<void> => {
    await approveToken(pool, user);
    const params = await makePurchaseParams(user, pool, buyAmount);
    await dispatch(
      processTransaction({
        provider: user?.getProvider(),
        params,
        title: 'Purchase Tokens',
      }),
    );
  };

  const purchaseToken = async (
    pool: IDOPool,
    user: UserInterface | null,
    buyAmount: string,
  ): Promise<void> => {
    if (!pool || !user) {
      return;
    }
    buyAmount = convertDecToWei(
      buyAmount,
      pool.getCollateralCurrencyDecimals(),
    );
    if (pool.isNetworkSolana()) {
      return purchaseSolanaIDO(pool, user, buyAmount);
    }
    return purchaseEvmIDO(pool, user, buyAmount);
  };

  return {
    claimToken,
    purchaseToken,
  };
};

export default useIDOPool;
