import React, { useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import { Avatar, Collapse } from '@material-ui/core';
import {
  ArrowLeftIcon,
  InfoCircleIcon,
  UnverifiedIcon,
  VerifiedIcon,
  WarningRedIcon,
} from 'src/assets/icons';
import AppBadge from 'src/components/AppBadge';
import AppButton from 'src/components/AppButton';
import SocialLinks from 'src/components/SocialLinks';
import { ModalListItem } from 'src/modals/ModalBuyINO';
import styles from 'src/styles/pages/INODetail/INOOverview.module.scss';
import clsx from 'clsx';
import CardTimeLine from 'src/pages/PageIDOPoolDetail/parts/CardTimeLine';
import { INOPool, PackageType, PoolResponseType } from 'src/utils/pool';
import {
  convertWeiToDec,
  formatNumber,
  formatTierAsString,
  formatTimestamp,
} from 'src/utils/utils-formats';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { KYC_STATUS } from 'src/constants';
import useAuth from 'src/hooks/useAuth';
import rf from 'src/requests/RequestFactory';
import {
  getBoxSold,
  getCountdownTimestamp,
  getTitleCurrentPhase,
} from 'src/utils/utils-pool';
import { PhaseType, TimelineInterface } from 'src/utils/timelines';
import ModalApplyWhitelist from 'src/modals/ModalApplyWhitelist';
import ModalBuyINO from 'src/modals/ModalBuyINO';
import { Link } from 'react-router-dom';
import { getNetworkConfig } from 'src/utils/utils-network';

interface INOOverviewProps {
  pool: PoolResponseType;
}

const INOOverview = ({ pool }: INOOverviewProps) => {
  const inoPool = useMemo(() => {
    if (!pool) return;
    return new INOPool(pool);
  }, [pool]);

  const { userInfo, myTier } = useSelector(
    (state: RootState) => state.myAccount,
  );
  const { user } = useAuth();

  const [showTimeline, setShowTimeline] = useState<boolean>(false);
  const [selectedPackage, setSelectedPackage] = useState<PackageType>(
    {} as PackageType,
  );
  const [boxesSoldQuantity, setBoxesSoldQuantity] = useState<[]>([]);
  const [winner, setWinner] = useState<any>(null);
  const [countdown, setCountdown] = useState<number>(0);
  const [isRegisteredWhitelist, setIsRegisteredWhitelist] = useState(false);
  const [openApplyWhitelistModal, setOpenApplyWhitelistModal] =
    useState<boolean>(false);

  const shouldRequireKyc = userInfo?.kycStatusText !== KYC_STATUS.APPROVED;

  const timeline = inoPool?.getTimeline();

  const fetchWinners = async () => {
    if (!pool.id || !user) return;
    const response = (await rf
      .getRequest('PoolRequest')
      .getWinners(pool.id)) as any;
    const winnerUser = response.docs.find(
      (item: any) => item.userAddress === user.getAddress(),
    );
    setWinner(winnerUser);
  };

  const getBoxesSold = async () => {
    if (!inoPool?.getContractAddress()) return;
    try {
      const boxSold = await getBoxSold(
        inoPool?.getNetwork(),
        inoPool?.getContractAddress(),
      );
      setBoxesSoldQuantity(boxSold);
    } catch (error: any) {
      setBoxesSoldQuantity([]);
      console.log(error);
    }
  };

  const fetchWhiteListParticipants = async () => {
    if (!pool.id || !user) return;
    const response = (await rf
      .getRequest('PoolRequest')
      .getWhiteListParticipants(pool.id)) as any;
    setIsRegisteredWhitelist(
      response.docs.some((item: any) => item.userAddress === user.getAddress()),
    );
  };

  const getUserAllocation = useMemo(() => {
    if (winner) {
      return Number(winner?.allocationSize);
    }
    if (inoPool?.getTimeline()?.isPublicSwapPhase()) {
      return inoPool.getFreeBuyAllocation();
    }
  }, [pool.id]);
  const quantity =
    inoPool?.getPackages() &&
    inoPool
      ?.getPackages()
      .reduce((pre, curr) => pre + Number(curr.quantity), 0);

  const _renderWarningConnectWallet = () => (
    <div className={styles['info']}>
      <WarningRedIcon />
      Connect your wallet to apply whitelist and join swap
    </div>
  );

  const _renderWarningRegisterKycWhiteList = () => (
    <div className={styles['info']}>
      <WarningRedIcon />
      Complete KYC to apply whitelist
    </div>
  );

  const _renderWarningSwitchNetwork = () => (
    <div className={styles['info']}>
      <WarningRedIcon />
      Please switch to the required network to apply whitelist, purchase and
      claim tokens
    </div>
  );

  const _renderWarningIneligibleTier = () => (
    <div>
      <div className={styles['info']}>
        <WarningRedIcon />
        Your current tier does not meet pool's requirements.
        <br />
        View your profile and upgrade your Tier to apply whitelist of this
        project
      </div>
    </div>
  );
  const _renderUpcoming = () => (
    <div className={styles['info']}>
      <InfoCircleIcon />
      Please wait until Whitelist time to apply to join
    </div>
  );
  const _renderSaleEnd = () => {
    return (
      <div className={styles['info']}>
        <InfoCircleIcon />
        This INO pool has ended.
      </div>
    );
  };

  const _renderApplyWhiteList = () => (
    <>
      <div>
        <div className={styles['info']}>
          <InfoCircleIcon />
          You are qualified to apply whitelist
        </div>
      </div>
    </>
  );

  const _renderWarningWaitingForWinnerList = () => {
    if (!timeline) {
      return;
    }
    const privateSwapPhase: PhaseType = timeline.getPrivateSwapPhase();
    return (
      <div className={styles['info']}>
        <WarningRedIcon />
        Please wait for the winner list and allocation to be announced on{' '}
        {formatTimestamp(privateSwapPhase.startTime, 'DD/MM/YYYY')}
      </div>
    );
  };

  const _renderWhiteList = () => {
    if (!timeline || !inoPool) {
      return;
    }
    if (!user) {
      return _renderWarningConnectWallet();
    }
    if (!inoPool.isKYCValid(user)) {
      return _renderWarningRegisterKycWhiteList();
    }
    if (!inoPool.isTierEligible(user)) {
      return _renderWarningIneligibleTier();
    }
    if (!inoPool?.isEnoughJoinConditions(user)) {
      return;
    }
    if (!inoPool.isNetworkCorrect(user)) {
      return _renderWarningSwitchNetwork();
    }
    if (timeline.beforeWhitelistPhase()) {
      return _renderUpcoming();
    }
    if (!isRegisteredWhitelist) {
      return _renderApplyWhiteList();
    }
    if (timeline.afterSwapPhase()) {
      return _renderSaleEnd();
    }

    return _renderWarningWaitingForWinnerList();
  };

  const _renderWarningRegisterKycTokenSale = () => (
    <div className={styles['info']}>
      <WarningRedIcon />
      Complete KYC to join token sale
    </div>
  );
  const _renderWarningUserUnstaked = () => (
    <div className={styles['info']}>
      <WarningRedIcon />
      Your staking tier has been reduced before the Sale ends. Thus, you cannot
      purchase the tokens allocated to you
      <br />
      {new BigNumber(inoPool?.getFreeBuyAllocation() || 0).gt(0) && (
        <span>
          You can still claim the tokens purchased prior to tier reduction and
          join FCFS sale after Sales for alloction slots end
        </span>
      )}
    </div>
  );

  const _renderWarningIsNotAWinner = () => (
    <div className={styles['info']}>
      <WarningRedIcon />
      Sorry, you are not in the buyer list of this project; you can join FCFS
      sale after Sales for allocation slots end
    </div>
  );

  const _renderWaitingForSaleStart = () => (
    <div className={styles['info']}>
      <InfoCircleIcon />
      Please wait for Sale start time
    </div>
  );

  const _renderCanBuyTokenNotice = () => (
    <div className={styles['info']}>
      <InfoCircleIcon />
      You can purchase the tokens
    </div>
  );

  const _renderSwapPhase = () => {
    if (!timeline) return;
    if (!user) {
      return _renderWarningConnectWallet();
    }
    if (!inoPool?.isKYCValid(user)) {
      return _renderWarningRegisterKycTokenSale();
    }
    if (!inoPool.isNetworkCorrect(user)) {
      return _renderWarningSwitchNetwork();
    }
    const hasINOResult =
      inoPool.isPoolDeployed() || !timeline.beforeSwapPhase();
    if (!hasINOResult) {
      return _renderWarningWaitingForWinnerList();
    }
    const canBuy = user && hasINOResult && inoPool.isTierEligible(user);

    if (!inoPool.isTierEligible(user) && timeline.isSwapPhase()) {
      return _renderWarningUserUnstaked();
    }
    if (!winner && timeline.isPrivateSwapPhase()) {
      return _renderWarningIsNotAWinner();
    }
    if (timeline.beforeSwapPhase()) {
      return _renderWaitingForSaleStart();
    }
    if (canBuy && timeline.isPublicSwapPhase()) {
      return _renderCanBuyTokenNotice();
    }
    if (timeline.isSwapPhase()) {
      return _renderCanBuyTokenNotice();
    }
  };

  const renderUserInfo = () => {
    return (
      <div className={clsx(styles['user'])}>
        <div className={styles['user__info']}>
          <h6 className={styles['user__info--rank']}>
            {formatTierAsString(myTier?.tier)} User
          </h6>
          {!shouldRequireKyc ? (
            <AppBadge color="orange">
              <UnverifiedIcon />
              <span>Unverified</span>
            </AppBadge>
          ) : (
            <AppBadge color="green">
              <VerifiedIcon />
              <span>Verified</span>
            </AppBadge>
          )}
        </div>
        <div className={styles['user__action']}>
          {inoPool?.getJoinConditions().kyc &&
            user?.getKYCStatus() !== KYC_STATUS.APPROVED && (
              <AppButton sizes="small">
                <a href="/account" target="blank">
                  KYC Now
                </a>
              </AppButton>
            )}
          <AppButton sizes="small" variant="secondary">
            <a href="/staking" target="blank">
              Stake
            </a>
          </AppButton>
        </div>
      </div>
    );
  };

  const renderCountdown = () => {
    const timeline = inoPool?.getTimeline() as TimelineInterface;
    return (
      <>
        {timeline.afterSwapPhase() ? (
          'The sale has ended. See you in the upcoming projects.'
        ) : (
          <>
            <p>{getTitleCurrentPhase(timeline)}</p>
            <div>
              <span className={styles['countdown-text']}>
                {getReturnValues(countdown).days}
              </span>
              <span>Days : </span>
              <span className={styles['countdown-text']}>
                {getReturnValues(countdown).hours}
              </span>
              <span>Hours : </span>
              <span className={styles['countdown-text']}>
                {getReturnValues(countdown).minutes}
              </span>
              <span>Minutes : </span>
              <span className={styles['countdown-text']}>
                {getReturnValues(countdown).seconds}
              </span>
              <span>Seconds</span>
            </div>
          </>
        )}
      </>
    );
  };
  const onSelectPackage = (packageId: string | number) => {
    if (inoPool?.getPackages() === undefined) return;
    for (let i = 0; i < inoPool?.getPackages().length; i++) {
      if (inoPool.getPackages()[i].packageId === packageId) {
        setSelectedPackage(inoPool.getPackages()[i]);
        return;
      }
    }
  };

  const getReturnValues = (countDown: number) => {
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  useEffect(() => {
    if (!timeline) return;
    const countdown = getCountdownTimestamp(timeline);
    const interval = setInterval(() => {
      setCountdown(countdown - new Date().getTime());
    }, 1000);
    return () => clearInterval(interval);
  }, [inoPool]);

  useEffect(() => {
    fetchWinners();
    fetchWhiteListParticipants();
    getBoxesSold();
  }, [inoPool?.getId(), user?.address]);

  useEffect(() => {
    if (!inoPool?.getPackages()) return;
    setSelectedPackage(inoPool?.getPackages()[0]);
  }, [inoPool]);

  const _renderButtonBuyBox = () => {
    if (timeline?.isWhitelistPhase()) {
      return (
        <div className={styles['whitelist-info__button']}>
          <AppButton
            variant="white"
            sizes="medium"
            onClick={() => setOpenApplyWhitelistModal(true)}
          >
            Apply Whitelist
          </AppButton>
        </div>
      );
    }

    if (
      selectedPackage?.sold &&
      selectedPackage?.quantity === selectedPackage?.sold
    ) {
      return (
        <AppButton variant="warning" sizes="medium" isDisable>
          Sold Out
        </AppButton>
      );
    }

    if (timeline?.isSwapPhase()) {
      return (
        <ButtonBuyINO
          allocation={getUserAllocation || 0}
          inoPool={inoPool}
          packageItem={selectedPackage}
          tokenSymbol={inoPool?.getSwapToken()?.symbol || ''}
        />
      );
    }
  };

  return (
    <div className={styles['overview']}>
      <div className={styles['navigation']}>
        <ArrowLeftIcon />
        <Link to="/pools">Back</Link>
      </div>
      <div className={styles['overview__top']}>
        <div
          className={clsx(
            styles['overview__content--header'],
            styles['hide-desktop'],
          )}
        >
          <img
            src={inoPool?.getSwapTokenLogoUrl()}
            alt=""
            className={styles['logo']}
          />
          <h3 className="">{inoPool?.getName()}</h3>
        </div>
        <PackageSwitch
          className={styles['hide-desktop']}
          packages={inoPool?.getPackages() as PackageType[]}
          onSelectedTab={(packageId: string | number) =>
            onSelectPackage(packageId)
          }
        />
        <div className={styles['overview__container']}>
          <div className={styles['overview__image-container']}>
            <div className={clsx(styles['hide-desktop'], styles['socials'])}>
              <SocialLinks
                media={inoPool?.getProject()?.getMedia() || {}}
                iconColor="#CACCD1"
                type="menu"
              />
            </div>
            <img src={selectedPackage.logoUrl} alt="" />
            <div className={styles['overview__image-detail']}>
              {timeline?.beforeWhitelistPhase()
                ? _renderUpcoming()
                : timeline?.isSwapPhase()
                ? _renderSwapPhase()
                : timeline?.isWhitelistPhase()
                ? _renderWhiteList()
                : _renderSaleEnd()}
            </div>
          </div>

          <div className={styles['overview__content']}>
            <div
              className={clsx(
                styles['overview__content--header'],
                styles['hide-mobile'],
              )}
            >
              <img
                src={inoPool?.getSwapTokenLogoUrl()}
                alt=""
                className={styles['logo']}
              />
              <h3 className="">{inoPool?.getName()}</h3>
            </div>
            <div
              className={clsx(
                styles['overview__content--tool'],
                styles['hide-mobile'],
              )}
            >
              <PackageSwitch
                packages={inoPool?.getPackages() as PackageType[]}
                onSelectedTab={(packageId: string | number) =>
                  onSelectPackage(packageId)
                }
              />
              <div className={styles['overview__content--socials']}>
                <SocialLinks
                  media={inoPool?.getProject()?.getMedia() || {}}
                  iconColor="#CACCD1"
                />
              </div>
            </div>

            <div className={styles['whitelist-info']}>
              <div className={styles['whitelist-info__header']}>
                <div className={styles['whitelist-info__price']}>
                  <span>Price: </span>
                  <h4>
                    {selectedPackage && convertWeiToDec(selectedPackage?.price)}{' '}
                    {inoPool?.getCollateralCurrencySymbol()}
                  </h4>
                </div>
                {_renderButtonBuyBox()}
              </div>
              <hr className={styles['separator']} />
              <div className={styles['whitelist-info__countdown']}>
                {renderCountdown()}
              </div>
            </div>
            <ul className={styles['whitelist-info__info']}>
              <ModalListItem
                name="Total Boxes"
                value={formatNumber(selectedPackage?.quantity || 0)}
                className={styles['list-item']}
              />
              <ModalListItem
                name="Boxes Sold"
                value={formatNumber(selectedPackage.sold)}
                className={styles['list-item']}
              />
              <ModalListItem
                name="Remaining"
                value={formatNumber(
                  selectedPackage?.quantity - selectedPackage.sold,
                )}
                className={styles['list-item']}
              />
            </ul>
            <div className={styles['user-container']}>
              <div className={styles['hide-mobile']}>{renderUserInfo()}</div>
              <div
                className={clsx(
                  styles['user__collapse-header'],
                  styles['hide-desktop'],
                )}
                onClick={() => setShowTimeline(!showTimeline)}
              >
                <div className={styles['user__collapse-header--left']}>
                  <span className={styles['point']} />
                  <p>whitelist</p>
                </div>
                <span
                  className={`${
                    styles['user__collapse-header--icon-collapse']
                  } ${
                    styles[
                      !showTimeline ? 'user__collapse-header--collapsed' : ''
                    ]
                  }`}
                />
              </div>
              <Collapse
                in={showTimeline}
                className={clsx(styles['hide-desktop'])}
                classes={{
                  root: styles['collapse'],
                }}
              >
                {renderUserInfo()}
                <div className={styles['timeline-mobile']}>
                  {inoPool && (
                    <CardTimeLine
                      timeline={inoPool?.getTimeline()}
                      type="INO"
                      className={styles['timeline-element']}
                    />
                  )}
                </div>
              </Collapse>
            </div>
            <div
              className={clsx(
                styles['timeline-desktop'],
                styles['hide-mobile'],
              )}
            >
              {inoPool && (
                <CardTimeLine
                  timeline={inoPool?.getTimeline()}
                  className={styles['timeline-element']}
                  pool
                  type="INO"
                />
              )}
            </div>
          </div>
        </div>
        <div
          className={clsx(
            styles['phase-info'],
            timeline?.afterWhitelistPhase() && styles['phase-info-border'],
          )}
        >
          <ModalListItem
            secondaryColor
            className={styles['phase-info__item']}
            name="Eligible Tiers"
            value={
              inoPool
                ?.getJoinConditions()
                ?.tiers.map((item) => formatTierAsString(item))
                .join(', ') || 'NaN'
            }
          />
          <ModalListItem
            secondaryColor
            className={styles['phase-info__item']}
            name="KYC required"
            value={inoPool?.getJoinConditions()?.kyc ? 'Yes' : 'No'}
          />
          <ModalListItem
            secondaryColor
            className={styles['phase-info__item']}
            name="Network"
            value={
              inoPool?.getNetwork()
                ? getNetworkConfig(inoPool?.getNetwork())?.name
                : '--'
            }
          />
          <ModalListItem
            secondaryColor
            className={styles['phase-info__item']}
            name="Quantity"
            value={quantity || 0}
          />
          {timeline?.afterWhitelistPhase() && isRegisteredWhitelist && (
            <ModalListItem
              secondaryColor
              className={styles['phase-info__item']}
              name="Your Allocation"
              value={getUserAllocation || 0}
            />
          )}
        </div>
        {timeline?.isSwapPhase() ||
          (timeline?.afterSwapPhase() && (
            <div className={styles['phase-info__timer']}>
              <ModalListItem
                secondaryColor
                className={clsx(
                  styles['phase-timer'],
                  styles['phase-info__item'],
                  styles['list-item'],
                )}
                name="Time exclusive sale"
                valueClassName={styles['phase-info__timer--item']}
                value={
                  <div className={styles['phase-info__timer--item']}>
                    <div>
                      <span>From</span>
                      <span className={styles['time']}>
                        {timeline &&
                          formatTimestamp(
                            timeline.getPrivateSwapPhase().startTime,
                            'hh:mm:ss MMM DD, yyyy',
                          )}
                      </span>
                    </div>
                    <div>
                      <span>To</span>
                      <span className={styles['time']}>
                        {timeline &&
                          formatTimestamp(
                            timeline.getPrivateSwapPhase().endTime,
                            'hh:mm:ss MMM DD, yyyy',
                          )}
                      </span>
                    </div>
                  </div>
                }
              />
              <ModalListItem
                secondaryColor
                className={clsx(
                  styles['phase-timer'],
                  styles['phase-info__item'],
                )}
                name="Time for FCFS Sale"
                valueClassName={styles['phase-info__timer--item']}
                value={
                  <div className={styles['phase-timer__item']}>
                    <div>
                      <span>From</span>
                      <span className={styles['time']}>
                        {timeline?.getPublicSwapPhase() !== null
                          ? formatTimestamp(
                              timeline?.getPublicSwapPhase()?.startTime || 0,
                              'hh:mm:ss MMM DD, yyyy',
                            )
                          : ''}
                      </span>
                    </div>
                    <div>
                      <span>To</span>
                      <span className={styles['time']}>
                        {timeline?.getPublicSwapPhase() !== null
                          ? formatTimestamp(
                              timeline?.getPublicSwapPhase()?.endTime || 0,
                              'hh:mm:ss MMM DD, yyyy',
                            )
                          : ''}
                      </span>
                    </div>
                  </div>
                }
              />
            </div>
          ))}
      </div>
      <ModalApplyWhitelist
        open={openApplyWhitelistModal}
        onClose={() => setOpenApplyWhitelistModal(false)}
        poolId={pool.id}
        onSuccess={() => console.log('success')}
        socialList={inoPool?.getProject()?.getMedia()}
      />
    </div>
  );
};

export default INOOverview;

interface PackageSwitchProps {
  onSelectedTab: (packageId: string | number) => void;
  packages: PackageType[];
  className?: string;
}

const PackageSwitch = ({
  onSelectedTab,
  className,
  packages,
}: PackageSwitchProps) => {
  const [packageActive, setPackageActive] = useState<string | number | null>(
    null,
  );

  useEffect(() => {
    if (!packages) return;
    setPackageActive(packages[0].packageId);
  }, [packages]);

  const onSelectTab = (value: string | number) => {
    setPackageActive(value);
    onSelectedTab(value);
  };

  return (
    <ul className={clsx(styles['button-tabs'], className)}>
      {packageActive !== null
        ? packages.map((item: PackageType) => {
            return (
              <li
                key={item.packageId}
                className={
                  packageActive === item.packageId ? styles['active'] : ''
                }
                onClick={() => onSelectTab(item.packageId)}
              >
                <span>{item.name}</span>
              </li>
            );
          })
        : null}
    </ul>
  );
};

interface ButtonBuyINOProps {
  packageItem?: PackageType;
  allocation: number | string;
  tokenSymbol?: string;
  inoPool?: INOPool;
  soldOut?: boolean;
}
const ButtonBuyINO = (props: ButtonBuyINOProps) => {
  const { packageItem, inoPool, allocation, tokenSymbol, soldOut } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
      <AppButton onClick={() => setOpenModal(true)} sizes="medium">
        Buy now
      </AppButton>
      <ModalBuyINO
        open={openModal}
        onClose={() => setOpenModal(false)}
        allocation={allocation}
        packageItem={packageItem}
        tokenSymbol={tokenSymbol}
        inoPool={inoPool}
      />
    </>
  );
};
