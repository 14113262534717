import styles from 'src/styles/modals/ModalStuck.module.scss';
import { FC, useState } from 'react';
import AppButton from 'src/components/AppButton';
import AppInput from 'src/components/AppInput';
import BaseModal from './BaseModal';

interface ModalStuckProps {
  open: boolean;
  onClose: () => void;
}

const ModalStuck: FC<ModalStuckProps> = ({ open, onClose }) => {
  const [txHash, setTxHash] = useState<string>('');

  const onConfirm = () => {
    console.log('onConfirm');
  };

  return (
    <BaseModal
      title={'Stuck?'}
      open={open}
      onClose={onClose}
      onActionLeft={onClose}
      textActionLeft="No"
      onActionRight={onConfirm}
      textActionRight="Yes"
    >
      <>
        <div className={styles['main']}>
          <div className={styles['box-message']}>
            Please reset with the latest tx hash from your wallet to continue.
          </div>

          <div className={styles['label']}>
            Stuck? Please paste the latest tx hash from your wallet once it
            completes.
          </div>
          <AppInput
            type="text"
            value={txHash}
            handleChange={(value: string) => setTxHash(value)}
            placeholder="0x122..."
            endAdornment={
              <div className={styles['adornment-end']}>
                <div className={styles['slot']}>
                  <AppButton sizes={'small'} onClick={() => setTxHash('')}>
                    Reset
                  </AppButton>
                </div>
              </div>
            }
          />
        </div>
      </>
    </BaseModal>
  );
};

export default ModalStuck;
