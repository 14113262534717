import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  BaseSyntheticEvent,
} from 'react';
import styles from 'src/styles/components/MyPool.module.scss';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { ArrowCircleDownIcon, CalendarIcon, ClockIcon } from 'src/assets/icons';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import {
  formatTimestamp,
  formatNumber,
  convertWeiToDec,
} from 'src/utils/utils-formats';
import { Link } from 'react-router-dom';
import {
  ClaimPhaseType,
  IdoTimeline,
  WhitelistPhaseType,
} from 'src/utils/timelines';
import { Collapse, Tooltip } from '@material-ui/core';
import AppButton from 'src/components/AppButton';
import {
  getClaimedTokenUserInfo,
  getMaxClaimableTokensByTime,
  PHASES,
  getIDOPhaseClassName,
  isDisableClaim,
  getPoolDetailLink,
} from 'src/utils/utils-pool';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import useAuth from 'src/hooks/useAuth';
import { IDOPool, PoolResponseType } from 'src/utils/pool';
import { switchNetwork } from 'src/utils/utils-auth';
import useIDOPool from 'src/hooks/useIDOPool';
import BigNumber from 'bignumber.js';
import ModalConnectSolana from 'src/modals/ModalConnectSolana';

interface IMyIDOPoolType {
  pool: PoolResponseType;
}

const MyIDOPool = (props: IMyIDOPoolType) => {
  const { pool } = props;
  const { user } = useAuth();

  const timeline = useMemo(() => new IdoTimeline(pool), [pool]);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [purchasedTokens, setPurchasedTokens] = useState<string | BigNumber>(
    '0',
  );
  const [claimedToken, setClaimedToken] = useState('0');
  const [openConnectSolanaModal, setOpenConenctSolanaModal] =
    useState<boolean>(false);

  const idoPool = useMemo(() => new IDOPool(pool), [pool]);
  const { claimToken } = useIDOPool();
  const claimSchedules = idoPool.getClaimSchedules();
  const contractAddress = idoPool.getContractAddress();
  const network = idoPool.getNetwork();

  /** start:: state, variable on mobile */
  const [openCollapse, setOpenCollapse] = useState(false);
  /** end:: state, variable on mobile */

  const getInfosPool = useCallback(async () => {
    if (!user || !contractAddress || !network) return;
    try {
      const { claimedToken, purchasedToken } = await getClaimedTokenUserInfo(
        idoPool,
        user,
      );
      setClaimedToken(claimedToken);
      setPurchasedTokens(purchasedToken);
    } catch (error) {
      console.log(error);
    }
  }, [network, user, contractAddress, user?.getLinkedProviders()]);

  useEffect(() => {
    getInfosPool();
  }, [user, contractAddress, user?.getLinkedProviders()]);

  const onClaimTokens = useCallback(
    async (e: BaseSyntheticEvent) => {
      e.stopPropagation();
      await claimToken(idoPool, user);
    },
    [idoPool, user],
  );

  const maxClaimableTokensByTime = getMaxClaimableTokensByTime(
    claimSchedules,
    Number(purchasedTokens),
  );

  const isClaimDone = useMemo(() => {
    return +claimedToken > 0 && +claimedToken === +purchasedTokens;
  }, [claimSchedules, claimedToken, purchasedTokens]);

  const isDisableClaimButton = isDisableClaim(
    idoPool,
    user,
    claimedToken,
    maxClaimableTokensByTime,
  );

  const getTimeNextClaimPhase = useMemo(() => {
    let nextTime = 0;
    const currentTime = moment().unix();
    for (let index = 0; index < claimSchedules.length; index++) {
      if (index === claimSchedules.length - 1) {
        nextTime = claimSchedules[claimSchedules.length - 1].startTime;
        break;
      } else {
        if (
          claimSchedules[index].startTime <= currentTime &&
          currentTime < claimSchedules[index + 1].startTime
        ) {
          nextTime = claimSchedules[index + 1].startTime;
          break;
        }
      }
    }
    return Number(nextTime) * 1000;
  }, [claimSchedules]);

  const isAfterLastClaimPhase = useMemo(() => {
    const currentTime = moment().unix();
    return currentTime > claimSchedules[claimSchedules.length - 2].startTime;
  }, [claimSchedules]);

  const calcInvestAmount = () => {
    const swapRate = idoPool.getSwapRate();
    const tokenDecimal = idoPool.getCollateralCurrencyDecimals();
    return new BigNumber(convertWeiToDec(purchasedTokens, tokenDecimal))
      .multipliedBy(swapRate)
      .toString();
  };

  const onToggleConnectSolanaModal = () => {
    setOpenConenctSolanaModal((prevState) => !prevState);
  };

  const onSwitchToAcceptedNetwork = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    if (!user) {
      return;
    }
    if (idoPool.isNetworkSolana()) {
      onToggleConnectSolanaModal();
    } else {
      switchNetwork(network, user.getProvider());
    }
  };

  const _renderButtonClaim = () => {
    if (timeline.beforeClaimPhase() || isClaimDone) {
      return null;
    }
    if (user && !idoPool.isNetworkCorrect(user)) {
      return (
        <AppButton
          sizes="mini"
          onClick={onSwitchToAcceptedNetwork}
          variant="white"
        >
          Switch Network
        </AppButton>
      );
    }

    return (
      <AppButton
        className={styles['btn-claim']}
        onClick={onClaimTokens}
        sizes="mini"
        isDisable={isDisableClaimButton}
      >
        Claim
      </AppButton>
    );
  };

  const _renderForMobile = () => {
    const whitelistPhase: WhitelistPhaseType = timeline.getWhitelistPhase();
    const claimPhase: ClaimPhaseType = timeline.getClaimPhase();
    const { startTime: swapStartTime, endTime: swapEndTime } =
      timeline.getSwapTime();
    return (
      <>
        <div
          onClick={() => setOpenCollapse((openCollapse) => !openCollapse)}
          className={styles['card-mypool-header']}
        >
          <div className={styles['flex-header']}>
            <Link to={getPoolDetailLink(pool)} className={styles['link-pool']}>
              <span className={styles['icon']}>
                <img src={idoPool.getSwapTokenLogoUrl()} alt="" />
              </span>
              <Tooltip title={pool?.name} placement="top-start">
                <h5 className={styles['title']}>{pool?.name}</h5>
              </Tooltip>
            </Link>
            <span
              className={`${styles['icon-collapse']} ${
                !openCollapse ? styles['collapsed'] : ''
              }`}
            />
          </div>
        </div>
        <div className={styles['card-mypool-subheader']}>
          <label className={styles['label']}>Invest</label>
          <h6 className={styles['text']}>
            {formatNumber(calcInvestAmount())}{' '}
            {Number(purchasedTokens) > 0 &&
              idoPool.getCollateralCurrencySymbol()}
          </h6>
        </div>
        <Collapse
          in={openCollapse}
          timeout="auto"
          unmountOnExit
          style={{ borderTop: '1px solid #1F2646', marginTop: '16px' }}
        >
          <div className={styles['card-mypool-body']}>
            <div className={styles['card-mypool-item']}>
              <div className={styles['flex-title-item']}>
                <span
                  className={`${styles['after-status']} ${
                    styles[getIDOPhaseClassName(timeline, PHASES.WHITE_LIST)]
                  } `}
                />
                <label className={styles['label']}>Whitelist</label>
              </div>
              <div className={styles['detail']}>
                <div className={styles['row']}>
                  <span className={styles['label-icon']}>
                    <ClockIcon />
                  </span>
                  <span className={styles['text']}>
                    {formatTimestamp(
                      timeline.beforeWhitelistPhase()
                        ? whitelistPhase.startTime
                        : whitelistPhase.endTime,
                      'HH:mm',
                    )}
                  </span>
                </div>
                <div className={styles['row']}>
                  <span className={styles['label-icon']}>
                    <CalendarIcon />
                  </span>
                  <span className={styles['text']}>
                    {formatTimestamp(
                      timeline.beforeWhitelistPhase()
                        ? whitelistPhase.startTime
                        : whitelistPhase.endTime,
                      'DD/MM/YYYY',
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div className={styles['card-mypool-item']}>
              <div className={styles['flex-title-item']}>
                <span
                  className={`${styles['after-status']} ${
                    styles[getIDOPhaseClassName(timeline, PHASES.TOKEN_SALE)]
                  }`}
                />
                <label className={styles['label']}>Swap</label>
              </div>
              <div className={styles['detail']}>
                <div className={styles['row']}>
                  <span className={styles['label-icon']}>
                    <ClockIcon />
                  </span>
                  <span className={styles['text']}>
                    {formatTimestamp(
                      timeline.isSwapPhase() || timeline.afterSwapPhase()
                        ? swapEndTime
                        : swapStartTime,
                      'HH:mm',
                    )}
                  </span>
                </div>
                <div className={styles['row']}>
                  <span className={styles['label-icon']}>
                    <CalendarIcon />
                  </span>
                  <span className={styles['text']}>
                    {formatTimestamp(
                      timeline.isSwapPhase() || timeline.afterSwapPhase()
                        ? swapEndTime
                        : swapStartTime,
                      'DD/MM/YYYY',
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div className={styles['card-mypool-item']}>
              <div className={styles['flex-title-item']}>
                <span
                  className={`${styles['after-status']} ${
                    styles[getIDOPhaseClassName(timeline, PHASES.CLAIM)]
                  }`}
                />
                <label className={styles['label']}>Rewards Claim</label>
                {_renderButtonClaim()}
              </div>
              <div className={styles['detail']}>
                {isClaimDone ? (
                  <div>You claimed all tokens!</div>
                ) : (
                  <>
                    {timeline.hasVestingSchedule() && (
                      <div className={styles['row']}>
                        {isAfterLastClaimPhase
                          ? 'You can claim the rest at'
                          : 'You can claim at'}
                      </div>
                    )}
                    <div className={styles['row']}>
                      <span className={styles['label-icon']}>
                        <ClockIcon />
                      </span>
                      <span className={styles['text']}>
                        {formatTimestamp(
                          timeline.hasVestingSchedule()
                            ? getTimeNextClaimPhase
                            : claimPhase.startTime,
                          'HH:mm',
                        )}
                      </span>
                    </div>
                    <div className={styles['row']}>
                      <span className={styles['label-icon']}>
                        <CalendarIcon />
                      </span>
                      <span className={styles['text']}>
                        {formatTimestamp(
                          timeline.hasVestingSchedule()
                            ? getTimeNextClaimPhase
                            : claimPhase.startTime,
                          'DD/MM/YYYY',
                        )}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Collapse>
      </>
    );
  };

  const _renderForDesktop = () => {
    const handleExpandAccordion = () => {
      setIsExpanded((preState) => !preState);
    };
    const whitelistPhase: WhitelistPhaseType = timeline.getWhitelistPhase();
    const claimPhase: ClaimPhaseType = timeline.getClaimPhase();
    const { startTime: swapStartTime, endTime: swapEndTime } =
      timeline.getSwapTime();
    return (
      <Accordion
        className={styles['my-pool-item']}
        expanded={isExpanded}
        onChange={handleExpandAccordion}
      >
        <AccordionSummary
          expandIcon={<ArrowCircleDownIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={`${styles['content-pool']} row`}>
            <div className={`${styles['pool-name']} col-md-3`}>
              <Link to={getPoolDetailLink(pool)} className="link-detail">
                <img src={idoPool.getSwapTokenLogoUrl()} alt="" />
                <Tooltip title={pool?.name} placement="top-start">
                  <div className={styles['title']}>{pool?.name}</div>
                </Tooltip>
              </Link>
            </div>
            <div className={`${styles['pool-step']} col-md-5`}>
              <div
                className={`${styles['step']} ${
                  styles[getIDOPhaseClassName(timeline, PHASES.WHITE_LIST)]
                }`}
              ></div>
              <div
                className={`${styles['step']} ${
                  styles[getIDOPhaseClassName(timeline, PHASES.TOKEN_SALE)]
                }`}
              ></div>
              <div
                className={`${styles['step']} ${
                  styles[getIDOPhaseClassName(timeline, PHASES.CLAIM)]
                }`}
              ></div>
            </div>

            <div className={`${styles['action']} col-md-1`}>
              {_renderButtonClaim()}
            </div>

            <div className={`${styles['value-invest']} col-md-2`}>
              {formatNumber(calcInvestAmount())}{' '}
              {Number(purchasedTokens) > 0 &&
                idoPool.getCollateralCurrencySymbol()}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="pool--list-content">
          <div className={`${styles['content-pool']} row`}>
            <div className="col-md-3" />
            <div className={`${styles['time-step']} col-md-6`}>
              <div className={styles['content-step']}>
                {timeline.beforeWhitelistPhase() ? (
                  <>
                    <div>Start in</div>
                    <div>
                      {formatTimestamp(
                        whitelistPhase.startTime,
                        'HH:mm - DD/MM/YYYY',
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    End in
                    <div>
                      {formatTimestamp(
                        whitelistPhase.endTime,
                        'HH:mm - DD/MM/YYYY',
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className={styles['content-step']}>
                {timeline.isSwapPhase() || timeline.afterSwapPhase() ? (
                  <>
                    End in
                    <div>
                      {formatTimestamp(swapEndTime, 'HH:mm - DD/MM/YYYY')}
                    </div>
                  </>
                ) : (
                  <>
                    Start in
                    <div>
                      {formatTimestamp(swapStartTime, 'HH:mm - DD/MM/YYYY')}
                    </div>
                  </>
                )}
              </div>
              <div className={styles['content-step']}>
                <>
                  {isClaimDone ? (
                    <div style={{ textAlign: 'center' }}>
                      You claimed <br />
                      all tokens!
                    </div>
                  ) : (
                    <>
                      {timeline.hasVestingSchedule() ? (
                        <>
                          {isAfterLastClaimPhase ? (
                            <div style={{ textAlign: 'center' }}>
                              You can <br />
                              claim the rest in
                            </div>
                          ) : (
                            'Next Claim in'
                          )}
                          <div>
                            {formatTimestamp(
                              getTimeNextClaimPhase,
                              'HH:mm - DD/MM/YYYY',
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          Start in
                          <div>
                            {formatTimestamp(
                              claimPhase.startTime,
                              'HH:mm - DD/MM/YYYY',
                            )}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <div
      className={`${isMobile ? styles['card-mypool'] : styles['pool-item']}`}
    >
      {isMobile ? _renderForMobile() : _renderForDesktop()}
      <ModalConnectSolana
        open={openConnectSolanaModal}
        network={idoPool.getNetwork()}
        onClose={onToggleConnectSolanaModal}
      />
    </div>
  );
};

export default MyIDOPool;
