import { toastError, toastSuccess, toastWarning } from './utils-notify';
import { isString } from 'lodash';
import BigNumber from 'bignumber.js';
import retry from 'async-retry';

const COMMON_ERROR_MESSAGE =
  'An unexpected error occurred. Please contact the administrator for more details.';

export const copyToClipboard = (message: string) => {
  if (!navigator.clipboard) {
    return toastWarning({ message: "Your browser doesn't support copy" });
  }
  try {
    navigator.clipboard.writeText(message);
    toastSuccess({ message: 'Copied' });
  } catch (error: any) {
    toastError({ message: 'Oops. Something went wrong' });
  }
};

export const roundNumber = (
  number: number | string | BigNumber,
  roundMode = BigNumber.ROUND_DOWN,
) => {
  const decimalPlaces = 8;
  const newNumber = new BigNumber(number).toFixed(decimalPlaces, roundMode);
  return new BigNumber(newNumber).toString();
};

export const appRetry = async (callback: any, numberRetry = 3) => {
  const blockTime = 3000; // 3s
  await retry(callback, {
    retries: numberRetry,
    minTimeout: 2 * blockTime,
    maxTimeout: 3 * blockTime,
  });
};

export const getErrorMessage = (err: any) => {
  // Init regex inside a function to reset regex (reset lastIndex)
  const REGEX_GET_MESSAGE = /execution reverted:([^"]*)/gm;
  if (err.message?.includes('execution reverted:')) {
    const match = REGEX_GET_MESSAGE.exec(err.message);
    return match ? match[1] : COMMON_ERROR_MESSAGE;
  }
  if (isString(err)) {
    return err;
  }
  if (err.message && isString(err.message)) {
    return err.message;
  }
  return COMMON_ERROR_MESSAGE;
};
