import { forwardRef, ReactNode, Ref } from 'react';
import {
  Dialog,
  DialogActions,
  DialogClassKey,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@material-ui/core';
import styles from 'src/styles/modals/BaseModal.module.scss';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { CloseTransparentIcon } from 'src/assets/icons';
import AppButton from 'src/components/AppButton';

export interface BaseModalProps extends DialogProps {
  classes?: Partial<ClassNameMap<DialogClassKey>>;
  title?: string;
  isCloseIcon?: boolean;
  customAction?: ReactNode;
  onClose?: () => void;
  onActionLeft?: () => void;
  textActionLeft?: string;
  customBtnLeft?: ReactNode;
  onActionRight?: () => void;
  textActionRight?: string;
  customBtnRight?: ReactNode;
  fullScreenMobile?: boolean;
  maxWidth?: 'xl' | 'xs' | 'sm' | 'md' | 'lg';
}

const BaseModal = forwardRef((props: BaseModalProps, ref: Ref<unknown>) => {
  const {
    classes,
    title,
    isCloseIcon = true,
    customAction,
    onClose,
    onActionLeft,
    textActionLeft = 'Cancel',
    customBtnLeft,
    onActionRight,
    textActionRight = 'Confirm',
    customBtnRight,
    fullScreenMobile,
    maxWidth = 'xs',
    children,
    ...otherProps
  } = props;

  const classDefault: Partial<ClassNameMap<DialogClassKey>> = {
    paper: `${styles['paper']} ${fullScreenMobile ? styles['paper-full'] : ''}`,
    paperWidthXs: styles['paper-width-xs'],
  };

  return (
    <Dialog
      onClose={onClose}
      classes={{
        ...classDefault,
        ...classes,
      }}
      {...otherProps}
      keepMounted
      maxWidth={maxWidth}
      aria-describedby="custom-dialog"
      ref={ref}
    >
      <DialogTitle
        classes={{
          root: styles['dialog-title-root'],
        }}
      >
        <div className={styles['dialog-title']}>
          <span
            className={`${styles[!isCloseIcon ? 'center' : '']} ${
              styles['dialog-title-text']
            }`}
          >
            {title}
          </span>
          {isCloseIcon && onClose && (
            <div className={styles['dialog-title-right']}>
              <button
                type="button"
                onClick={onClose}
                className={styles['btn-close']}
              >
                <CloseTransparentIcon />
              </button>
            </div>
          )}
        </div>
      </DialogTitle>
      <DialogContent classes={{ root: styles['dialog-content-root'] }}>
        {children}
      </DialogContent>
      <DialogActions classes={{ root: styles['dialog-action-root'] }}>
        {customAction && (
          <div className={styles['dialog-action']}>{customAction}</div>
        )}
        {!customAction && (onActionLeft || onActionRight) && (
          <div className={styles['dialog-action']}>
            <div className={styles['dialog-action-group']}>
              <div className={styles['action']}>
                {customBtnLeft ? (
                  customBtnLeft
                ) : (
                  <AppButton
                    variant="outline"
                    className={styles['action-btn']}
                    onClick={onActionLeft}
                  >
                    {textActionLeft}
                  </AppButton>
                )}
              </div>
              <div className={styles['action']}>
                {customBtnRight ? (
                  customBtnRight
                ) : (
                  <AppButton
                    variant="primary"
                    className={styles['action-btn']}
                    onClick={onActionRight}
                  >
                    {textActionRight}
                  </AppButton>
                )}
              </div>
            </div>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
});

export default BaseModal;
