import { withWidth } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router';
import styles from 'src/styles/components/StakingPoolDetail.module.scss';
import {
  BackIcon,
  CloseModalIcon,
  DetailOverviewIcon,
  StakeIcon,
  UnstakeIcon,
} from 'src/assets/icons';
import { formatTimestamp, formatWeiNumber } from 'src/utils/utils-formats';
import {
  getNFTCard,
  getPendingWithdrawal,
  getUserStakingPool,
  makeWithdrawParams,
} from 'src/utils/utils-farm';
import {
  getAllowance,
  getTokenInfo,
  makeApproveParams,
} from 'src/utils/utils-token';
import useAuth from 'src/hooks/useAuth';
import { processTransaction } from 'src/store/transactions';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import LoadingIcon from 'src/assets/icons/LoadingIcon';
import { toggleConnectWalletModal } from 'src/store/authentication';
import { NOT_AVAILABLE_TEXT } from 'src/utils/common';
import { isMobile } from 'react-device-detect';
import ModalUserStakingOverview from 'src/modals/ModalUserStakingOverview';
import { AppBroadcast } from 'src/utils/utils-broadcast';
import { isAddress } from 'ethers/lib/utils';
import config from 'src/config';
import AppButton from 'src/components/AppButton';
import ModalStakeFarm from 'src/modals/ModalStakeFarm';
import ModalUnStakeFarm from '../../../modals/ModalUnstakeFarm';
import ModalClaimFarm from '../../../modals/ModalClaimFarm';

const PartStakingPool = (props: any) => {
  const { pool, wrongChain } = props;

  const dispatch = useDispatch();
  const { user } = useAuth();

  const [openOverview, setOpenOverview] = useState<boolean>(false);
  const [userStakingPool, setUserStakingPool] = useState<any>(null);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [tokenInfo, setTokenInfo] = useState<any>(null);
  const [pendingWithdrawal, setPendingWithdrawal] = useState<{
    amount: string;
    releaseTime: string;
  } | null>(null);

  const [showStakeModal, setShowStakeModal] = useState(false);
  const [showUnstakeModal, setShowUnstakeModal] = useState(false);
  const [showClaimModal, setShowClaimModal] = useState(false);
  const [tokenAllowance, setTokenAllowance] = useState<any>(null);

  useEffect(() => {
    document.body.style.overflow =
      isMobile && Object.keys(pool).length ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  });

  useEffect(() => {
    if (pool && pool?.lpToken[0]) {
      getTokenInfo(pool?.lpToken[0], config.supportStakingNetwork).then(
        (payload) => {
          setTokenInfo(payload);
        },
      );
    }
  }, [pool?.poolAddress]);

  const onClose = () => {
    AppBroadcast.dispatch('CLOSE_DETAIL_STAKING_POOL');
  };

  const fetchAllowanceInfo = async () => {
    if (
      !tokenInfo?.contractAddress ||
      !user?.getAddress() ||
      !pool?.poolAddress
    ) {
      return;
    }
    const allowance = await getAllowance(
      config.supportStakingNetwork,
      tokenInfo?.contractAddress,
      user?.getAddress(),
      pool?.poolAddress,
    );
    setTokenAllowance(allowance);
  };

  useEffect(() => {
    if (!tokenInfo?.contractAddress || !user || !pool?.poolAddress) {
      return;
    }
    fetchAllowanceInfo().then();
  }, [tokenInfo?.contractAddress, user?.getAddress(), pool?.poolAddress]);

  const fetchUserData = () => {
    if (pool?.poolAddress) {
      setIsLoadingData(true);
      Promise.all([fetchPendingWithdrawalInfo(), fetchUserStakingPool()]).then(
        () => {
          setIsLoadingData(false);
        },
      );
    }
  };

  const fetchPendingWithdrawalInfo = async () => {
    if (!isAddress(pool.poolAddress) || !user) {
      return;
    }
    const payload = await getPendingWithdrawal(
      pool?.poolAddress,
      user?.getAddress(),
    );
    setPendingWithdrawal(payload);
  };

  const fetchUserStakingPool = async () => {
    if (!isAddress(pool.poolAddress) || !user) {
      return;
    }
    const payload = await getUserStakingPool(
      user?.getAddress(),
      pool?.poolAddress,
    );
    setUserStakingPool(payload);
  };

  useEffect(() => {
    fetchUserData();

    AppBroadcast.on('LOAD_USER_INFORMATION', () => {
      fetchUserData();
    });
    return () => {
      AppBroadcast.remove('LOAD_USER_INFORMATION');
    };
  }, [user?.getAddress(), pool?.poolAddress]);

  const _renderEnableButton = () => {
    const approve = async () => {
      if (!pool.poolAddress || !tokenInfo || !tokenInfo?.contractAddress) {
        return null;
      }
      const params = makeApproveParams(
        tokenInfo?.contractAddress,
        pool.poolAddress,
      );
      await dispatch(
        processTransaction({ provider: user?.getProvider(), params }),
      );
      fetchAllowanceInfo().then();
    };

    return (
      <AppButton
        className={`${styles['btn']}`}
        isDisable={wrongChain || !user}
        sizes="big"
        onClick={() => approve()}
      >
        <span className={styles['text']}>Enable</span>
      </AppButton>
    );
  };

  const _renderHarvest = () => {
    return (
      <div className={styles['main']}>
        <div className={styles['value']}>
          <span className={styles['text']}>BUNI Earned</span>
        </div>
        <div className={styles['box-harverst']}>
          <span className={styles['value-harverst']}>
            {formatWeiNumber(userStakingPool?.pendingReward)}
          </span>
          <AppButton
            sizes="small"
            isDisable={
              !userStakingPool?.pendingReward ||
              userStakingPool?.pendingReward === '0' ||
              wrongChain
            }
            onClick={() => setShowClaimModal(true)}
          >
            Harvest
          </AppButton>
        </div>
      </div>
    );
  };

  const _renderWithdraw = () => {
    const handleWithdraw = async () => {
      if (!isAddress(pool.poolAddress)) {
        return;
      }
      const params = makeWithdrawParams(pool.poolAddress);
      await dispatch(
        processTransaction({ provider: user?.getProvider(), params }),
      );
      AppBroadcast.dispatch('LOAD_USER_INFORMATION');
    };
    return (
      <>
        <div className={styles['head']}>
          <div className={styles['label']}>
            <span className={styles['icon']} />
            <span className={styles['text']}>
              {tokenInfo?.symbol} withdrawal amount
            </span>
          </div>
          <div className={styles['detail-overview']}>
            <button
              className={styles['btn-withdraw']}
              onClick={handleWithdraw}
              disabled={
                !pendingWithdrawal ||
                Number(pendingWithdrawal.releaseTime) > moment().unix() ||
                wrongChain
              }
            >
              Withdraw
            </button>
          </div>
        </div>
        <div className={styles['main']}>
          <div className={styles['value']}>
            <span className={styles['text']}>
              {formatWeiNumber((pendingWithdrawal?.amount || 0).toString())}
            </span>
          </div>
        </div>
      </>
    );
  };

  const _renderAvailableAt = () => {
    return (
      <>
        <div className={styles['head']}>
          <div className={styles['label']}>
            <span className={styles['icon']} />
            <span className={styles['text']}>Available at</span>
          </div>
        </div>
        <div className={styles['main']}>
          <div className={styles['value']}>
            <span className={styles['text']}>
              {pendingWithdrawal?.releaseTime
                ? formatTimestamp(
                    Number(pendingWithdrawal.releaseTime) * 1000,
                    'YYYY-MM-DD - HH:mm:ss',
                  )
                : NOT_AVAILABLE_TEXT}
            </span>
          </div>
        </div>
      </>
    );
  };

  const _renderButtonStake = () => {
    return (
      <AppButton
        className={`${styles['btn']}`}
        isDisable={wrongChain}
        onClick={() => setShowStakeModal(true)}
        sizes="big"
      >
        <StakeIcon />
        <span className={styles['text']}>Stake</span>
      </AppButton>
    );
  };

  const _renderButtonUnstake = () => {
    if (!userStakingPool) {
      return;
    }
    return (
      <AppButton
        className={`${styles['btn']}`}
        isDisable={wrongChain || userStakingPool?.stakedAmount === '0'}
        onClick={() => setShowUnstakeModal(true)}
        variant="outline"
        sizes="big"
      >
        <UnstakeIcon />
        <span className={styles['text']}>Unstake</span>
      </AppButton>
    );
  };

  const _renderGroupButtonStake = () => {
    if (!user) return;
    return (
      <div className={styles['group-btn']}>
        {_renderButtonUnstake()}
        {_renderButtonStake()}
      </div>
    );
  };

  const _renderButtonConnect = () => {
    const handleClickConnectWallet = () => {
      dispatch(toggleConnectWalletModal(true));
    };

    return (
      <AppButton
        className={`${styles['btn']}`}
        onClick={handleClickConnectWallet}
        sizes="big"
      >
        <span className={styles['text']}>Connect Wallet</span>
      </AppButton>
    );
  };

  const _renderEnablePool = () => {
    return (
      <div>
        <div className={styles['text-enable']}>
          <DetailOverviewIcon />{' '}
          <span>
            Thank you for your staking contribution. Your current BUNI Earned is
            eligible with [NFT name] NFT above. Enable to harvest this NFT or
            stake/unstake this token.
          </span>
        </div>
        <div className={styles['group-btn']}>
          {isMobile ? (
            <div className={styles['pool-btn-stake-mobile']}>
              <div className={styles['stake-footer']}>
                {user ? _renderEnableButton() : _renderButtonConnect()}
              </div>
            </div>
          ) : (
            <div className={styles['pool-btn-stake']} style={{ width: '100%' }}>
              <div className={styles['stake-footer']} style={{ padding: 0 }}>
                {user ? _renderEnableButton() : _renderButtonConnect()}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const _renderContentPool = () => {
    return (
      <>
        <div className={styles['pool-body']}>
          {isMobile && (
            <div
              className={styles['pool-detail-slot']}
              onClick={() => setOpenOverview(true)}
            >
              <div className={styles['head']}>
                <div className={styles['label']}>
                  <span className={styles['icon']} />
                  <span className={styles['text']}>Overview</span>
                </div>
                <div className={styles['detail-overview']}>
                  <div className={styles['btn-view']}>
                    <DetailOverviewIcon />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={styles['pool-detail-slot']}>{_renderHarvest()}</div>
          {pendingWithdrawal && Number(pendingWithdrawal.amount) > 0 && (
            <>
              <div className={styles['pool-detail-slot']}>
                {_renderWithdraw()}
              </div>
              <div className={styles['pool-detail-slot']}>
                {_renderAvailableAt()}
              </div>
            </>
          )}
        </div>

        <div className="group-btn-stake">
          {!isMobile ? (
            <div className={styles['pool-btn-stake']}>
              <div className={styles['stake-footer']}>
                {_renderGroupButtonStake()}
              </div>
            </div>
          ) : (
            <div className={styles['pool-btn-stake-mobile']}>
              <div className={styles['stake-footer']}>
                {_renderGroupButtonStake()}
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  const _renderShowLoadingIcon = () => {
    return (
      <div>
        <LoadingIcon width={100} height={100} />
      </div>
    );
  };

  const shouldRenderEnablePool = useMemo(() => {
    return !user || (tokenAllowance !== null && Number(tokenAllowance) === 0);
  }, [tokenAllowance, pool?.poolAddress]);

  const _renderView = () => {
    return (
      <>
        <div
          className={
            isMobile ? styles['pool-thumb-mobile'] : styles['pool-thumb']
          }
        >
          <img
            className={styles['img-thumb']}
            src={getNFTCard(pool?.pendingReward?.toString()).smallImgUrl}
            alt=""
          />
        </div>
        {!isLoadingData && (
          <>
            {shouldRenderEnablePool
              ? _renderEnablePool()
              : _renderContentPool()}
          </>
        )}

        <ModalStakeFarm
          open={showStakeModal}
          onClose={() => setShowStakeModal(false)}
          tokenDetails={tokenInfo}
          poolAddress={pool.poolAddress}
          stakingAmount={userStakingPool?.stakedAmount}
        />

        <ModalUnStakeFarm
          poolAddress={pool.poolAddress}
          tokenDetails={tokenInfo}
          stakingAmount={userStakingPool?.stakedAmount}
          open={showUnstakeModal}
          onClose={() => setShowUnstakeModal(false)}
        />

        <ModalClaimFarm
          poolAddress={pool.poolAddress}
          pendingReward={pool?.pendingReward}
          open={showClaimModal}
          onClose={() => setShowClaimModal(false)}
        />

        {isMobile && (
          <ModalUserStakingOverview
            open={openOverview}
            onClose={() => setOpenOverview(false)}
          />
        )}
      </>
    );
  };

  return (
    <>
      <div className={styles['pool-info']}>
        <div className={styles['pool-header']}>
          <button
            className={styles['btn-back']}
            type="button"
            onClick={onClose}
          >
            <BackIcon />
          </button>
          <div className={styles['pool-intro']}>
            <img src="/images/BuniLogo.svg" alt="" />
            {pool?.lpSymbol?.includes('BNB') && (
              <img src="/images/bsc.svg" alt="" />
            )}
            <div className={styles['content']}>
              <h3 className={styles['symbol']}>{pool?.lpSymbol}</h3>
              <span className={styles['name']}>{pool?.name}</span>
            </div>
          </div>
          <button
            className={styles['btn-close']}
            type="button"
            onClick={onClose}
          >
            <CloseModalIcon />
          </button>
        </div>
        {isLoadingData && _renderShowLoadingIcon()}
        {!isLoadingData && _renderView()}
      </div>
    </>
  );
};

export default withWidth()(withRouter(PartStakingPool));
