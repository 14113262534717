import styles from 'src/styles/pages/ProjectDetail.module.scss';
import { useEffect, useState } from 'react';
import rf from 'src/requests/RequestFactory';
import PoolCard from 'src/components/PoolCard';
import { VisibilityGridPoolsEnum } from 'src/constants';
import { isWidthUp, withWidth } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

const RelatedPools = (props: any) => {
  const { projectId } = props;
  const [pools, setPools] = useState([]);

  const getPools = async () => {
    const params = {
      sortBy: 'type',
      sortType: 'asc',
    };
    try {
      const response = (await rf
        .getRequest('ProjectRequest')
        .getPoolsInProject(projectId, params)) as any;
      response && response.docs && setPools(response.docs);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getPools();
  }, [projectId]);

  return (
    <div className={styles['pool-list']}>
      {pools && !!pools.length && (
        <>
          <div className={styles['title']}>Pool List</div>
          <div
            className={!isWidthUp('lg', props.width) ? ' row' : ''}
            style={{ justifyContent: 'center' }}
          >
            {pools.map((pool: any) => (
              <PoolCard
                pool={pool}
                key={pool.id}
                type={
                  isWidthUp('lg', props.width)
                    ? VisibilityGridPoolsEnum.ROW
                    : VisibilityGridPoolsEnum.COLUMN
                }
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default withWidth()(withRouter(RelatedPools));
