import {
  useState,
  useEffect,
  useImperativeHandle,
  Ref,
  forwardRef,
  ChangeEvent,
  useRef,
  FC,
} from 'react';
import { FormHelperText, Switch } from '@material-ui/core';
import styles from 'src/styles/pages/LPB/steps/StepPoolInfo.module.scss';
import AppButton from 'src/components/AppButton';
import AppInput from 'src/components/AppInput';
import { AuctionType, MediaInterface, RefStep } from 'src/utils/utils-auction';
import { createValidator } from 'src/utils/utils-validator';

interface PoolInfoProps {
  auction: AuctionType;
  onClickBack: () => void;
  onClickNext: () => void;
}

interface IFooterSetupPool {
  isDisabledNextStep: boolean;
  onHandleBack: () => void;
  onHandleNext: () => void;
}

export const FooterSetupPool: FC<IFooterSetupPool> = ({
  onHandleBack,
  onHandleNext,
  isDisabledNextStep,
}) => {
  return (
    <div className={`${styles['wrap-footer']}`}>
      <div className={styles['footer-fixed']}>
        <div className={styles['row']}>
          <div className={styles['group-button-left']}>
            <AppButton
              variant="outline"
              onClick={onHandleBack}
              className={styles['btn']}
            >
              Back
            </AppButton>
          </div>
          <div className={styles['group-button-right']}>
            <AppButton
              variant="primary"
              onClick={onHandleNext}
              isDisable={isDisabledNextStep}
              className={styles['btn']}
            >
              Next
            </AppButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const StepPoolInfo = forwardRef((props: PoolInfoProps, ref: Ref<RefStep>) => {
  const { auction, onClickBack, onClickNext } = props;

  const [isDisableNextStep, setIsDisableNextStep] = useState(true);
  const [internalAuction, setInternalAuction] = useState(auction);

  const { description, media, swapFee, permissions } = internalAuction;

  const validator = useRef(
    createValidator({
      element: (message: string) => (
        <FormHelperText error>{message}</FormHelperText>
      ),
    }),
  );

  useEffect(() => {
    setInternalAuction(auction);
  }, [auction]);

  useEffect(() => {
    const isDisabled = !validator.current.allValid();
    setIsDisableNextStep(isDisabled);
  }, [description, media, swapFee, permissions]);

  const validate = async () => {
    if (isDisableNextStep) throw new Error('Invalid validation');
  };

  useImperativeHandle(ref, () => ({
    validate,
    auction: internalAuction,
  }));

  const onHandleNext = () => {
    if (isDisableNextStep) return;
    onClickNext();
  };

  const onChangeDescription = (newDescription: string) => {
    setInternalAuction((prevState) => ({
      ...prevState,
      description: newDescription,
    }));
  };

  const onChangeMedia = (newMedia: MediaInterface) => {
    setInternalAuction((prevState) => ({ ...prevState, media: newMedia }));
  };

  const onChangeSwapFee = (newSwapFee: number) => {
    setInternalAuction((prevState) => ({
      ...prevState,
      swapFee: newSwapFee / 100,
    }));
  };

  const onChangePermissions = (newPermissions: {
    pauseTrading: boolean;
    pullLiquidity: boolean;
  }) => {
    setInternalAuction((prevState) => ({
      ...prevState,
      permissions: newPermissions,
    }));
  };

  return (
    <>
      <div className={styles['wrap-item']}>
        <div className={styles['card']}>
          <div className={`${styles['card-body']}`}>
            <div className={styles['title-body']}>Pool Information</div>
            {/* sub step 1 */}
            <div className={styles['section-content-none']}>
              <div className={styles['row']}>
                <div className={styles['col']}>
                  <div className={styles['field']}>
                    <label className={styles['label']}>Description</label>
                    <AppInput
                      placeholder={'Enter description'}
                      multiline
                      fullWidth
                      rows={3}
                      maxRows={3}
                      value={description}
                      validate={{
                        name: 'description',
                        validator: validator.current,
                        rule: 'max:3000',
                      }}
                      handleChange={(value: string) =>
                        onChangeDescription(value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles['section-content-none']}>
              <div className={styles['row']}>
                <div className={styles['col']}>
                  <div className={styles['field']}>
                    <label className={styles['label']}>Website</label>
                    <AppInput
                      placeholder={'Enter website address'}
                      type="url"
                      value={media.website}
                      validate={{
                        name: 'website',
                        validator: validator.current,
                        rule: 'url',
                      }}
                      handleChange={(value: string) => {
                        onChangeMedia({ ...media, website: value });
                      }}
                    />
                    <span className={styles['note']}>
                      Please enter a valid URL that starts with "http://" or
                      "https://"
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles['section-content-none']}>
              <div className={styles['row']}>
                <div className={styles['col-lg-6']}>
                  <div className={styles['field']}>
                    <label className={styles['label']}>Telegram</label>
                    <AppInput
                      placeholder={'Enter telegram address'}
                      type="url"
                      value={media.telegram}
                      validate={{
                        name: 'telegram',
                        validator: validator.current,
                        rule: 'url',
                      }}
                      handleChange={(value: string) =>
                        onChangeMedia({ ...media, telegram: value })
                      }
                    />
                  </div>
                </div>
                <div className={styles['col-lg-6']}>
                  <div className={styles['field']}>
                    <label className={styles['label']}>Twitter</label>
                    <AppInput
                      placeholder={'Enter twitter address'}
                      type="url"
                      value={media.twitter}
                      validate={{
                        name: 'twitter',
                        validator: validator.current,
                        rule: 'url',
                      }}
                      handleChange={(value: string) =>
                        onChangeMedia({ ...media, twitter: value })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles['section-content-none']}>
              <div className={styles['row']}>
                <div className={styles['col-lg-6']}>
                  <div className={styles['field']}>
                    <label className={styles['label']}>Discord</label>
                    <AppInput
                      placeholder={'Enter discord address'}
                      type="url"
                      value={media.discord}
                      validate={{
                        name: 'discord',
                        validator: validator.current,
                        rule: 'url',
                      }}
                      handleChange={(value: string) =>
                        onChangeMedia({ ...media, discord: value })
                      }
                    />
                  </div>
                </div>
                <div className={styles['col-lg-6']}>
                  <div className={styles['field']}>
                    <label className={styles['label']}>Medium</label>
                    <AppInput
                      placeholder={'Enter medium address'}
                      type="url"
                      value={media.medium}
                      validate={{
                        name: 'medium',
                        validator: validator.current,
                        rule: 'url',
                      }}
                      handleChange={(value: string) =>
                        onChangeMedia({ ...media, medium: value })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles['section-content-none']}>
              <div className={styles['row']}>
                <div className={styles['col']}>
                  <div className={styles['field']}>
                    <label className={styles['label']}>Swap Fee</label>
                    <AppInput
                      type="number"
                      value={swapFee * 100}
                      validate={{
                        name: 'swapFee',
                        validator: validator.current,
                        rule: 'numeric|minValue:0.01|maxValue:10',
                      }}
                      handleChange={(value: string) =>
                        onChangeSwapFee(Number(value))
                      }
                      endAdornment={
                        <div className={styles['adornment-end']}>
                          <div className={styles['text']}>%</div>
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles['section-content-none']}>
              <div className={styles['row-switch']}>
                <div className={styles['switch-control']}>
                  <label className={styles['label']}>Pause trading</label>
                  <Switch
                    checked={permissions.pauseTrading}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onChangePermissions({
                        ...permissions,
                        pauseTrading: e.target.checked,
                      })
                    }
                    disabled
                    classes={{
                      root: styles['root'],
                      track: styles['track'],
                      switchBase: styles['switchBase'],
                      checked: styles['checked'],
                    }}
                  />
                </div>
                <div className={styles['switch-control']}>
                  <label className={styles['label']}>Pull liquidity</label>
                  <Switch
                    checked={permissions.pullLiquidity}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onChangePermissions({
                        ...permissions,
                        pullLiquidity: e.target.checked,
                      })
                    }
                    disabled
                    classes={{
                      root: styles['root'],
                      track: styles['track'],
                      switchBase: styles['switchBase'],
                      checked: styles['checked'],
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterSetupPool
        onHandleBack={onClickBack}
        onHandleNext={onHandleNext}
        isDisabledNextStep={isDisableNextStep}
      />
    </>
  );
});

export default StepPoolInfo;
