import { Collapse } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { ArrowLeftIcon } from 'src/assets/icons';
import AppChartDonut from 'src/components/AppChartDonut';
import AppChartLine from 'src/components/AppChartLine';
import ModalShowInfoDetail from 'src/modals/ModalShowInfoDetail';
import stylesProjectInfo from 'src/styles/components/ProjectInfos.module.scss';
import stylesTabMetricInfos from 'src/styles/components/TabMetricInfos.module.scss';
import { Erc20TokenType } from 'src/utils/pool';
import {
  AdvisorType,
  BackerType,
  ProjectInterface,
  TeamType,
  TokenomicType,
} from 'src/utils/project';
import { formatNumber, formatTimestamp } from 'src/utils/utils-formats';
import { A11y, Grid, Navigation, Pagination, Scrollbar } from 'swiper';
import 'swiper/modules/grid/grid.scss';
import 'swiper/modules/navigation/navigation.scss';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper.scss'; // core Swiper

interface IPartProjectContentView {
  project: ProjectInterface;
  hiddenProjectContent?: boolean;
  hiddenProjectInfos?: boolean;
  currency?: Erc20TokenType;
  namePage?: string;
  classNameInfo?: string;
}

const menuInfos = [
  {
    id: 'project-overview',
    label: 'Overview',
  },
  {
    id: 'roadmap',
    label: 'Roadmap',
  },
  {
    id: 'backers',
    label: 'Backers',
  },
  {
    id: 'advisors',
    label: 'Advisors',
  },
  {
    id: 'team',
    label: 'Team',
  },
];

const MAX_LENGTH_TEXT_ADVISOR = 150;
const MAX_LENGTH_TEXT_TEAM = 100;
const ProjectContentView: React.FC<IPartProjectContentView> = ({
  project,
  hiddenProjectContent,
  hiddenProjectInfos,
  currency,
  namePage,
  classNameInfo,
}) => {
  /**project content */
  const TAB_POOL_INFO = {
    METRICS: 0,
    TOKENOMICS: 1,
  };
  const [activeTab, setActiveTab] = useState<number>(TAB_POOL_INFO.METRICS);
  const keyMetrics = project.getKeyMetrics();

  const isActiveTab = (tab: number) => {
    return activeTab === tab;
  };

  const _renderItemMetricsTab = (
    title: string,
    value: any,
    className?: string,
  ) => {
    return (
      <div className={stylesTabMetricInfos['metric-item'] + ` ${className}`}>
        <div className={stylesTabMetricInfos['label']}>
          <div className={stylesTabMetricInfos['dot']} />
          <div className={stylesTabMetricInfos['text']}>{title || ''}</div>
        </div>
        <div className={stylesTabMetricInfos['value']}>{value || ''}</div>
      </div>
    );
  };

  const _renderMetricsTab = () => {
    return (
      <div
        className={`${stylesTabMetricInfos['metrics']} row ${stylesTabMetricInfos['metrics-tokenomics']}`}
      >
        <div className="col-12 col-md-6 d-flex flex-column flex-grow-1">
          {_renderItemMetricsTab(
            'Accepted Currency',
            currency?.symbol || '',
            'flex-grow-1',
          )}
          {_renderItemMetricsTab(
            'Token Supply',
            formatNumber(keyMetrics?.totalSupply || 0),
            '',
          )}
          {namePage === 'project-detail' &&
            _renderItemMetricsTab(
              'Public Raise',
              `$${formatNumber(keyMetrics?.publicRaise || 0)}`,
              '',
            )}
        </div>
        <div className="col-12 col-md-6">
          {_renderItemMetricsTab(
            'Blockchain Network',
            project.getNetworkAsString(),
          )}

          {_renderItemMetricsTab(
            'Project Valuation',
            formatNumber(keyMetrics?.valuation || 0),
          )}

          {namePage === 'project-detail' &&
            _renderItemMetricsTab(
              ' Initial Market Cap',
              `$${formatNumber(keyMetrics?.initialMarketCap || 0)}`,
            )}
        </div>
        {namePage !== 'project-detail' && (
          <div className="col-12">
            {_renderItemMetricsTab(
              ' Initial Market Cap',
              `$${formatNumber(keyMetrics?.initialMarketCap || 0)}`,
            )}
          </div>
        )}
      </div>
    );
  };
  const tokenomics = project.getTokenomics();

  const _renderSaleRoundDetailDesktop = () => {
    return (
      <div className={stylesTabMetricInfos['table-sales-round']}>
        <div className={stylesTabMetricInfos['header'] + ' row'}>
          <div className={stylesTabMetricInfos['cell'] + ' col-4'} />
          <div className={stylesTabMetricInfos['cell'] + ' col-1'}>Price</div>
          <div className={stylesTabMetricInfos['cell'] + ' col-2'}>
            Fundraised
          </div>
          <div className={stylesTabMetricInfos['cell'] + ' col-5'}>Lock-up</div>
        </div>
        <div className={stylesTabMetricInfos['body']}>
          {tokenomics.map((tokenomic: TokenomicType) => {
            return (
              <div
                className={stylesTabMetricInfos['row'] + ' row'}
                key={tokenomic.title}
              >
                <div className={stylesTabMetricInfos['cell'] + ' col-4'}>
                  {tokenomic.title}
                </div>
                <div className={stylesTabMetricInfos['cell'] + ' col-1'}>
                  ${formatNumber(tokenomic.price || 0)}
                </div>
                <div className={stylesTabMetricInfos['cell'] + ' col-2'}>
                  {formatNumber(tokenomic.totalAllocation)}
                </div>
                <div className={stylesTabMetricInfos['cell'] + ' col-5'}>
                  {tokenomic.description}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const _renderSaleRoundDetailMobile = () => {
    const CollapseSaleRoundDetailsMobile = (propsCollapse: any) => {
      const { saleRound } = propsCollapse;
      const [open, setOpen] = useState(false);
      return (
        <div className={stylesTabMetricInfos['card-staking']}>
          <div
            onClick={() => setOpen((pre) => !pre)}
            className={stylesTabMetricInfos['card-staking-header']}
          >
            <div className={stylesTabMetricInfos['title-header']}>
              {saleRound.title}
            </div>
            <span
              className={`${stylesTabMetricInfos['icon-collapse']} ${
                !open ? stylesTabMetricInfos['collapsed'] : ''
              }`}
            />
          </div>
          <div className={stylesTabMetricInfos['card-staking-body']}>
            <div className={stylesTabMetricInfos['card-staking-item']}>
              <label className={stylesTabMetricInfos['label']}>Price</label>
              <h6 className={stylesTabMetricInfos['text']}>
                ${formatNumber(saleRound.price)}
              </h6>
            </div>
          </div>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {saleRound && (
              <div className={stylesTabMetricInfos['card-staking-body']}>
                <div className={stylesTabMetricInfos['card-staking-item']}>
                  <label className={stylesTabMetricInfos['label']}>
                    Fundraised
                  </label>
                  <h6 className={stylesTabMetricInfos['text']}>
                    {formatNumber(saleRound.totalAllocation)}
                  </h6>
                </div>
                <div className={stylesTabMetricInfos['card-staking-item']}>
                  <label className={stylesTabMetricInfos['label']}>
                    Lock-up
                  </label>
                  <h6 className={stylesTabMetricInfos['text']}>
                    {saleRound.description}
                  </h6>
                </div>
              </div>
            )}
          </Collapse>
        </div>
      );
    };

    return (
      <>
        {tokenomics.map((tokenomic: any) => {
          return (
            <CollapseSaleRoundDetailsMobile
              saleRound={tokenomic}
              key={tokenomic.title}
            />
          );
        })}
      </>
    );
  };

  const _renderTabTokenomics = () => {
    return (
      <div className={stylesTabMetricInfos['tokenomics']}>
        <div>
          <div className={stylesTabMetricInfos['title']}>
            Data provided by project
          </div>

          <AppChartDonut id="tokenomics-chart" tokenData={tokenomics} />
        </div>
        <div>
          <div className={stylesTabMetricInfos['title']}>
            Sales round details
          </div>
          {isMobile
            ? _renderSaleRoundDetailMobile()
            : _renderSaleRoundDetailDesktop()}
        </div>

        <div>
          <div className={stylesTabMetricInfos['title']}>
            Token release schedule
          </div>

          <AppChartLine id="token-release-chart" tokenData={tokenomics} />
        </div>
      </div>
    );
  };

  /**project infos */
  const [menuActive, setMenuActive] = useState<string>('project-overview');
  const [isShowFull, setIsShowFull] = useState<boolean>(false);
  const [isFixedMenu, setIsFixedMenu] = useState<boolean>(false);
  const [isOpenModalInfoDetail, setIsOpenModalInfoDetail] =
    useState<boolean>(false);
  const [personInfo, setPersonInfo] = useState<any>({});

  function scrollTotDiv(id: string) {
    setIsShowFull(true);
    setMenuActive(id);
  }

  useEffect(() => {
    const getDistanceToTop = (id: string) => {
      // @ts-ignore
      return document.getElementById(id).getBoundingClientRect().top;
    };
    const onScrollPage = () => {
      const distanceEndToTop = getDistanceToTop('btn-show-more');
      const distanceProjectInfoToTop = getDistanceToTop('project-info');

      menuInfos.forEach((item) => {
        const distanceToTop = getDistanceToTop(item.id);

        if (distanceToTop <= 200 && distanceToTop > 100 && isShowFull) {
          setMenuActive(item.id);
        }
      });

      setIsFixedMenu(distanceProjectInfoToTop <= 120 && distanceEndToTop > 150);
    };
    document.addEventListener('scroll', onScrollPage);
    return () => {
      document.removeEventListener('scroll', onScrollPage);
    };
  }, [isFixedMenu, isShowFull]);

  const CollapseRoadMap = (props: any) => {
    const { data, keyIndex } = props;
    const roadMaps = project.getRoadMaps();
    const [open, setOpen] = useState(false);
    const currentTime = moment().unix();
    return (
      <div className={stylesProjectInfo['roadmap-item']}>
        <div
          className={
            stylesProjectInfo['circle-status'] +
            ' ' +
            stylesProjectInfo[
              `${currentTime > data.startTime ? 'active' : ''}`
            ] +
            ' ' +
            stylesProjectInfo[
              `${
                currentTime > roadMaps[keyIndex + 1]?.startTime
                  ? 'active-line'
                  : ''
              }`
            ]
          }
        />
        <div className={stylesProjectInfo['card-roadmap']}>
          <div
            onClick={() => setOpen((openCard) => !openCard)}
            className={stylesProjectInfo['header']}
          >
            <div>
              <div>
                <div className={stylesProjectInfo['name']}>{data.title}</div>
                <div className={stylesProjectInfo['date']}>
                  {formatTimestamp(data.startTime * 1000, 'DD MMM YYYY')}
                  {' - '}
                  {formatTimestamp(data.endTime * 1000, 'DD MMM YYYY')}
                </div>
              </div>
              <span
                className={`${stylesProjectInfo['icon-collapse']} ${
                  !open ? stylesProjectInfo['collapsed'] : ''
                }`}
              />
            </div>
          </div>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <div
              className={stylesProjectInfo['description']}
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
          </Collapse>
        </div>
      </div>
    );
  };

  const _renderRoadMap = () => {
    const roadMaps = project.getRoadMaps();
    return (
      <div className={stylesProjectInfo['roadmap']}>
        {roadMaps.map((item: any, index: number) => {
          return <CollapseRoadMap key={index} data={item} keyIndex={index} />;
        })}
      </div>
    );
  };

  const _renderBackers = () => {
    const backers = project.getBackers();
    return (
      <div className={`${stylesProjectInfo['backers']} row`}>
        {backers.map((backer: BackerType, index: number) => {
          return (
            <div
              className={`${stylesProjectInfo['item']} col-lg-3 col-4`}
              key={index}
            >
              <div className={stylesProjectInfo['logo']}>
                <img src={backer.logoUrl} alt="" />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const _renderAdvisors = () => {
    const advisors = project.getAdvisors();
    return (
      <div className={stylesProjectInfo['slide-advisors']}>
        <Swiper
          className="slide-advisors"
          modules={[Scrollbar, Navigation, A11y]}
          spaceBetween={40}
          slidesPerView={1.5}
          navigation={{
            prevEl: '.advisors-prevSlider',
            nextEl: '.advisors-nextSlider',
          }}
          breakpoints={{
            500: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            993: {
              slidesPerView: 2.5,
            },
            1200: {
              slidesPerView: 3,
            },
          }}
        >
          {advisors.map((advisor: AdvisorType, index: number) => {
            return (
              <SwiperSlide key={index}>
                <div className={stylesProjectInfo['advisors-item']}>
                  <div className={stylesProjectInfo['avatar']}>
                    <div className={stylesProjectInfo['avatar-img']}>
                      <img src={advisor.avatar} alt="" />
                    </div>
                  </div>
                  <div className={stylesProjectInfo['name']}>
                    {advisor.fullName}
                  </div>
                  <div className={stylesProjectInfo['line']} />
                  <div className={stylesProjectInfo['description']}>
                    {advisor.intro.length <= MAX_LENGTH_TEXT_ADVISOR ? (
                      advisor.intro
                    ) : (
                      <>
                        {`${advisor.intro.substring(
                          0,
                          MAX_LENGTH_TEXT_ADVISOR,
                        )}... `}
                        <span
                          className={stylesProjectInfo['read-more']}
                          onClick={() => {
                            setIsOpenModalInfoDetail(true);
                            setPersonInfo({
                              type: 'Advisors',
                              info: advisor,
                            });
                          }}
                        >
                          Read more
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    );
  };

  const _renderTeams = () => {
    const teams = project.getTeams();
    return (
      <div className={stylesProjectInfo['slide-team']}>
        <Swiper
          className="slide-team"
          modules={[Scrollbar, Pagination, A11y, Grid]}
          spaceBetween={40}
          slidesPerView={1.5}
          grid={{
            fill: 'row',
          }}
          pagination={{
            el: '.team-pagination',
            type: 'bullets',
            renderBullet: (_index, className) => {
              return '<span class="' + className + '"></span>';
            },
            clickable: true,
          }}
          breakpoints={{
            500: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            993: {
              slidesPerView: 2.5,
              grid: {
                rows: 2,
              },
            },
            1200: {
              slidesPerView: 3,
              grid: {
                rows: 2,
              },
            },
          }}
        >
          {teams.map((team: TeamType, index: number) => {
            return (
              <SwiperSlide
                className={stylesProjectInfo['slide-team-item']}
                key={index}
              >
                <div className={stylesProjectInfo['team-item']}>
                  <div className={stylesProjectInfo['avatar']}>
                    <img src={team.avatar} alt="" />
                  </div>
                  <div className={stylesProjectInfo['name']}>
                    {team.fullName}
                  </div>
                  <div className={stylesProjectInfo['position']}>
                    {team.position}
                  </div>
                  <div className={stylesProjectInfo['description']}>
                    {team.intro.length <= MAX_LENGTH_TEXT_TEAM ? (
                      team.intro
                    ) : (
                      <>
                        {`${team.intro.substring(0, MAX_LENGTH_TEXT_TEAM)}... `}
                        <span
                          className={stylesProjectInfo['read-more']}
                          onClick={() => {
                            setIsOpenModalInfoDetail(true);
                            setPersonInfo({
                              type: 'Team',
                              info: team,
                            });
                          }}
                        >
                          Read more
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div className="team-pagination" />
      </div>
    );
  };

  const _renderProjectContent = () => {
    return (
      <>
        {!hiddenProjectContent && currency && (
          <div className={stylesTabMetricInfos['tabs-info']}>
            <nav className={stylesTabMetricInfos['tab-nav']}>
              <ul className={stylesTabMetricInfos['tab-list']}>
                <li
                  className={`${stylesTabMetricInfos['tab-item']} ${
                    isActiveTab(TAB_POOL_INFO.METRICS)
                      ? stylesTabMetricInfos['tab-active']
                      : ''
                  }`}
                  onClick={() => setActiveTab(TAB_POOL_INFO.METRICS)}
                >
                  Key Metrics
                </li>
                <li
                  className={`${stylesTabMetricInfos['tab-item']} ${
                    isActiveTab(TAB_POOL_INFO.TOKENOMICS)
                      ? stylesTabMetricInfos['tab-active']
                      : ''
                  }`}
                  onClick={() => setActiveTab(TAB_POOL_INFO.TOKENOMICS)}
                >
                  Tokenomics
                </li>
              </ul>
            </nav>

            <div className={stylesTabMetricInfos['tabs-content']}>
              <div
                style={{
                  display: isActiveTab(TAB_POOL_INFO.METRICS)
                    ? 'block'
                    : 'none',
                }}
              >
                {_renderMetricsTab()}
              </div>

              <div
                style={{
                  display: isActiveTab(TAB_POOL_INFO.TOKENOMICS)
                    ? 'block'
                    : 'none',
                }}
              >
                {isActiveTab(TAB_POOL_INFO.TOKENOMICS) &&
                  _renderTabTokenomics()}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const _renderProjectInfos = () => {
    return (
      <>
        {!hiddenProjectInfos && (
          <div
            className={clsx(stylesProjectInfo['project-info'], classNameInfo)}
            id="project-info"
          >
            <div
              className={`${stylesProjectInfo['menu-info']} ${
                isFixedMenu && isShowFull ? stylesProjectInfo['menu-fixed'] : ''
              }`}
            >
              {menuInfos.map((item: any) => {
                return (
                  <a
                    href={`#${item.id}`}
                    className={
                      stylesProjectInfo[menuActive === item.id ? 'active' : '']
                    }
                    onClick={() => scrollTotDiv(item.id)}
                    style={{ cursor: 'pointer' }}
                    key={item.id}
                  >
                    {item.label} {menuActive === item.id && <ArrowLeftIcon />}
                  </a>
                );
              })}
            </div>
            <div
              className={`${stylesProjectInfo['content-info']} ${
                isFixedMenu && isShowFull
                  ? stylesProjectInfo['content-info-fixed']
                  : ''
              }`}
            >
              <div
                className={
                  stylesProjectInfo[!isShowFull ? 'show-less' : 'show-more']
                }
              >
                <div
                  className={stylesProjectInfo['item-info']}
                  id="project-overview"
                >
                  <div className={stylesProjectInfo['title']}>
                    {project.getName()} Overview
                  </div>
                  <div
                    className={stylesProjectInfo['content']}
                    dangerouslySetInnerHTML={{ __html: project.overview }}
                  />
                </div>

                <div className={stylesProjectInfo['item-info']} id="roadmap">
                  <div className={stylesProjectInfo['title']}>Roadmap</div>
                  {_renderRoadMap()}
                </div>

                <div className={stylesProjectInfo['item-info']} id="backers">
                  <div className={stylesProjectInfo['title']}>Backers</div>
                  {_renderBackers()}
                </div>

                <div className={stylesProjectInfo['item-info']} id="advisors">
                  <div className={stylesProjectInfo['title']}>
                    <div>Advisors</div>
                    <div style={{ display: 'flex' }}>
                      <div
                        className={`${stylesProjectInfo['navigation']} advisors-prevSlider`}
                      >
                        <ArrowLeftIcon />
                      </div>
                      <div
                        className={
                          stylesProjectInfo['navigation'] +
                          ' advisors-nextSlider ' +
                          stylesProjectInfo['navigation-next']
                        }
                      >
                        <ArrowLeftIcon />
                      </div>
                    </div>
                  </div>

                  {_renderAdvisors()}
                </div>
                <div className={stylesProjectInfo['item-info']} id="team">
                  <div className={stylesProjectInfo['title']}>Team</div>

                  {_renderTeams()}
                </div>
              </div>

              <div
                className={stylesProjectInfo['btn-show']}
                id="btn-show-more"
                onClick={() => {
                  if (isShowFull) {
                    setMenuActive(menuInfos[0].id);
                  }
                  setIsShowFull((prevState) => !prevState);
                }}
              >
                <img
                  src="/images/header/icon-arrow-down.svg"
                  alt="icon wallet"
                  style={{
                    transform: !isShowFull ? 'rotate(0)' : 'rotate(180deg)',
                  }}
                />
                {isShowFull ? 'Show less' : 'Show more'}
              </div>
            </div>
            <ModalShowInfoDetail
              open={isOpenModalInfoDetail}
              onClose={() => setIsOpenModalInfoDetail(false)}
              personInfo={personInfo}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {_renderProjectContent()}
      {_renderProjectInfos()}
    </>
  );
};

export default ProjectContentView;
