import { Tooltip } from '@material-ui/core';
import styles from 'src/styles/modals/ModalSettingSlippage.module.scss';
import { FC, useState } from 'react';
import AppButton from 'src/components/AppButton';
import { DetailOverviewIcon } from 'src/assets/icons';
import BaseModal from './BaseModal';

interface ModalSettingSlippageProps {
  open: boolean;
  slippage: number;
  onClose: () => void;
  onSetSlippage: (value: number) => void;
}

const SLIPPAGE_OPTIONS = [0.5, 1, 2];

const ModalSettingSlippage: FC<ModalSettingSlippageProps> = ({
  open,
  onClose,
  slippage,
  onSetSlippage,
}) => {
  const [valueSlippage, setValueSlippage] = useState('');
  return (
    <BaseModal
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-setting-title"
      aria-describedby="alert-dialog-setting-description"
      title={'Settings'}
    >
      <div className={styles['item']}>
        <div className={styles['title']}>
          Slippage tolerance
          <Tooltip
            title="The difference between the price at the confirmation time and the actual price of the transaction based on the total swap value."
            placement="top"
          >
            <DetailOverviewIcon />
          </Tooltip>
        </div>
        <div className={styles['list-slippage']}>
          {SLIPPAGE_OPTIONS.map((item: number) => {
            return (
              <AppButton
                key={item}
                sizes="small"
                variant={slippage === item ? 'primary' : 'tertiary'}
                onClick={() => onSetSlippage(item)}
                className={`${styles['btn-select-slippage']} ${
                  styles[slippage === item ? 'active' : '']
                }`}
              >
                {item.toFixed(1)}%
              </AppButton>
            );
          })}
          <div className={styles['input-slippage']}>
            <input
              value={valueSlippage}
              onChange={(e) => {
                setValueSlippage(e.target.value);
                onSetSlippage(+e.target.value);
              }}
            />
            %
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default ModalSettingSlippage;
