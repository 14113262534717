import { useEffect, useState } from 'react';
import moment from 'moment';
import BigNumber from 'bignumber.js';
import { Tooltip, withWidth } from '@material-ui/core';
import { InfoCircleIcon, WarningRedIcon } from 'src/assets/icons';
import styles from 'src/styles/pages/PoolDetail.module.scss';
import AppButton from 'src/components/AppButton';
import AppCountdown from 'src/components/AppCountdown';
import { AppBroadcast } from 'src/utils/utils-broadcast';
import {
  RELOAD_POOL_DETAILS,
  RELOAD_USER_CLAIM_INFO,
  RELOAD_WHITE_LIST_PARTICIPANTS,
  RELOAD_WINNER_LIST,
  TOGGLE_PURCHASE_TOKEN_MODAL,
} from 'src/pages/PageIDOPoolDetail';
import ModalApplyWhitelist from 'src/modals/ModalApplyWhitelist';
import {
  getCountdownTimestamp,
  getMaxClaimableTokensByTime,
  getTitleCurrentPhase,
} from 'src/utils/utils-pool';
import { IDOPool } from 'src/utils/pool';
import { PhaseType } from 'src/utils/timelines';
import useAuth from 'src/hooks/useAuth';
import { isMobile } from 'react-device-detect';
import { formatTimestamp } from 'src/utils/utils-formats';
import SocialLinks from 'src/components/SocialLinks';
import { toastSuccess } from 'src/utils/utils-notify';

interface UserClaimInfoType {
  purchasedToken: string;
  claimedToken: string;
  remainingToken: string;
}

interface PoolBannerMessagesProps {
  idoPool: IDOPool;
  claimInfo: UserClaimInfoType;
  isRegisteredWhitelist: boolean;
  isWinner: boolean;
}

const PartBanner = (props: PoolBannerMessagesProps) => {
  const { idoPool, claimInfo, isRegisteredWhitelist, isWinner } = props;

  const { user } = useAuth();
  const [openPopupConfirmWhitelist, setOpenPopupConfirmWhitelist] =
    useState<boolean>(false);

  const timeline = idoPool.getTimeline();
  const endDate = timeline ? getCountdownTimestamp(timeline) : 0;

  useEffect(() => {
    let reloadDataInterval = undefined as any;
    if (endDate && endDate >= new Date().valueOf()) {
      reloadDataInterval = setInterval(function () {
        if (endDate / 1000 === moment().unix()) {
          // TODO: optimize reload by phase
          AppBroadcast.dispatch(RELOAD_POOL_DETAILS);
          AppBroadcast.dispatch(RELOAD_WHITE_LIST_PARTICIPANTS);
          AppBroadcast.dispatch(RELOAD_WINNER_LIST);
          AppBroadcast.dispatch(RELOAD_USER_CLAIM_INFO);
        }
      }, 1000);
    }
    return () => {
      clearInterval(reloadDataInterval);
    };
  }, [endDate]);

  const onApplyWhiteListSuccess = () => {
    toastSuccess({ message: 'You have applied whitelist. Good luck!' });
    AppBroadcast.dispatch(RELOAD_WHITE_LIST_PARTICIPANTS);
  };

  const onSwap = () => {
    AppBroadcast.dispatch(TOGGLE_PURCHASE_TOKEN_MODAL, true); // open Purchase Token modal
  };

  const _renderWarningConnectWallet = () => (
    <div className={styles['info']}>
      <WarningRedIcon />
      Connect your wallet to apply whitelist and join swap
    </div>
  );

  const _renderWarningRegisterKycWhiteList = () => (
    <div className={styles['info']}>
      <WarningRedIcon />
      Complete KYC to apply whitelist
    </div>
  );

  const _renderWarningSwitchNetwork = () => (
    <div className={styles['info']}>
      <WarningRedIcon />
      Please switch to the required network to apply whitelist, purchase and
      claim tokens
    </div>
  );

  const _renderWarningIneligibleTier = () => (
    <div>
      <div className={styles['info']}>
        <WarningRedIcon />
        Your current tier does not meet pool's requirements
        <br />
        View your profile and upgrade your Tier to apply whitelist of this
        project
      </div>
    </div>
  );

  const _renderApplyWhiteList = () => (
    <>
      <div>
        <div className={styles['info']}>
          <InfoCircleIcon />
          You are qualified to apply whitelist
        </div>
      </div>
      <AppButton
        variant="white"
        className={styles['btn-apply']}
        sizes="medium"
        onClick={() => setOpenPopupConfirmWhitelist(true)}
      >
        Apply Whitelist
      </AppButton>
    </>
  );

  const _renderWarningUserUnstaked = () => (
    <div className={styles['info']}>
      <div>
        <div>
          <WarningRedIcon />
          Your staking tier has been reduced before the Sale ends. Thus, you
          cannot purchase the tokens allocated to you
        </div>
        {new BigNumber(idoPool.getFreeBuyAllocation()).gt(0) &&
          'You can still claim the tokens purchased prior to tier reduction and join FCFS sale after Sales for alloction slots end'}
      </div>
    </div>
  );

  const _renderWarningRegisterKycTokenSale = () => (
    <div className={styles['info']}>
      <WarningRedIcon />
      Complete KYC to join token sale
    </div>
  );

  const _renderWarningDidNotApplyWhiteList = () => (
    <div className={styles['info']}>
      <WarningRedIcon />
      You are not in the buyer list of this project. Stay tuned and follow other
      projects to take part in other sales
    </div>
  );

  const _renderWarningIsNotAWinner = () => (
    <div className={styles['info']}>
      <WarningRedIcon />
      Sorry, you are not in the buyer list of this project; you can join FCFS
      sale after Sales for allocation slots end
    </div>
  );

  const _renderWarningWaitingForWinnerList = () => {
    if (!timeline) {
      return;
    }
    const privateSwapPhase: PhaseType = timeline.getPrivateSwapPhase();
    return (
      <div className={styles['info']}>
        <WarningRedIcon />
        Please wait for the winner list and allocation to be announced on{' '}
        {formatTimestamp(privateSwapPhase.startTime, 'DD/MM/YYYY')}
      </div>
    );
  };

  const _renderWaitingForSaleStart = () => (
    <div className={styles['info']}>
      <InfoCircleIcon />
      Please wait for Sale start time
    </div>
  );

  const _renderCanBuyTokenNotice = () => (
    <>
      <div>
        <div className={styles['info']}>
          <InfoCircleIcon />
          You can purchase the tokens
        </div>
      </div>
      {isMobile && (
        <AppButton
          variant="white"
          sizes="medium"
          className={styles['btn-apply']}
          onClick={onSwap}
        >
          Swap
        </AppButton>
      )}
    </>
  );

  const _renderWarningRegisterKycClaim = () => (
    <div className={styles['info']}>
      <WarningRedIcon />
      Complete KYC to continue
    </div>
  );

  const _renderWarningNotJoined = () => (
    <div className={styles['info']}>
      <WarningRedIcon />
      You are not a participant of this project; Stay tuned and follow other
      projects to take part in another sales
    </div>
  );

  const _renderWaitForClaim = () => (
    <div className={styles['info']}>
      <WarningRedIcon />
      Please wait for starting time to receive your tokens
    </div>
  );

  const _renderCanClaimToken = () => (
    <div className={styles['info']}>
      <InfoCircleIcon />
      You can claim the tokens
    </div>
  );

  const _renderClaimPartialToken = () => (
    <div className={styles['info']}>
      <InfoCircleIcon />
      You have claimed all available tokens. Come back at the next schedule
    </div>
  );

  const _renderClaimAllToken = () => (
    <div className={styles['info']}>
      <InfoCircleIcon />
      Congratulations! You have claimed 100% tokens. See you in your next
      project
    </div>
  );

  const _renderUpcomming = () => (
    <div className={styles['info']}>
      <InfoCircleIcon />
      Please wait until Whitelist time to apply to join
    </div>
  );

  const _renderWhiteList = () => {
    if (!timeline) {
      return;
    }
    if (
      !user ||
      (idoPool.isNetworkSolana() && !idoPool.isSolanaConnected(user))
    ) {
      return _renderWarningConnectWallet();
    }
    if (!idoPool.isKYCValid(user)) {
      return _renderWarningRegisterKycWhiteList();
    }
    if (!idoPool.isTierEligible(user)) {
      return _renderWarningIneligibleTier();
    }
    if (!idoPool.isEnoughJoinConditions(user)) {
      return;
    }
    if (!idoPool.isNetworkCorrect(user)) {
      return _renderWarningSwitchNetwork();
    }
    if (timeline.beforeWhitelistPhase()) {
      return _renderUpcomming();
    }
    if (!isRegisteredWhitelist) {
      return _renderApplyWhiteList();
    }
    return _renderWarningWaitingForWinnerList();
  };

  const _renderTokenSale = () => {
    if (!timeline) {
      return;
    }
    if (
      !user ||
      (idoPool.isNetworkSolana() && !idoPool.isSolanaConnected(user))
    ) {
      return _renderWarningConnectWallet();
    }
    if (!idoPool.isKYCValid(user)) {
      return _renderWarningRegisterKycTokenSale();
    }
    if (!idoPool.isNetworkCorrect(user)) {
      return _renderWarningSwitchNetwork();
    }
    const isPassedFundamentalConditions = idoPool.canJoinTokenSalePhase(user);
    if (!isPassedFundamentalConditions) {
      return;
    }
    if (!isRegisteredWhitelist) {
      return _renderWarningDidNotApplyWhiteList();
    }
    const hasIDOResult =
      idoPool.isPoolDeployed() || !timeline.beforeSwapPhase();
    if (!hasIDOResult) {
      return _renderWarningWaitingForWinnerList();
    }
    const canBuy = user && hasIDOResult && idoPool.isTierEligible(user);

    if (!idoPool.isTierEligible(user) && timeline.isSwapPhase()) {
      return _renderWarningUserUnstaked();
    }
    if (!isWinner && timeline.isPrivateSwapPhase()) {
      return _renderWarningIsNotAWinner();
    }
    if (timeline.beforeSwapPhase()) {
      return _renderWaitingForSaleStart();
    }
    if (canBuy && timeline.isPublicSwapPhase()) {
      return _renderCanBuyTokenNotice();
    }
    if (timeline.isSwapPhase()) {
      return _renderCanBuyTokenNotice();
    }
  };

  const _renderRewardsClaim = () => {
    if (!timeline) {
      return;
    }
    if (
      !user ||
      (idoPool.isNetworkSolana() && !idoPool.isSolanaConnected(user))
    ) {
      return _renderWarningConnectWallet();
    }
    if (!idoPool.isKYCValid(user)) {
      return _renderWarningRegisterKycClaim();
    }
    if (!idoPool.isNetworkCorrect(user)) {
      return _renderWarningSwitchNetwork();
    }

    const isPassedFundamentalConditions = idoPool.canJoinClaimPhase(user);
    if (!isPassedFundamentalConditions) {
      return;
    }
    if (!isRegisteredWhitelist) {
      return _renderWarningNotJoined();
    }
    const { purchasedToken, claimedToken } = claimInfo;
    const didPurchaseTokens = +purchasedToken > 0;
    if (timeline.beforeClaimPhase() && didPurchaseTokens) {
      return _renderWaitForClaim();
    }

    const isAbleToClaim =
      !timeline.beforeClaimPhase() &&
      isRegisteredWhitelist &&
      didPurchaseTokens;
    const maxClaimableTokens = getMaxClaimableTokensByTime(
      idoPool.getClaimSchedules(),
      +purchasedToken,
    );
    const canClaimToken = isAbleToClaim && +claimedToken < +maxClaimableTokens;
    if (canClaimToken) {
      return _renderCanClaimToken();
    }

    const isClaimPartialToken =
      isAbleToClaim &&
      +claimedToken === +maxClaimableTokens &&
      +maxClaimableTokens < +purchasedToken;
    if (isClaimPartialToken) {
      return _renderClaimPartialToken();
    }

    const isClaimAllToken = isAbleToClaim && +claimedToken === +purchasedToken;
    if (isClaimAllToken) {
      return _renderClaimAllToken();
    }
  };

  const _renderCountdown = () => {
    return !!endDate && timeline && getTitleCurrentPhase(timeline) ? (
      <>
        <div className={styles['title-countdown']}>
          {getTitleCurrentPhase(timeline)}
        </div>
        <div className={styles['countdown-comp']}>
          <AppCountdown endDate={endDate} customClass="countdown-pool" />
        </div>
      </>
    ) : null;
  };

  const _renderInformation = () => {
    if (!timeline) {
      return;
    }
    if (timeline.beforeWhitelistPhase() || timeline.isWhitelistPhase())
      return _renderWhiteList();
    else if (timeline.shouldHighlightSwapPhase()) return _renderTokenSale();
    else if (timeline.shouldHighlightClaimPhase()) return _renderRewardsClaim();
    return null;
  };

  const project = idoPool.getProject();

  return (
    <>
      <div className={styles['banner-pool']}>
        <div className={styles['banner-img']}>
          <img src={idoPool.getImageUrl()} alt="pool banner" />
        </div>
        <div className={styles['banner-content']}>
          <div className={styles['social-mobile']}>
            {project && <SocialLinks media={project.getMedia()} type="menu" />}
          </div>
          <div className={styles['info-pool']}>
            <Tooltip title={idoPool.getName()} placement="bottom-start">
              <div className={styles['name']}>{idoPool.getName()}</div>
            </Tooltip>
            {_renderCountdown()}
            {_renderInformation()}
            <ModalApplyWhitelist
              onClose={() => setOpenPopupConfirmWhitelist(false)}
              open={openPopupConfirmWhitelist}
              poolId={idoPool.getId()}
              onSuccess={onApplyWhiteListSuccess}
              socialList={project?.getMedia()}
            />
          </div>
          <div className={styles['info-social']}>
            {project && <SocialLinks media={project.getMedia()} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default withWidth()(PartBanner);
