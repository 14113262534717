import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store';
import {
  ListNetworkIcon,
  SolanaIcon,
  VerifiedIcon,
  UnverifiedIcon,
  IronIcon,
  BronzeRankIcon,
  SliverRankIcon,
  GoldRankIcon,
  DiamondRankIcon,
  NearIcon,
  ArrowNextIcon,
} from 'src/assets/icons';
import styles from 'src/styles/pages/Profile.module.scss';
import AppButton from 'src/components/AppButton';
import config from 'src/config';
import rf from 'src/requests/RequestFactory';
import {
  formatNumber,
  formatTierAsString,
  formatWeiNumber,
} from 'src/utils/utils-formats';
import { KYC_STATUS } from 'src/constants';
import useAuth from 'src/hooks/useAuth';
import ModalLinkAccount from 'src/modals/ModalLinkAccount';
import CopyableWalletAddress from 'src/components/CopyableWalletAddress';
import { NetworkLink } from 'src/utils/project';
import AppBadge from 'src/components/AppBadge';
import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { ILinkedAccount } from 'src/utils/common';
import ModalMyAssets from 'src/modals/ModalMyAssets';
import { disconnectLinkedNetwork } from 'src/store/myAccount';
import { getSolanaNetworkId } from 'src/utils/utils-network';
import ModalComingSoon from 'src/modals/ModalComingSoon';

const PartAccountInfo = () => {
  const { stakedInfo, myTier, userInfo } = useSelector(
    (state: RootState) => state.myAccount,
  );

  const { user } = useAuth();
  const dispatch = useDispatch();
  const [gameElo, setGameElo] = useState<number>(0);

  const [selectedNetwork, setSelectedNetwork] = useState<NetworkLink | null>(
    null,
  );

  const linkAccounts = [
    {
      networkFamily: 'solana',
      connected: false,
      icon: <SolanaIcon />,
      domain: 'Bunicorn Launchpad',
      network: config.networks[getSolanaNetworkId()].id,
      walletAddress: '',
      onDisconnect: async () => {
        const res = await rf.getRequest('UserRequest').unlinkAccount();
        dispatch(disconnectLinkedNetwork(res));
        return res;
      },
    },
    {
      networkFamily: 'near',
      connected: false,
      icon: <NearIcon />,
      domain: 'Bunicorn Launchpad',
      network: config.networks[getSolanaNetworkId()].id,
      walletAddress: '',
    },
  ];

  const [listLinkNetwork, setListLinkNetwork] =
    useState<NetworkLink[]>(linkAccounts);

  const getLinkedAccounts = (linkedAccounts: ILinkedAccount[]) => {
    const tempList = linkAccounts.map((item) => {
      let tempItem = { ...item };
      linkedAccounts.forEach((account: ILinkedAccount) => {
        if (
          item.networkFamily.toLowerCase() ===
          account.networkFamily.toLowerCase()
        ) {
          tempItem = {
            ...tempItem,
            connected: true,
            walletAddress: account.networkAddress,
          };
        }
      });
      return tempItem;
    });
    setListLinkNetwork(tempList);
  };

  const getGameElo = async (userAddress: string) => {
    const res = await rf.getRequest('GameRequest').getGameElo(userAddress);
    if (!res || !res.totalElo) {
      return 0;
    }
    return res.totalElo;
  };

  const getIconRankTier = (tier: number | undefined) => {
    switch (tier) {
      case 0:
        return <IronIcon />;
      case 1:
        return <BronzeRankIcon />;
      case 2:
        return <SliverRankIcon />;
      case 3:
        return <GoldRankIcon />;
      case 5:
        return <DiamondRankIcon />;
      default:
        return '';
    }
  };

  useEffect(() => {
    if (!user?.getAddress()) {
      return;
    }
    getGameElo(user?.getAddress()).then((elo) => {
      setGameElo(elo);
    });
  }, [user?.getAddress()]);

  useEffect(() => {
    if (!user?.getLinkedAccounts()) {
      return;
    }
    getLinkedAccounts(user.getLinkedAccounts());
  }, [user?.getLinkedAccounts()]);

  const _renderKycStatus = () => {
    if (userInfo.kycStatusText === KYC_STATUS.APPROVED) {
      return (
        <div>
          <AppBadge color="green" className={styles['badge']}>
            <VerifiedIcon />
            <span>Verified</span>
          </AppBadge>
        </div>
      );
    }
    const kycLink = config.kycUrl || '';
    const handleKyc = () => {
      window.open(kycLink, '_blank');
    };
    return (
      <div className={styles['account-unverified']}>
        <div>
          <AppBadge color="orange" className={styles['badge']}>
            <UnverifiedIcon />
            <span>Unverified</span>
          </AppBadge>
        </div>
        <AppButton
          className={styles['button-kyc']}
          sizes="small"
          onClick={handleKyc}
        >
          KYC Now
        </AppButton>
      </div>
    );
  };

  const renderInfoAccount = () => {
    return (
      <>
        <div className={styles['info']}>
          <div className={styles['info-rank']}>
            {getIconRankTier(myTier?.level)}
            <div>
              <div className={styles['rank']}>
                {formatTierAsString(myTier?.tier) + ' User'}
              </div>

              <div>{_renderKycStatus()}</div>
            </div>
          </div>
          <div className={styles['address-wallet-container']}>
            <ListNetworkIcon />
            {user && (
              <CopyableWalletAddress
                walletAddress={user.getAddress()}
                className={styles['wallet-address']}
              />
            )}
          </div>
        </div>
        <div className={styles['network-links']}>
          {listLinkNetwork.map((item, index) => (
            <ButtonLinkNetwork
              key={index}
              {...item}
              onClick={() => {
                if (item.connected) return;
                setSelectedNetwork(item);
              }}
              onDisconnect={item.onDisconnect}
            />
          ))}
          {selectedNetwork?.networkFamily === 'solana' ? (
            <ModalLinkAccount
              open={selectedNetwork !== null}
              onClose={() => setSelectedNetwork(null)}
              networkLink={selectedNetwork}
            />
          ) : (
            <ModalComingSoon
              open={selectedNetwork !== null}
              onClose={() => setSelectedNetwork(null)}
            />
          )}
        </div>
      </>
    );
  };

  const renderInfoStaking = () => {
    const { buniStake, lpStake } = stakedInfo;

    const [openModalAsset, setOpenModalAsset] = useState<boolean>(false);

    const _renderRecordItem = (label: string, val: string | number) => {
      return (
        <div className={styles['info-staking-item']}>
          <div className={styles['aside']}>
            <div className={styles['adornment']} />
            <div className={styles['label']}>{label}</div>
          </div>
          <div className={`${styles['aside']} ${styles['grow']}`}>
            <div className={styles['value-ellipsis']}>
              <div className={styles['value']}>{val}</div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <>
        <div className={styles['group-staking-item']}>
          {/* {_renderRecordItem('Staking Tier', formatTierAsString(myTier?.tier))} */}
          {_renderRecordItem(
            'BUNI to Next Tier',
            formatWeiNumber(userInfo?.buniToNextTier?.toString() || ''),
          )}
          {_renderRecordItem('BUNI staked', formatWeiNumber(buniStake))}
          {_renderRecordItem('BPT LP Staked', formatWeiNumber(lpStake))}
          {_renderRecordItem('Gamer ELO', formatNumber(gameElo))}
          <div className={styles['info-staking-item']}>
            <div className={styles['view-more']}>
              <button type="button" onClick={() => setOpenModalAsset(true)}>
                View More
                <ArrowNextIcon></ArrowNextIcon>
              </button>
            </div>
          </div>
        </div>
        <ModalMyAssets
          open={openModalAsset}
          onClose={() => setOpenModalAsset(false)}
        />
      </>
    );
  };

  return (
    <>
      <div className={`${styles['info-account']} col-sm-12 col-md-7`}>
        {renderInfoAccount()}
      </div>
      <div className={`${styles['info-staking']} col-sm-12 col-md-5`}>
        {renderInfoStaking()}
      </div>
    </>
  );
};

interface ButtonLinkNetworkProps {
  networkFamily: string;
  connected: boolean;
  icon: React.ReactNode;
  className?: string;
  onClick: () => void;
  walletAddress: string;
  onDisconnect: (() => Promise<any>) | undefined;
  onDisconnectSuccess?: (address: string) => void;
}

export const ButtonLinkNetwork: FC<ButtonLinkNetworkProps> = ({
  icon,
  networkFamily,
  connected,
  className,
  onClick,
  walletAddress,
  onDisconnect,
  onDisconnectSuccess,
}) => {
  const onDisconnectNetwork = async () => {
    if (!onDisconnect) {
      return;
    }
    try {
      const res = await onDisconnect();
      onDisconnectSuccess && onDisconnectSuccess(res.networkAddress);
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <div
      className={clsx(
        styles['button-container'],
        connected && styles['button-connected'],
        !connected && styles['pointer'],
        className,
      )}
      onClick={onClick}
    >
      <div className={styles['left']}>
        {icon}
        <div className={styles['description']}>
          <p
            className={clsx(
              styles['status-text'],
              connected
                ? styles['connected-text']
                : styles['not-connected-text'],
            )}
          >
            {connected ? 'Connected' : 'Not Connected'}
          </p>
          <p className={styles['wallet-name']}>{networkFamily} Wallet</p>
          {connected && <CopyableWalletAddress walletAddress={walletAddress} />}
        </div>
      </div>
      <div className={styles['right']}>
        <div
          className={clsx(
            styles['status-point'],
            connected
              ? styles['connected-point']
              : styles['not-connected-point'],
          )}
        />
        {connected && (
          <Tooltip title="Disconnect">
            <img
              style={{ cursor: 'pointer' }}
              onClick={onDisconnectNetwork}
              src="/images/header/icon-disconnect.svg"
              alt="Disconnect"
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default PartAccountInfo;
