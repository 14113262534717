import { Tooltip } from '@material-ui/core';
import styles from 'src/styles/modals/ModalSettingLBP.module.scss';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DetailOverviewIcon } from 'src/assets/icons';
import AppSwitchButton from 'src/components/AppSwitchButton';
import { useDispatch } from 'react-redux';
import { processTransaction } from 'src/store/transactions';
import useAuth from 'src/hooks/useAuth';
import config from 'src/config';
import { formatNumber } from '../utils/utils-formats';
import {
  IAuctionResponseType,
  ITokenBase,
  ITokenMain,
  withdrawAuction,
  toggleEnableSwap,
} from '../utils/utils-auction';
import { getNetworkProvider } from '../utils/utils-network';
import { LBPProxyContract } from '../utils/utils-contract';
import BaseModal from './BaseModal';
import AppButton from 'src/components/AppButton';

interface ModalSettingLBPProps {
  open: boolean;
  onClose: () => void;
  auction: IAuctionResponseType;
  mainToken: ITokenMain;
  baseToken: ITokenBase;
  baseTokenIndex: number;
  mainTokenIndex: number;
  baseTokenAccrued: number;
  balanceCurrent: string[];
  isEnabledSwap: boolean;
  fetchStatusEnableSwap: () => void;
}

const ModalSettingLBP: FC<ModalSettingLBPProps> = ({
  open,
  onClose,
  auction,
  mainToken,
  baseToken,
  baseTokenIndex,
  baseTokenAccrued,
  mainTokenIndex,
  balanceCurrent,
  isEnabledSwap,
  fetchStatusEnableSwap,
}) => {
  const { pool, network, ownerAddress } = auction;
  const { address: poolAddress } = pool;
  const [feePercent, setFeePercent] = useState<number>(0);

  const dispatch = useDispatch();
  const { user } = useAuth();

  const handleEnable = useCallback(async () => {
    const params = await toggleEnableSwap(poolAddress, !isEnabledSwap, network);
    await dispatch(
      processTransaction({ provider: user?.getProvider(), params }),
    );
    fetchStatusEnableSwap();
  }, [isEnabledSwap, poolAddress]);

  const isNotOwner = useMemo(
    () => user?.getAddress() !== ownerAddress,
    [user, ownerAddress],
  );

  const getFeeSwap = async () => {
    const provider = getNetworkProvider(network);
    try {
      const contract = LBPProxyContract(
        config.networks[network].addresses.auctionProxy,
        provider,
      );
      const feeBPS = await contract._feeBPS();
      setFeePercent(+feeBPS.toString() / 100);
    } catch (e) {
      setFeePercent(0);
    }
  };

  const withdrawToken = async () => {
    const params = withdrawAuction(poolAddress, network);

    await dispatch(
      processTransaction({ provider: user?.getProvider(), params }),
    );
  };

  useEffect(() => {
    getFeeSwap();
  }, []);

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-setting-lbp-title"
      aria-describedby="alert-dialog-setting-lbp-description"
      title={'Settings'}
    >
      <div className={styles['wapper-field']}>
        <div className={styles['title']}>Swapping</div>
        <div className={styles['flex-swap']}>
          <div className={styles['label']}>
            {isEnabledSwap ? 'Enabled' : 'Disabled'}
          </div>
          <div className={styles['switch-ctrl']}>
            <AppSwitchButton
              onChange={handleEnable}
              checked={isEnabledSwap}
              disabled={isNotOwner}
            />
          </div>
        </div>
      </div>
      <div className={styles['wapper-field']}>
        <div className={styles['title']}>LBP Balances</div>
        <div className={styles['content-box']}>
          <div className={styles['view-paper']}>
            <div className={styles['shrink']}>
              <span className={styles['adornment']}></span>
              <span className={styles['label']}>Available balance</span>
            </div>
            <div className={`${styles['grow']}`}>
              <div className={styles['ellipsis']}>
                <div className={styles['value']}>
                  {formatNumber(balanceCurrent[mainTokenIndex])}
                  <span className={styles['adornment-end']}>
                    <img src={mainToken.icon} alt="" />
                  </span>
                </div>
              </div>
              <div className={styles['ellipsis']}>
                <div className={styles['value']}>
                  {formatNumber(balanceCurrent[baseTokenIndex])}
                  <span className={styles['adornment-end']}>
                    <img src={baseToken.icon} alt="" />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className={styles['view-paper']}>
            <div className={styles['shrink']}>
              <span className={styles['adornment']}></span>
              <span className={styles['label']}>Base Tokens Accrued</span>
            </div>
            <div className={`${styles['grow']}`}>
              <div className={styles['ellipsis']}>
                <div className={styles['value']}>
                  {formatNumber(baseTokenAccrued)}
                  <span className={styles['adornment-end']}>
                    <img src={baseToken.icon} alt="" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['wapper-field']}>
        <div className={styles['content-box']}>
          <div className={styles['view-paper']}>
            <div className={`${styles['grow']} ${styles['grow-left']}`}>
              <div className={styles['label-info']}>
                <span className={styles['text']}>
                  {feePercent}% Platform Access Fee Approximation
                </span>
                <span className={styles['adornment-end']}>
                  <Tooltip
                    title="Platform access fee approximation is calculated in real time upon withdrawal transaction using the base tokens accrued."
                    placement="top"
                  >
                    <DetailOverviewIcon />
                  </Tooltip>
                </span>
              </div>
              <div className={styles['value-info']}>
                <span className={styles['text']}>
                  {formatNumber(
                    (+balanceCurrent[baseTokenIndex] * feePercent) / 100,
                  )}
                </span>
                <span className={styles['adornment-end']}>
                  <img src={baseToken.icon} alt="" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['wapper-field']}>
        <div className={styles['title']}>
          Balances After Platform Access Fee
        </div>
        <div className={styles['content-box']}>
          <div className={styles['view-paper']}>
            <div className={styles['balance-block']}>
              <div className={styles['row-balance']}>
                <div className={styles['col-value']}>
                  <div className={styles['value']}>
                    {formatNumber(+balanceCurrent[mainTokenIndex])}
                  </div>
                  <div className={styles['adornment']}>
                    <img src={mainToken.icon} alt="" />
                  </div>
                </div>
                <div className={styles['col-value']}>
                  <div className={styles['value']}>
                    {formatNumber(
                      (+balanceCurrent[baseTokenIndex] * (100 - feePercent)) /
                        100,
                    )}
                  </div>
                  <div className={styles['adornment']}>
                    <img src={baseToken.icon} alt="" />
                  </div>
                </div>
              </div>
              <AppButton
                sizes="small"
                isDisable={isNotOwner}
                className={styles['btn-withraw']}
                onClick={withdrawToken}
              >
                Withdraw All
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default ModalSettingLBP;
