import {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  Ref,
  useRef,
} from 'react';
import { FormHelperText } from '@material-ui/core';
import { isAddress } from 'ethers/lib/utils';
import config from 'src/config';
import styles from 'src/styles/pages/LPB/steps/StepLBPOverview.module.scss';
import { AuctionType, RefStep, TokenInterface } from 'src/utils/utils-auction';
import DropdownNetwork from 'src/components/DropdownNetwork';
import AppButton from 'src/components/AppButton';
import AppInput from 'src/components/AppInput';
import { formatString, formatWeiNumber } from 'src/utils/utils-formats';
import { getTokenBalance, getTokenInfo } from 'src/utils/utils-token';
import useAuth from 'src/hooks/useAuth';
import { createValidator } from 'src/utils/utils-validator';
import AppAlertWarning from 'src/components/AppAlertWarning';
import { BeatLoader } from 'react-spinners';

interface OverviewProps {
  auction: AuctionType;
  onClickNext: () => void;
}

const initEmptyToken = {
  address: '',
  name: '',
  symbol: '',
  decimals: 0,
  totalSupply: '',
  logo: '',
};

const StepOverview = forwardRef((props: OverviewProps, ref: Ref<RefStep>) => {
  const { auction, onClickNext } = props;
  const { user } = useAuth();

  const [internalAuction, setInternalAuction] = useState(auction);
  const [isLoadingToken, setIsLoadingToken] = useState(false);
  const [userTokenBalance, setUserTokenBalance] = useState('0');
  const [networkErrorMessage, setNetworkErrorMessage] = useState('');
  const [isDisableNextStep, setIsDisableNextStep] = useState(true);
  useEffect(() => {
    setInternalAuction(auction);
  }, [auction]);

  const { network, token } = internalAuction;

  const validator = useRef(
    createValidator({
      element: (message: string) => (
        <FormHelperText className={styles['helper-text']} error>
          {message}
        </FormHelperText>
      ),
    }),
  );

  const validate = async () => {
    if (isDisableNextStep) throw new Error('Invalid validation');
  };

  useImperativeHandle(ref, () => ({
    validate,
    auction: internalAuction,
  }));

  const onChangeNetwork = (newNetwork: string) => {
    setInternalAuction((prevState) => ({
      ...prevState,
      network: newNetwork,
    }));
  };

  const onChangeToken = (newToken: TokenInterface) => {
    setInternalAuction((prevState) => ({ ...prevState, token: newToken }));
  };

  const fetchTokenInfo = (tokenParam: TokenInterface, networkParam: string) => {
    if (
      !tokenParam.address ||
      !isAddress(tokenParam.address) ||
      !networkParam
    ) {
      return;
    }
    setIsLoadingToken(true);
    getTokenInfo(tokenParam.address, networkParam)
      .then((tokenInfo) => {
        onChangeToken(
          tokenInfo
            ? {
                ...tokenParam,
                name: tokenInfo.name,
                symbol: tokenInfo.symbol,
                decimals: tokenInfo.decimals,
                totalSupply: tokenInfo.totalSupply,
              }
            : initEmptyToken,
        );
      })
      .finally(() => setIsLoadingToken(false));
  };

  const fetchTokenBalance = (
    tokenParam: TokenInterface,
    networkParam: string,
    userAddress: string | undefined,
  ) => {
    if (!tokenParam.address || !networkParam || !userAddress) {
      return;
    }
    getTokenBalance(networkParam, tokenParam.address, userAddress)
      .then((balance) => {
        setUserTokenBalance(balance);
      })
      .catch(() => setUserTokenBalance('0'));
  };

  const checkDisableNextStep = (
    tokenParam: TokenInterface,
    networkErrorMessageParam: string,
  ) => {
    const isDisabledNextButton =
      !tokenParam.name ||
      !!networkErrorMessageParam ||
      !validator.current.allValid();
    setIsDisableNextStep(isDisabledNextButton);
  };

  useEffect(() => {
    checkDisableNextStep(token, networkErrorMessage);
  }, [token, networkErrorMessage]);

  useEffect(() => {
    fetchTokenInfo(token, network);
  }, [token.address, network]);

  useEffect(() => {
    fetchTokenBalance(token, network, user?.getAddress());
  }, [token.address, network, user?.getAddress()]);

  // set error message for network
  useEffect(() => {
    setNetworkErrorMessage(
      network !== user?.getNetwork() ? 'Network does not match target' : '',
    );
  }, [network, user?.getNetwork()]);

  const isNetworkCorrect = network === user?.getNetwork();

  const _renderDetailToken = () => {
    if (isLoadingToken) {
      return <BeatLoader color="white" size={10} />;
    }

    return (
      <>
        <div className={styles['info-row']}>
          <span className={styles['label']}>Token name:</span>
          <span className={styles['value']}>{formatString(token.name)}</span>
        </div>
        <div className={styles['info-row']}>
          <span className={styles['label']}>Token symbol:</span>
          <span className={styles['value']}>{formatString(token.symbol)}</span>
        </div>
        <div className={styles['info-row']}>
          <span className={styles['label']}>Total supply:</span>
          <span className={styles['value']}>
            {formatWeiNumber(token?.totalSupply, token?.decimals)}
          </span>
        </div>
        <div className={styles['info-row']}>
          <span className={styles['label']}>Balance:</span>
          <span className={styles['value']}>
            {formatWeiNumber(userTokenBalance, token?.decimals)}
          </span>
        </div>
      </>
    );
  };

  const _renderNetworkNotCorrect = () => {
    return (
      <>
        <AppAlertWarning acceptedNetwork={network} type={'shortcut'}>
          <span>Network does not match LBP target</span>
        </AppAlertWarning>
      </>
    );
  };

  const _renderInfoToken = () => {
    return (
      <div className={styles['group-info']}>
        <div className={styles['head']}>
          <div
            className={`${styles['symbol']} ${
              styles[token.name ? 'valid' : 'invalid']
            }`}
          >
            <img src={config.networks[network].icon} alt="" />
          </div>
          <div className={styles['name']}>
            {token.name ? 'Token validated' : 'Token Invalid'}
          </div>
        </div>
        {_renderDetailToken()}
      </div>
    );
  };

  return (
    <>
      <div className={`${styles['wrap-item']} ${styles['wrap-infomation']}`}>
        <div className={`${styles['card']}`}>
          <div className={styles['card-body']}>
            <div className={styles['group-control']}>
              <div className={`${styles['field']}`}>
                <label className={styles['label']}>
                  Select LBP target network{' '}
                  <span className={styles['required']}>*</span>
                </label>
                <DropdownNetwork
                  fullWidth
                  value={network}
                  onChange={onChangeNetwork}
                  variant={'outlined'}
                  support={'auction'}
                />
                <FormHelperText className={styles['helper-text']} error>
                  {networkErrorMessage}
                </FormHelperText>
              </div>
              <div className={styles['field']}>
                <label className={styles['label']}>
                  Launch token <span className={styles['required']}>*</span>
                </label>
                <AppInput
                  value={token.address}
                  placeholder="Enter token contract address"
                  validate={{
                    name: 'address',
                    validator: validator.current,
                    rule: 'required|isAddress',
                  }}
                  handleChange={(value: string) =>
                    onChangeToken({ ...token, address: value })
                  }
                />
              </div>
              <div className={styles['field']}>
                <label className={styles['label']}>
                  Token logo URL <span className={styles['required']}>*</span>
                </label>
                <AppInput
                  value={token.logo}
                  placeholder="URL of token image"
                  validate={{
                    name: 'logo',
                    validator: validator.current,
                    rule: 'required|url|logoUrl',
                  }}
                  handleChange={(value: string) =>
                    onChangeToken({ ...token, logo: value })
                  }
                />
                <span className={styles['note']}>
                  Enter a valid URL ending in “jpeg”, “jpg” or “png”
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles['card']} ${styles['card-shrink']}`}>
          <div className={`${styles['card-body']}`}>
            {isNetworkCorrect ? _renderInfoToken() : _renderNetworkNotCorrect()}
          </div>
        </div>
      </div>
      <div className={`${styles['wrap-footer']}`}>
        <div className={styles['footer-fixed']}>
          <div className={styles['row']}>
            <div className={`${styles['group-button-right']}`}>
              <AppButton
                variant="primary"
                onClick={onClickNext}
                isDisable={isDisableNextStep}
                className={styles['btn']}
              >
                Next
              </AppButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default StepOverview;
