export enum PoolStatus {
  Upcoming = 'Upcoming',
  Whitelist = 'Whitelist',
  Swap = 'Swap',
  Claim = 'Rewards Claim',
  End = 'End',
}

export const NFTS_DATA = [
  {
    id: 1,
    imgUrl: 'https://static.nft.bunicorn.exchange/farms/logo-450x600.png',
    smallImgUrl: 'https://static.nft.bunicorn.exchange/farms/logo-180x240.png',
    lowestBuni: 0,
    highestBuni: 500,
    name: 'Bunicorn Logo',
  },
  {
    id: 2,
    imgUrl:
      'https://static.nft.bunicorn.exchange/krypto_monsters/krypto_monster_04-450x450.png',
    smallImgUrl:
      'https://static.nft.bunicorn.exchange/krypto_monsters/krypto_monster_04-180x180.png',
    lowestBuni: 500,
    highestBuni: 1000,
    name: 'AEGIRBUNI',
  },
  {
    id: 3,
    imgUrl:
      'https://static.nft.bunicorn.exchange/krypto_monsters/krypto_monster_05-450x450.png',
    smallImgUrl:
      'https://static.nft.bunicorn.exchange/krypto_monsters/krypto_monster_05-180x180.png',
    lowestBuni: 1000,
    highestBuni: 5000,
    name: 'ROCBUNI',
  },
  {
    id: 4,
    imgUrl:
      'https://static.nft.bunicorn.exchange/krypto_monsters/krypto_monster_06-450x450.png',
    smallImgUrl:
      'https://static.nft.bunicorn.exchange/krypto_monsters/krypto_monster_06-180x180.png',
    lowestBuni: 5000,
    highestBuni: 10000,
    name: 'BEEZLEBUNI',
  },
  {
    id: 5,
    imgUrl:
      'https://static.nft.bunicorn.exchange/krypto_monsters/krypto_monster_07-450x450.png',
    smallImgUrl:
      'https://static.nft.bunicorn.exchange/krypto_monsters/krypto_monster_07-180x180.png',
    lowestBuni: 10000,
    highestBuni: 'POSITIVE_INFINITY',
    name: 'FORNBUNI',
  },
];

export enum KYC_STATUS {
  INCOMPLETE = 'Unverified', // Blockpass verifications pending
  APPROVED = 'Verified', // profile has been approved by Merchant
  RESUBMIT = 'Submitted', // Merchant has rejected one or more attributes
  WAITING = 'Rejected', // Merchant's review pending
  INREVIEW = 'Reviewing', // in review by Merchant
  REJECTED = 'Unverified',
}

export enum VisibilityGridPoolsEnum {
  COLUMN = 'COLUMN',
  ROW = 'ROW',
}

export enum POOL_TYPE {
  IDO = 'IDO',
  INO = 'INO',
}

export const AMOUNT_REGEX = /^-?[0-9]{0,}[.]{0,1}[0-9]{0,6}$/;
