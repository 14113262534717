import {
  Avalanche,
  BnbChain,
  BullhornIcon,
  BuniIcon,
  CopyIcon,
  Ethereum,
  Polygon,
  RocketIcon,
  Solana,
  TelegramOutlineIcon,
} from 'src/assets/icons';
import AppButton from 'src/components/AppButton';
import config from 'src/config';
import landingStyle from 'src/styles/pages/Homepage.module.scss';
import { formatShortAddress } from 'src/utils/utils-formats';
import { copyToClipboard } from 'src/utils/utils-helpers';

const PartContactUs = () => {
  return (
    <>
      <div className={landingStyle['common-info']}>
        <div className={landingStyle['title']}>
          Accelerate Growth at Any Scale
        </div>
        <div className={landingStyle['description']}>
          Bring out your projects. Reinforce your success. Experience seamless, multi-chain solutions.
        </div>
        <div className={landingStyle['btn-group']}>
          <a
            href="https://forms.gle/3eioR7RsjxLSBjFZ6"
            target="_blank"
            rel="noreferrer"
          >
            <AppButton
              className={landingStyle['button']}
              variant="primary"
              sizes="big"
            >
              <RocketIcon />
              Apply As A Project
            </AppButton>
          </a>
          <AppButton
            className={landingStyle['button']}
            variant="secondary"
            sizes="big"
            onClick={() => window.open(config.socialLinks.telegram, '_blank')}
          >
            <TelegramOutlineIcon /> Telegram
          </AppButton>
          <AppButton
            className={landingStyle['button']}
            variant="secondary"
            sizes="big"
            onClick={() =>
              window.open(config.socialLinks.announcements, '_blank')
            }
          >
            <BullhornIcon /> Announcements
          </AppButton>
        </div>
        <div className={landingStyle['btn-group']}>
          <AppButton
            className={`${landingStyle['button']} ${landingStyle['buy-buni-btn']}`}
            variant="outline"
            sizes="small"
            onClick={() => window.open(config.buyMoreBuniUrl, '_blank')}
          >
            <BuniIcon /> BUY BUNI
          </AppButton>
          <AppButton
            className={`${landingStyle['button']} ${landingStyle['address-btn']}`}
            variant="outline"
            sizes="small"
            onClick={() => copyToClipboard(config.addresses.buni)}
          >
            {formatShortAddress(config.addresses.buni)} <CopyIcon />
          </AppButton>
        </div>
      </div>
      <div className={landingStyle['raise-capital']}>
        RAISE CAPITAL ACROSS ALL MAIN BLOCKCHAIN NETWORKS
      </div>
      <div className={landingStyle['capitals']}>
        <Ethereum />
        <BnbChain />
        <Polygon />
        <Solana />
        <Avalanche />
      </div>
    </>
  );
};

export default PartContactUs;
