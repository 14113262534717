import { TokenType } from './utils-token';
import BigNumber from 'bignumber.js';
import Decimal from 'decimal.js';
import moment from 'moment';
import abi from 'src/abi';
import config from 'src/config';
import { LBPContract, vaultContract } from './utils-contract';
import { convertWeiToDec } from './utils-formats';
import { getNetworkProvider } from './utils-network';

export interface AuctionClient {
  tokenName: string;
  status: string | number;
  id: string;
  network: string;
  verified: boolean;
  logoUrl: string;
  liquidity: number;
  price: string | number;
  startTime: number;
  endTime: number;
  baseToken: ITokenAuction;
}

export interface ISearchValue {
  status?: string | number;
  network?: string;
  searchKey?: string;
  page?: number;
  limit?: number;
  isDraft?: boolean;
}

export interface ITokenDraft {
  address: string;
  name: string;
  symbol: string;
  decimals: number;
}

export interface ITokenAuction {
  id: string;
  network: string;
  onchainId: string;
  address: string;
  symbol: string;
  name: string;
  decimals: number;
  priceRate: string;
  balance: string;
  weight: string;
  latestPriceRate: string;
}

export interface ITokenMain {
  id: string;
  network: string;
  onchainId: string;
  address: string;
  symbol: string;
  name: string;
  decimals: number;
  priceRate: string;
  balance: string;
  weight: string;
  icon: string;
}

export interface ITokenBase {
  coingeckoId: string;
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  icon: string;
}

export interface SwapListResponseType {
  id: string;
  network: string;
  onchainId: string;
  caller: {
    id: string;
    network: string;
    address: string;
  };
  tokenIn: {
    id: string;
    network: string;
    address: string;
    symbol: string;
    name: string;
    decimals: number;
    totalBalanceUSD: string;
    totalBalanceNotional: string;
    totalVolumeUSD: string;
    totalVolumeNotional: string;
    totalSwapCount: number;
  };
  tokenOut: {
    id: string;
    network: string;
    address: string;
    symbol: string;
    name: string;
    decimals: number;
    totalBalanceUSD: string;
    totalBalanceNotional: string;
    totalVolumeUSD: string;
    totalVolumeNotional: string;
    totalSwapCount: number;
  };
  tokenAmountIn: string;
  tokenAmountOut: string;
  txid: string;
  timestamp: number;
  price?: string;
}

export interface IPoolAuctionResponseType {
  id: string;
  network: string;
  onchainId: string;
  address: string;
  factory: string;
  symbol: string;
  name: string;
  swapEnabled: boolean;
  swapFee: string;
  owner: string;
  vault: {
    id: string;
    network: string;
    address: string;
    poolCount: number;
    totalSwapCount: number;
    totalLiquidity: string;
    totalSwapVolume: string;
    totalSwapFee: string;
  };
  txid: string;
  tokens: ITokenAuction[];
  tokensList: string[];
  createTime: number;
  totalLiquidity: string;
  totalSwapFee: string;
  totalSwapVolume: string;
  totalWeight: string;
  totalShares: string;
  holdersCount: number;
  swapsCount: number;
  startTime: number;
  endTime: number;
  startWeights: string[];
  endWeights: string[];
}

export interface IAuctionResponseType {
  id: string;
  network: string;
  creationTx: string;
  ownerAddress: string;
  logoUrl: string;
  description: string;
  draftInfo: {
    tokens: ITokenDraft[];
    amounts: number[];
    startTime: number;
    endTime: number;
    startWeights: number[];
    endWeights: number[];
    swapFee: number;
  };
  pool: IPoolAuctionResponseType;
  socialLinks: {
    website?: string;
    telegram?: string;
    twitter?: string;
    medium?: string;
    discord?: string;
  };
  isDraft: boolean;
  isVerified: boolean;
}

export interface TokenInterface extends TokenType {
  totalSupply: string;
  logo: string;
}

export interface MediaInterface {
  website: string;
  telegram: string;
  twitter: string;
  discord: string;
  medium: string;
}

export interface AuctionType {
  network: string;
  token: TokenInterface;
  collateralToken: TokenType;
  depositToken: {
    launch: number;
    collateral: number;
  };
  duration: {
    startDate: Date;
    endDate: Date;
  };
  weights: {
    startWeight: number;
    endWeight: number;
  };
  description: string;
  media: MediaInterface;
  countries: string[];
  swapFee: number;
  permissions: {
    pauseTrading: boolean;
    pullLiquidity: boolean;
  };
  step: number;
}

export interface RefStep {
  validate?: () => void;
  auction?: AuctionType;
}

export interface CollateralToken {
  name: string;
  icon: string;
  currency: string;
}

export interface AuctionPriceChartData {
  time: number | string;
  value: number | string;
  isHistory?: boolean;
}

export const DEFAULT_CURRENCY = 'usdc';
export const DEFAULT_SWAP_FEE = 0.15 / 100;
export const TIME_FORMAT = 'MMM D, YYYY HH:mm:ss';

const BONE = new BigNumber(1);

export const calcInGivenOut = (
  tokenBalanceIn: string,
  tokenWeightIn: string,
  tokenBalanceOut: string,
  tokenWeightOut: string,
  tokenAmountOut: string,
  swapFee: string | number,
) => {
  const weightRatio = new BigNumber(tokenWeightOut).div(tokenWeightIn);
  const diff = new BigNumber(tokenBalanceOut).minus(tokenAmountOut);
  const y = new BigNumber(tokenBalanceOut).div(diff);
  const foo = Decimal.pow(y.toString(), weightRatio.toString()).toString();
  const bar = new BigNumber(foo).minus(BONE);
  const tokenAmountIn = new BigNumber(tokenBalanceIn)
    .multipliedBy(bar)
    .dividedBy(BONE.minus(swapFee));
  return !tokenAmountIn.isNaN() ? tokenAmountIn.toString() : '0';
};

export const calcOutGivenIn = (
  tokenBalanceIn: string,
  tokenWeightIn: string,
  tokenBalanceOut: string,
  tokenWeightOut: string,
  tokenAmountIn: string,
  swapFee: string,
) => {
  const weightRatio = new BigNumber(tokenWeightIn)
    .div(tokenWeightOut)
    .toFixed(8, BigNumber.ROUND_DOWN);
  let adjustedIn = BONE.minus(swapFee);
  adjustedIn = new BigNumber(tokenAmountIn).multipliedBy(adjustedIn);
  const y = new BigNumber(tokenBalanceIn)
    .dividedBy(new BigNumber(tokenBalanceIn).plus(adjustedIn))
    .toFixed(8, BigNumber.ROUND_UP);
  const foo = Decimal.pow(y.toString(), weightRatio.toString())
    .toFixed(8, Decimal.ROUND_UP)
    .toString();
  const bar = BONE.minus(foo);
  const tokenAmountOut = new BigNumber(tokenBalanceOut)
    .multipliedBy(bar)
    .toFixed(8, BigNumber.ROUND_DOWN);
  return !new BigNumber(tokenAmountOut).isNaN()
    ? tokenAmountOut.toString()
    : '0';
};

export const calcAuctionChartData = (
  collateralBalance: number,
  tokenBalance: number,
  startTokenWeight: number,
  endTokenWeight: number,
  startDate: Date,
  endDate: Date,
  swapFee = DEFAULT_SWAP_FEE,
): AuctionPriceChartData[] => {
  const duration = moment(endDate).diff(startDate, 'hours');

  const chartData = [];
  for (let step = 0; step <= duration; step++) {
    const tokenWeightOut = new BigNumber(
      startTokenWeight -
        (step / duration) * Math.abs(startTokenWeight - endTokenWeight),
    );
    const tokenWeightIn = new BigNumber(1).minus(tokenWeightOut);

    const inAmount = calcInGivenOut(
      collateralBalance.toString(),
      tokenWeightIn.toString(),
      tokenBalance.toString(),
      tokenWeightOut.toString(),
      '1',
      swapFee,
    );
    const price = new BigNumber(inAmount).toFixed(8, BigNumber.ROUND_HALF_UP);

    chartData.push({
      time: moment(startDate).add(step, 'hours').valueOf(),
      value: price,
    });
  }

  return chartData;
};

export const withdrawAuction = (poolAddress: string, network: string) => {
  const addressContract = config.networks[network].addresses.auctionProxy;
  const paramsSend = [poolAddress, [0, 0], 0];
  return [abi['LBPProxy'], addressContract, 'exitPool', paramsSend, {}];
};

export const getCurrentBalances = async (
  poolId: string,
  network: string,
  vaultAddress: string,
) => {
  const provider = getNetworkProvider(network);
  try {
    const contract = vaultContract(vaultAddress, provider);
    const { balances } = await contract.getPoolTokens(poolId);
    return balances.map((item: string) => convertWeiToDec(item.toString()));
  } catch (e) {
    return [];
  }
};

export const getStatusEnableSwap = async (
  network: string,
  poolAddress: string,
) => {
  const provider = getNetworkProvider(network);
  try {
    const contract = LBPContract(poolAddress, provider);
    return await contract.getSwapEnabled();
  } catch (e) {
    return false;
  }
};

export const toggleEnableSwap = async (
  poolAddress: string,
  isEnabledSwap: boolean,
  network: string,
) => {
  const paramsSend = [poolAddress, !isEnabledSwap];
  const addressContract = config.networks[network].addresses.auctionProxy;
  return [abi['LBPProxy'], addressContract, 'setSwapEnabled', paramsSend, {}];
};
