/* eslint-disable @typescript-eslint/ban-types */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Collapse,
} from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState, FC } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router';
import {
  FilterIcon,
  PlusIcon,
  SearchIcon,
  VerifiedIcon,
  UnverifiedIcon,
} from 'src/assets/icons';
import AppButton from 'src/components/AppButton';
import AppDataTable from 'src/components/AppDataTable';
import AppDropdown from 'src/components/AppDropdown';
import AppInput from 'src/components/AppInput';
import { Network } from 'src/config';
import rf from 'src/requests/RequestFactory';
import { AuctionStatusEnum } from 'src/utils/common';
import {
  AuctionClient,
  ISearchValue,
  IAuctionResponseType,
  ITokenAuction,
} from 'src/utils/utils-auction';
import { getNetworkConfigs, getSolanaNetworkId } from 'src/utils/utils-network';
import styles from 'src/styles/pages/Lbp.module.scss';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import moment from 'moment';
import { checkIsTokenBase } from 'src/utils/utils-token';
import { formatNumber } from 'src/utils/utils-formats';

interface IStatusLBP {
  startTime: number;
  endTime: number;
}

const STATUS_OPTIONS = [
  {
    value: '',
    label: 'All Status',
  },
  {
    value: AuctionStatusEnum.ACTIVE,
    label: 'Active',
  },
  {
    value: AuctionStatusEnum.PENDING,
    label: 'Pending',
  },
  {
    value: AuctionStatusEnum.INACTIVE,
    label: 'Inactive',
  },
];

let networks: Network[] = getNetworkConfigs();
networks = networks.filter((item) => item.id !== getSolanaNetworkId());
const NETWORK_OPTIONS = networks.map((item: Network) => {
  return {
    value: item.id,
    label: item.name,
    icon: item.icon,
  };
});

NETWORK_OPTIONS.unshift({
  value: '',
  label: 'All networks',
  icon: '',
});

const getDurations = (startTime: number, endTime: number) => {
  return Math.round(
    moment(endTime * 1000).diff(moment(startTime * 1000), 'hours') / 24,
  );
};

export const StatusLBP: FC<IStatusLBP> = ({ startTime, endTime }) => {
  const now = moment().unix();
  const isActive = startTime <= now && now <= endTime;
  const isPending = startTime > now;
  const isInactive = endTime > now;

  const getStatus = () => {
    switch (true) {
      case isPending:
        return 'pending';
      case isActive:
        return 'active';
      case isInactive:
        return 'inactive';
      default:
        return ' ';
    }
  };
  return (
    <div className={styles['badge-status']}>
      <span className={styles[getStatus()]} />
      <span className={styles['status']}>{getStatus()}</span>
    </div>
  );
};

const PageAuctionList = () => {
  const history = useHistory();

  const { address: connectedAccount } = useSelector(
    (state: RootState) => state.authentication,
  );

  const [listNetwork, setListNetwork] = useState<
    { value: string; label: string; icon?: string }[] | []
  >([]);

  const handleChange =
    (panel: string) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const [countFilter, setCountFilter] = useState<number>(1);

  const [expanded, setExpanded] = useState<string | false>(false);

  const [search, setSearch] = useState<ISearchValue>({
    status: '',
    network: '',
    searchKey: '',
    isDraft: false,
  });

  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const handleChangeSearchValue = (e: string) => {
    setSearch({ ...search, searchKey: e });
  };

  const handleSubmitFilter = () => {
    setOpenFilter((value) => !value);
  };

  useEffect(() => {
    setCountFilter(
      Object.keys(search).filter((value) => {
        return (search as any)[value].toString().length;
      }).length - 1,
    );
  }, [search]);

  useEffect(() => {
    setListNetwork(NETWORK_OPTIONS);
  }, []);

  const _renderSelectStatus = useCallback(() => {
    return (
      <div className={styles['filter-contain']}>
        <div className={styles['filter-header']}>
          <div className={styles['label-filter']}>Status</div>
        </div>
        <AppDropdown
          value={search.status}
          variant="outlined"
          onChange={(e: any) => {
            setSearch({ ...search, status: e });
          }}
          options={STATUS_OPTIONS}
        />
      </div>
    );
  }, [search]);

  const getPrice = (auction: IAuctionResponseType) => {
    const { pool, network } = auction;
    const tokenMainInPool = pool.tokens.find(
      (item: ITokenAuction) => !checkIsTokenBase(item.address, network),
    );

    return tokenMainInPool?.latestPriceRate;
  };

  const getBaseToken = (auction: IAuctionResponseType) => {
    const { pool, network } = auction;
    return pool.tokens.find((item: ITokenAuction) =>
      checkIsTokenBase(item.address, network),
    );
  };

  const handleFillterData = (data: IAuctionResponseType[]) => {
    return data.map((auction: IAuctionResponseType) => {
      return {
        tokenName: auction?.pool?.name || '',
        id: auction.id,
        network: auction.network,
        logoUrl: auction.logoUrl,
        verified: auction.isVerified,
        liquidity: Number(auction.pool?.totalLiquidity) || 0,
        price: getPrice(auction),
        baseToken: getBaseToken(auction),
        startTime: auction?.pool?.startTime,
        endTime: auction?.pool?.endTime,
      };
    });
  };

  const handleGetDataAuctions = async (payload: any) => {
    const data = await rf
      .getRequest('AuctionsRequest')
      .getAuctionsToken(payload);
    return { ...data, docs: handleFillterData(data.docs) };
  };

  const _renderSelectNetwork = useCallback(() => {
    return (
      <div className={styles['filter-contain']}>
        <div className={styles['filter-header']}>
          <div className={styles['label-filter']}>Network</div>
        </div>
        <AppDropdown
          variant="outlined"
          value={search.network}
          onChange={(e: any) => {
            setSearch({ ...search, network: e });
          }}
          options={listNetwork}
        />
      </div>
    );
  }, [listNetwork, search]);

  const listHeader = useMemo<{ name: string; position: string }[]>(
    () => [
      { name: 'Token', position: 'left' },
      { name: 'Network', position: 'left' },
      { name: 'Duration', position: 'right' },
      { name: 'LBP Status', position: 'right' },
      { name: 'Last Price', position: 'right' },
      { name: 'Verified', position: 'right' },
    ],
    [],
  );

  const _renderAuctionMobile = (item: any) => {
    return (
      <div className={styles['token-wrap']}>
        <Accordion
          expanded={expanded === item.id}
          onChange={handleChange(item.id)}
          className={styles[`accordion-container`]}
        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={styles['accordion-sumary']}
          >
            <div className={styles['infinite-scroll']}>
              <div className={styles['infinite-scroll-content']}>
                <Link to={`/lbp/${item.id}`}>
                  <div className={styles['content-summary']}>
                    <img
                      src={item.logoUrl}
                      alt="token"
                      className={styles['content-summary-img']}
                    />
                    <span className={styles['content-summary-name']}>
                      {item.tokenName}
                    </span>
                    <span>
                      {item.verified ? <VerifiedIcon /> : <UnverifiedIcon />}
                    </span>
                  </div>
                </Link>
                <div className={styles['flex-header']}>
                  <span
                    className={`${styles['icon-collapse']} ${
                      expanded === item.id ? '' : styles['collapsed']
                    }`}
                  />
                </div>
              </div>

              <div className={styles['infinite-scroll-content']}>
                <span className={styles['content-status']}>LBP Status</span>
                <StatusLBP startTime={item.startTime} endTime={item.endTime} />
              </div>
            </div>
          </AccordionSummary>

          <AccordionDetails className={styles['lbp-accordion-detail']}>
            <div className={styles['content-detail-wrap']}>
              <div className={styles['content-detail']}>
                <span>Price</span>
                <span>
                  <b>
                    {formatNumber(item.price)} {item.baseToken.symbol}
                  </b>
                </span>
              </div>
              <div className={styles['content-detail']}>
                <span>Duration</span>
                <span>
                  <b>{`${getDurations(item.startTime, item.endTime)} days`}</b>
                </span>
              </div>
              <div className={styles['content-detail']}>
                <span>Network</span>
                <span>
                  <b>{item.network}</b>
                </span>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };

  const _renderHeaderTable = () => {
    return (
      <div className={`${styles['header-table']}`}>
        {listHeader.map((headerItem, index) => (
          <div
            key={index}
            className={
              headerItem.position === 'left'
                ? styles['position-left']
                : styles['position-right']
            }
          >
            {headerItem.name}
          </div>
        ))}
      </div>
    );
  };

  const _renderBodyTable = (dataTable: AuctionClient[]) => {
    return (
      <>
        {dataTable.map((row: AuctionClient, index: number) => {
          return (
            <Link to={`/lbp/${row.id}`} key={index}>
              <div className={styles['table-row']}>
                <div className={styles['table-cell']}>
                  <img className={styles['img-cell']} src={row.logoUrl} />
                  <span className={styles['token-name']}>{row.tokenName}</span>
                </div>

                <div className={styles['table-cell']}>
                  {listNetwork.map((network, index) => {
                    if (network?.value === row.network)
                      return (
                        <>
                          <img
                            key={index}
                            className={styles['img-cell']}
                            src={network.icon}
                          />
                          <span style={{ marginLeft: '10px' }}>
                            {network.label}
                          </span>
                        </>
                      );
                  })}
                </div>

                <div className={styles['table-cell']}>
                  {`${getDurations(row.startTime, row.endTime)} days`}
                </div>

                <div className={styles['table-cell']}>
                  <StatusLBP startTime={row.startTime} endTime={row.endTime} />
                </div>

                <div
                  className={`${styles['cell-price']} ${styles['table-cell']}`}
                >
                  <span>
                    {formatNumber(row.price)}{' '}
                    {+row.price > 0 ? row.baseToken.symbol : ''}
                  </span>
                </div>

                <div className={styles['table-cell']}>
                  {row.verified ? <VerifiedIcon /> : <UnverifiedIcon />}
                </div>
              </div>
            </Link>
          );
        })}
      </>
    );
  };

  const _renderListTokenMobile = (dataTable: AuctionClient[]) => {
    return (
      <div className={styles['infinitiScroll-wrap']}>
        <>
          <div>
            {dataTable.map((item: AuctionClient, index: number) => {
              return (
                <div key={index} className={styles['token-container']}>
                  {_renderAuctionMobile(item)}
                </div>
              );
            })}
          </div>
        </>
      </div>
    );
  };

  const _renderLbpDesktop = () => {
    return (
      <div className={styles['lbp-container']}>
        <div className={styles['lbp-desktop']}>
          {/** title  */}
          <div className={styles['lbp-title-wrap']}>
            <span className={styles['lbp-title-content']}>
              Liquidity Bootstrapping Pools
            </span>
            <Box display={'flex'}>
              <AppButton variant="quaternary" onClick={handleSubmitFilter}>
                <div className={styles['icon-size']}>
                  {countFilter ? (
                    <span className={styles['badge-filter']}>
                      {countFilter}
                    </span>
                  ) : (
                    <FilterIcon />
                  )}
                </div>
                <span className={styles['content-button']}>Filters</span>
              </AppButton>
              {connectedAccount && (
                <AppButton
                  className={styles['btn-add-lbp']}
                  onClick={() => history.push('/lbp')}
                >
                  <div
                    className={`${styles['icon-size']} ${styles['gray-plus-icon']}`}
                  >
                    <PlusIcon />
                  </div>

                  <span className={styles['content-button']}>Create LBP</span>
                </AppButton>
              )}
            </Box>
          </div>
          {/**search */}
          <Collapse in={openFilter} timeout="auto" unmountOnExit>
            <div className={styles['lbp-search-container']}>
              <div className={styles['lpb-search']}>
                <AppInput
                  className={styles['input-search']}
                  placeholder="Search by Token name"
                  handleChange={handleChangeSearchValue}
                  value={search.searchKey}
                  startAdornment={<SearchIcon />}
                />

                <div className={styles['select-search']}>
                  <>
                    <div
                      className={`${styles['type-select']} ${
                        styles[`type-select-custom`]
                      }`}
                    >
                      {_renderSelectStatus()}
                    </div>
                    <div className={`${styles['type-select']}`}>
                      {_renderSelectNetwork()}
                    </div>
                  </>
                </div>
              </div>
            </div>
          </Collapse>
          <AppDataTable
            limit={10}
            wrapperClassName={styles['token-table-container']}
            renderHeader={_renderHeaderTable}
            renderBody={_renderBodyTable}
            requestParams={search}
            fetchData={handleGetDataAuctions}
          />
        </div>
      </div>
    );
  };

  const _renderLbpMobile = () => {
    return (
      <div
        className={`${styles['lbp-container']} ${styles['lbp-container-mobile']}`}
      >
        <div className={styles['lbp-desktop']}>
          {/** title  */}
          <div
            className={`${styles['lbp-title-wrap']} ${styles['lbp-title-wrap-mobile']}`}
          >
            <span
              className={`${styles['lbp-title-content']} ${styles['lbp-title-content-mobile']}`}
            >
              Pool List
            </span>

            <Box display={'flex'}>
              <AppButton
                variant="quaternary"
                className={`${styles['btn-filter']} ${styles['btn-mobile']}`}
                onClick={handleSubmitFilter}
              >
                <div className={styles['icon-size']}>{<FilterIcon />}</div>
              </AppButton>
              {connectedAccount && (
                <AppButton
                  onClick={() => history.push('/lbp')}
                  variant="primary"
                  className={`${styles['btn-add-lbp']} ${styles['btn-mobile']}`}
                >
                  <div
                    className={`${styles['icon-size']} ${styles['gray-plus-icon']}`}
                  >
                    <PlusIcon />
                  </div>
                </AppButton>
              )}
            </Box>
          </div>
          {/**search */}
          <Collapse in={openFilter} timeout="auto" unmountOnExit>
            <div
              className={`${styles['lbp-search-container']} ${styles['lbp-search-container-mobile']}`}
            >
              <div
                className={`${styles['lpb-search']} ${styles['lpb-search-mobile']}`}
              >
                <AppInput
                  className={`${styles['input-search']} ${styles['input-search-mobile']}`}
                  placeholder="Search by Token name"
                  handleChange={handleChangeSearchValue}
                  value={search.searchKey}
                  startAdornment={<SearchIcon />}
                />

                <div
                  className={`${styles['select-search']} ${styles['select-search-mobile']}`}
                >
                  <>
                    <div
                      className={`${styles['type-select']} ${
                        styles[`type-select-custom`]
                      }`}
                    >
                      {_renderSelectStatus()}
                    </div>
                    <div className={`${styles['type-select']}`}>
                      {_renderSelectNetwork()}
                    </div>
                  </>
                </div>
              </div>
            </div>
          </Collapse>
          <AppDataTable
            limit={10}
            renderBody={_renderListTokenMobile}
            requestParams={search}
            fetchData={handleGetDataAuctions}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={styles['wrapper']}>
      <div className={styles['main-content']}>
        {isMobile ? _renderLbpMobile() : _renderLbpDesktop()}
      </div>
    </div>
  );
};

export default PageAuctionList;
