import React, { FC, useState } from 'react';
import rf from 'src/requests/RequestFactory';
import { INOPool, PackageType } from 'src/utils/pool';
import { convertWeiToDec } from 'src/utils/utils-formats';
import styles from 'src/styles/modals/ModalBuyINO.module.scss';
import { InputAdornment, InputLabel } from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import _ from 'lodash';
import { buyNFT } from 'src/utils/utils-pool';
import AppInputCurrency from 'src/components/AppInputCurrency';
import BaseModal, { BaseModalProps } from './BaseModal';
import clsx from 'clsx';

interface ModalBuyINOProps extends BaseModalProps {
  packageItem?: PackageType;
  allocation: number | string;
  tokenSymbol?: string;
  inoPool?: INOPool;
  type?: 'public' | 'private';
}

const ModalBuyINO = (props: Omit<ModalBuyINOProps, 'children'>) => {
  const {
    packageItem,
    title = 'Buying Mystery Boxes',
    allocation,
    tokenSymbol,
    inoPool,
    onClose,
    ...rest
  } = props;
  const price = convertWeiToDec(packageItem?.price || '0');
  const [quantity, setQuantity] = useState<number>(0);
  const { user } = useAuth();

  const onBuyNFT = async () => {
    if (!inoPool || !user) return;
    const res = await rf.getRequest('TransactionRequest').getBuyInoTransaction({
      poolId: inoPool.getId(),
      userAddress: user?.getAddress(),
      inAmount: quantity.toString(),
      packageId: packageItem?.packageId,
    });
    const signature = res.signature;
    const params = _.pick(res, [
      'candidate',
      'fundingWallet',
      'inToken',
      'inAmount',
      'outToken',
      'outAmount',
      'outPackageId',
      'maxAmount',
      'minAmount',
      'totalSupply',
      'deadline',
    ]);
    try {
      const data = await buyNFT(
        user,
        inoPool.getContractAddress(),
        params,
        signature,
      );
      console.log('data', data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <BaseModal
      {...rest}
      title={title}
      onClose={onClose}
      onActionRight={onBuyNFT}
      onActionLeft={onClose}
    >
      <>
        {packageItem ? (
          <>
            <ul className={styles['list-item']}>
              <ModalListItem name="Box type" value={packageItem.name} />
              <ModalListItem name="Allocation" value={allocation} />
              <ModalListItem name="Price" value={`${price} ${tokenSymbol}`} />
              <ModalListItem
                name="Total cost"
                value={Number(price) * quantity}
              />
            </ul>

            <InputLabel className={styles['label']}>Quantity</InputLabel>
            <AppInputCurrency
              endAdornment={
                <InputAdornment
                  position="end"
                  className={styles['inputAdornedEnd']}
                >
                  boxes
                </InputAdornment>
              }
              value={quantity}
              handleChange={(value) => {
                if (+value > Number(allocation)) return;
                setQuantity(+value);
              }}
            />
          </>
        ) : null}
      </>
    </BaseModal>
  );
};

interface ModalListItemProps {
  name?: string | React.ReactNode;
  value?: string | number | React.ReactNode;
  secondaryColor?: boolean;
  className?: string;
  valueClassName?: string;
}

export const ModalListItem: FC<ModalListItemProps> = ({
  name,
  value,
  secondaryColor,
  className,
  valueClassName,
}) => {
  return (
    <li className={clsx(styles['modal-list-item'], className)}>
      <div className={styles['modal-list-item__left']}>
        <span
          className={clsx(
            styles['dot'],
            secondaryColor && styles['dot__secondary'],
          )}
        />
        <p className={styles['modal-list-item__key']}>{name}</p>
      </div>
      <p className={clsx(styles['modal-list-item__value'], valueClassName)}>
        {value}
      </p>
    </li>
  );
};

export default ModalBuyINO;
