import React, { useEffect, useState } from 'react';
import rf from 'src/requests/RequestFactory';
import INODescription from './part/INODescription';
import INOOverview from './part/INOOverview';
import styles from 'src/styles/pages/INODetail/INODetail.module.scss';
import { useParams } from 'react-router';
import { PoolResponseType } from 'src/utils/pool';

const INODetail = () => {
  const poolId = useParams() as any;
  const [pool, setPool] = useState<PoolResponseType>({} as PoolResponseType);

  const fetchPoolDetails = async (id: string) => {
    try {
      const response = (await rf
        .getRequest('PoolRequest')
        .getPoolDetail(id)) as any;
      setPool(response);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPoolDetails(poolId.id);
  }, [poolId.id]);
  return (
    <div className={styles['container']}>
      <INOOverview pool={pool} />
      {pool?.project && (
        <INODescription project={pool?.project} inoDetails={pool.inoDetails} />
      )}
    </div>
  );
};

export default INODetail;
