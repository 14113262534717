import {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
  FC,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import _ from 'lodash';
import { defaultAbiCoder } from '@ethersproject/abi';
import BigNumber from 'bignumber.js';
import { AuctionType, RefStep, TIME_FORMAT } from 'src/utils/utils-auction';
import {
  convertDecToWei,
  formatNumber,
  formatShortAddress,
  formatTimestamp,
} from 'src/utils/utils-formats';
import abi from 'src/abi';
import { processTransaction } from 'src/store/transactions';
import useAuth from 'src/hooks/useAuth';
import config from 'src/config';
import rf from 'src/requests/RequestFactory';
import AppButton from 'src/components/AppButton';
import styles from 'src/styles/pages/LPB/steps/StepCreateLBP.module.scss';
import {
  getAllowance,
  areTokensApproved,
  makeApproveParams,
} from 'src/utils/utils-token';
import { copyToClipboard } from 'src/utils/utils-helpers';
import { isMobile } from 'react-device-detect';
import ModalStuck from 'src/modals/ModalStuck';
import {
  CopyIcon,
  CreatedTransactionIcon,
  NoTransactionIcon,
  CheckedCircleIcon,
} from 'src/assets/icons';
import moment from 'moment';
import { toastError } from 'src/utils/utils-notify';
import { BeatLoader } from 'react-spinners';
import Storage from 'src/utils/storage';

interface CreateLbpProps {
  auction: AuctionType;
  onClickBack: () => void;
}

interface IButtonApprove {
  token: any;
  network: string;
  fetchAllowance: () => void;
}

export const ButtonApprove: FC<IButtonApprove> = ({
  token,
  network,
  fetchAllowance,
}) => {
  const [isTokenApproved, setIsTokenApproved] = useState<boolean>(false);
  const [isTokenApproving, setIsTokenApproving] = useState<boolean>(false);

  const dispatch = useDispatch();
  const { user } = useAuth();
  const onClickApproveToken = async () => {
    if (!token?.address) {
      return;
    }
    const params = makeApproveParams(
      token?.address,
      config.networks[network].addresses.auctionProxy,
    );
    await dispatch(
      processTransaction({ provider: user?.getProvider(), params }),
    );
    checkTokenAllowance().then();
    fetchAllowance();
  };

  const checkTokenAllowance = async () => {
    if (!network || !token.address || !user) return false;
    try {
      setIsTokenApproving(true);
      const tokenAllowance = await getAllowance(
        network,
        token.address,
        user?.getAddress(),
        config.networks[network].addresses.auctionProxy,
      );
      setIsTokenApproving(false);
      return setIsTokenApproved(new BigNumber(tokenAllowance).gt(0));
    } catch (error) {
      setIsTokenApproving(false);
      return false;
    }
  };

  useEffect(() => {
    checkTokenAllowance();
  }, [token, user]);

  return (
    <>
      <AppButton
        variant="primary"
        className={styles['btn']}
        onClick={onClickApproveToken}
        isDisable={isTokenApproved}
      >
        {isTokenApproving ? (
          <div style={{ paddingTop: '5px' }}>
            <BeatLoader color="white" size={8} />
          </div>
        ) : (
          `Approve ${token.symbol.toUpperCase()}`
        )}
      </AppButton>
    </>
  );
};

const StepCreateLbp = forwardRef((props: CreateLbpProps, ref: Ref<RefStep>) => {
  const { auction, onClickBack } = props;
  const [isLbpCreated, setIsLbpCreated] = useState<boolean>(false);
  const [isLbpCreating, setIsLbpCreating] = useState<boolean>(false);
  const [contractAddress, setContractAddress] = useState('');
  const [createdAuctionId, setCreatedAuctionId] = useState('');
  const [areAllTokensApproved, setAreAllTokensApproved] =
    useState<boolean>(false);
  const [isOpenModalStuck, setIsOpenModalStuck] = useState<boolean>(false);

  const history = useHistory();

  const {
    network,
    token,
    collateralToken,
    depositToken,
    weights,
    swapFee,
    duration,
    media,
    description,
  } = auction;

  const dispatch = useDispatch();
  const { user } = useAuth();

  const validate = () => {
    return;
  };

  useImperativeHandle(ref, () => ({
    validate,
  }));

  const _renderButtonTitle = () => {
    if (isLbpCreated) {
      return 'View Auction';
    }

    if (isLbpCreating) {
      return (
        <div style={{ paddingTop: '5px' }}>
          <BeatLoader color="white" size={8} />
        </div>
      );
    }
    return `Schedule ${token.symbol.toUpperCase()} LBP`;
  };

  const createTransaction = async () => {
    const now = moment().unix();
    const endDate = Math.floor(duration.endDate.getTime() / 1000);

    if (now > endDate) {
      toastError({ message: 'The end time must be after the current time.' });
      return;
    }

    const data = [
      {
        address: token.address,
        amount: convertDecToWei(depositToken.launch.toString(), token.decimals),
        startWeight: convertDecToWei(weights.startWeight.toString()),
        endWeight: convertDecToWei(weights.endWeight.toString()),
      },
      {
        address: collateralToken.address,
        amount: convertDecToWei(
          depositToken.collateral.toString(),
          collateralToken.decimals,
        ),
        startWeight: convertDecToWei(
          new BigNumber(1).minus(weights.startWeight).toString(),
        ),
        endWeight: convertDecToWei(
          new BigNumber(1).minus(weights.endWeight).toString(),
        ),
      },
    ];
    // data.sort((a: DataCompare, b: DataCompare) => {
    //   if (a.address < b.address) {
    //     return -1;
    //   }
    //   return 1;
    // });
    const [token1, token2] = data;
    const amounts = [token1.amount, token2.amount];
    const userData = defaultAbiCoder.encode(
      ['uint256', 'uint256[]'],
      [0, amounts],
    );
    const paramsSend = [
      `${token.symbol} Liquidity Bootstrapping Pool`,
      `${token.symbol}_LBP`,
      [token1.address, token2.address],
      [token1.amount, token2.amount],
      [token1.startWeight, token2.startWeight],
      [token1.endWeight, token2.endWeight],
      true,
      convertDecToWei(swapFee.toString()),
      userData,
      Math.floor(duration.startDate.getTime() / 1000),
      Math.floor(duration.endDate.getTime() / 1000),
    ];

    const params = [
      abi['LBPProxy'],
      config.networks[network].addresses.auctionProxy,
      'createAuction',
      [paramsSend],
      {},
    ];
    const transaction: any = await dispatch(
      processTransaction({ provider: user?.getProvider(), params }),
    );
    return transaction;
  };

  const createAuction = async (transaction: any) => {
    if (!transaction?.payload?.hash) return;
    const newContractAddress = transaction?.payload?.hash;
    const socialLinks = _.pickBy(
      {
        website: media.website,
        telegram: media.telegram,
        twitter: media.twitter,
        discord: media.discord,
        medium: media.medium,
      },
      (item) => !!item,
    );
    const createAuctionParams = {
      network,
      creationTx: transaction?.payload?.hash,
      logoUrl: token.logo,
      description,
      draftInfo: {
        tokens: [
          {
            address: token.address,
            name: token.name,
            symbol: token.symbol,
            decimals: token.decimals,
          },
          {
            address: collateralToken.address,
            name: collateralToken.name,
            symbol: collateralToken.symbol,
            decimals: collateralToken.decimals,
          },
        ],
        amounts: [
          convertDecToWei(depositToken.launch.toString(), token.decimals),
          convertDecToWei(
            depositToken.collateral.toString(),
            collateralToken.decimals,
          ),
        ],
        startTime: Math.floor(duration.startDate.getTime() / 1000),
        endTime: Math.floor(duration.endDate.getTime() / 1000),
        startWeights: [
          convertDecToWei(weights.startWeight.toString()),
          convertDecToWei(
            new BigNumber(1).minus(weights.startWeight).toString(),
          ),
        ],
        endWeights: [
          convertDecToWei(weights.endWeight.toString()),
          convertDecToWei(new BigNumber(1).minus(weights.endWeight).toString()),
        ],
        swapFee: swapFee,
      },
      socialLinks,
    };
    try {
      setIsLbpCreating(true);
      const res = await rf
        .getRequest('AuctionsRequest')
        .createAuction(_.pickBy(createAuctionParams, (item) => !!item));
      setCreatedAuctionId(res.id);
      setContractAddress(newContractAddress);
      setIsLbpCreating(false);
      setIsLbpCreated(true);
    } catch (error) {
      setIsLbpCreating(false);
    }
  };

  const onClickCreateLbp = async () => {
    const transaction = await createTransaction();
    await createAuction(transaction);
    Storage.clearAuction();
  };

  const onHandleBack = async () => {
    onClickBack();
  };

  const onClickViewLbp = () => {
    history.push(`/lbp/${createdAuctionId}`);
  };

  const onHandleCreateLbp = () => {
    if (isLbpCreated) {
      onClickViewLbp();
    } else {
      onClickCreateLbp();
    }
  };

  const checkAllTokenApproved = async () => {
    const isAllTokenApproved = await areTokensApproved(
      auction.network,
      auction.token.address,
      auction.collateralToken.address,
      user?.getAddress(),
    );
    setAreAllTokensApproved(isAllTokenApproved);
  };

  useEffect(() => {
    checkAllTokenApproved();
  }, [auction, user]);

  const isDisabledBackStep = isLbpCreated || isLbpCreating;

  const _renderButtonsApprove = () => {
    return (
      <div className={styles['row']}>
        <div className={styles['group-button-left']}>
          <ButtonApprove
            token={collateralToken}
            network={network}
            fetchAllowance={checkAllTokenApproved}
          />
        </div>
        <div className={styles['group-button-right']}>
          <ButtonApprove
            token={token}
            network={network}
            fetchAllowance={checkAllTokenApproved}
          />
        </div>
      </div>
    );
  };

  const _renderButtonsCreateLBP = () => {
    return (
      <div className={styles['row']}>
        <div className={styles['group-button-left']}>
          <AppButton
            variant="secondary"
            onClick={() => setIsOpenModalStuck(true)}
            isDisable={!areAllTokensApproved}
            className={styles['btn']}
          >
            Stuck?
          </AppButton>
        </div>
        <div className={styles['group-button-right']}>
          <AppButton
            variant="primary"
            onClick={onHandleCreateLbp}
            isDisable={isLbpCreating || !areAllTokensApproved}
            className={styles['btn']}
          >
            {_renderButtonTitle()}
          </AppButton>
        </div>
      </div>
    );
  };

  const _renderAuctionInfo = () => {
    return (
      <div className={styles['wrap-item']}>
        <div className={styles['card']}>
          <div className={styles['card-body']}>
            {isMobile && (
              <div
                className={`${styles['status-approve']} ${
                  areAllTokensApproved ? styles['done'] : styles['process']
                }`}
              >
                Approve interactions with main and base tokens{' '}
                {areAllTokensApproved && <CheckedCircleIcon />}
              </div>
            )}
            <div className={`${styles['title-body']}`}>Schedule LBP</div>
          </div>

          <div className={styles['section-content']}>
            <div className={styles['grid']}>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
              >
                <div className={styles['field']}>
                  <span className={styles['value']}>LBP network address: </span>
                </div>
              </div>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-grow']}`}
              >
                <div
                  className={`${styles['field']} ${styles['field-right']} ${styles['field-lg-left']}`}
                >
                  {contractAddress ? (
                    <span className={styles['label']}>
                      {formatShortAddress(contractAddress)}
                      <span
                        className={styles['icon']}
                        onClick={() => copyToClipboard(contractAddress)}
                      >
                        &nbsp;
                        <CopyIcon />
                      </span>
                    </span>
                  ) : (
                    <span className={styles['label']}>Not yet created</span>
                  )}
                </div>
              </div>
            </div>
            <div className={styles['grid']}>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
              >
                <div className={styles['field']}>
                  <span className={styles['value']}>Main token amount: </span>
                </div>
              </div>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-grow']}`}
              >
                <div
                  className={`${styles['field']} ${styles['field-right']} ${styles['field-lg-left']}`}
                >
                  <span className={styles['label']}>
                    {formatNumber(depositToken.launch)}{' '}
                    {token.name.toUpperCase()}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles['grid']}>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
              >
                <div className={styles['field']}>
                  <span className={styles['value']}>Base token amount: </span>
                </div>
              </div>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-grow']}`}
              >
                <div
                  className={`${styles['field']} ${styles['field-right']} ${styles['field-lg-left']}`}
                >
                  <span className={styles['label']}>
                    {formatNumber(depositToken.collateral)}{' '}
                    {collateralToken.symbol.toUpperCase()}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles['grid']}>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
              >
                <div className={styles['field']}>
                  <span className={styles['value']}>Swap fee:</span>
                </div>
              </div>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-grow']}`}
              >
                <div
                  className={`${styles['field']} ${styles['field-right']} ${styles['field-lg-left']}`}
                >
                  <span className={styles['label']}>{+swapFee * 100}%</span>
                </div>
              </div>
            </div>
            <div className={styles['grid']}>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
              >
                <div className={styles['field']}>
                  <span className={styles['value']}>Platform access fee: </span>
                </div>
              </div>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-grow']}`}
              >
                <div
                  className={`${styles['field']} ${styles['field-right']} ${styles['field-lg-left']}`}
                >
                  <span className={styles['label']}>2%</span>
                </div>
              </div>
            </div>
            <div className={styles['grid']}>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
              >
                <div className={styles['field']}>
                  <span className={styles['value']}>Start time: </span>
                </div>
              </div>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-grow']}`}
              >
                <div
                  className={`${styles['field']} ${styles['field-right']} ${styles['field-lg-left']}`}
                >
                  <span className={styles['label']}>
                    {formatTimestamp(duration.startDate.getTime(), TIME_FORMAT)}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles['grid']}>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
              >
                <div className={styles['field']}>
                  <span className={styles['value']}>End time: </span>
                </div>
              </div>
              <div
                className={`${styles['grid-item']} ${styles['grid-item-grow']}`}
              >
                <div
                  className={`${styles['field']} ${styles['field-right']} ${styles['field-lg-left']}`}
                >
                  <span className={styles['label']}>
                    {formatTimestamp(duration.endDate.getTime(), TIME_FORMAT)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {!isMobile && (
            <div className={styles['group-btn']}>{_renderButtonsApprove()}</div>
          )}
        </div>
        <div className={styles['card']}>
          <div className={styles['card-body']}>
            <div className={styles['foot']}>
              {contractAddress ? (
                <CreatedTransactionIcon />
              ) : (
                <NoTransactionIcon />
              )}
              <span className={styles['caption-img']}>
                {contractAddress
                  ? `${collateralToken.symbol} - ${token.symbol} LBP Created`
                  : 'No transactions in progress'}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {!isMobile && (
        <div className={styles['card-body']}>
          <div
            className={`${styles['status-approve']} ${
              areAllTokensApproved ? styles['done'] : styles['process']
            }`}
          >
            Approve interactions with main and base tokens{' '}
            {areAllTokensApproved && <CheckedCircleIcon />}
          </div>
        </div>
      )}
      {_renderAuctionInfo()}
      {!areAllTokensApproved && !isMobile && (
        <div className={styles['message-warning']}>
          You must approve interactions with main and base tokens to complete
          the last step of LBP creation.
        </div>
      )}

      <div className={`${styles['wrap-footer']}`}>
        <div className={styles['footer-fixed']}>
          <div className={styles['row']}>
            {!isMobile && (
              <div className={styles['group-button-left']}>
                <AppButton
                  variant="outline"
                  onClick={onHandleBack}
                  isDisable={isDisabledBackStep}
                  className={styles['btn']}
                >
                  Back
                </AppButton>
              </div>
            )}

            <div className={styles['group-button-right']}>
              <div className={styles['group-footer-full']}>
                {!areAllTokensApproved && isMobile
                  ? _renderButtonsApprove()
                  : _renderButtonsCreateLBP()}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalStuck
        open={isOpenModalStuck}
        onClose={() => setIsOpenModalStuck(false)}
      />
    </>
  );
});

export default StepCreateLbp;
