import styles from 'src/styles/components/AppInputCurrency.module.scss';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';
import { FC, ReactNode } from 'react';

export interface AppInputCurrencyProps extends CurrencyInputProps {
  sizes?: 'small' | 'medium';
  error?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  handleChange?: (value: string, name?: string) => void;
}

const AppInputCurrency: FC<AppInputCurrencyProps> = ({
  sizes = 'medium',
  error,
  disabled,
  readOnly,
  startAdornment,
  endAdornment,
  handleChange,
  ...props
}) => {
  return (
    <div
      className={`${styles['input-currency']} ${
        styles['input-currency-' + sizes]
      } ${disabled ? styles['input-currency-disabled'] : ''} ${
        error ? styles['input-currency-error'] : ''
      } ${readOnly ? styles['input-currency-read-only'] : ''}`}
    >
      {startAdornment && <>{startAdornment}</>}
      <div className={styles['core-input']}>
        <div className={styles['base-input']}>
          <CurrencyInput
            {...props}
            className={styles['input']}
            disabled={disabled}
            readOnly={readOnly}
            decimalSeparator={'.'}
            groupSeparator={','}
            onValueChange={(value, name) =>
              handleChange && handleChange(value ? value : '', name)
            }
          />
        </div>
      </div>
      {endAdornment && <>{endAdornment}</>}
    </div>
  );
};

export default AppInputCurrency;
