export interface TimelineInterface {
  whitelistPhase: WhitelistPhaseType;
  swapPhase: SwapPhaseType;
  claimPhase?: ClaimPhaseType;

  setWhitelistPhase(whitelistPhase: WhitelistPhaseType): void;
  getWhitelistPhase(): WhitelistPhaseType;
  beforeWhitelistPhase(): boolean;
  isWhitelistPhase(): boolean;
  afterWhitelistPhase(): boolean;
  setSwapPhase(swapPhase: SwapPhaseType): void;
  getSwapPhase(): SwapPhaseType;
  getPrivateSwapPhase(): PhaseType;
  getPublicSwapPhase(): PhaseType | null;
  getSwapTime(): PhaseType;
  beforeSwapPhase(): boolean;
  isSwapPhase(): boolean;
  isPrivateSwapPhase(): boolean;
  isPublicSwapPhase(): boolean;
  afterSwapPhase(): boolean;
  setClaimPhase(claimPhase: ClaimPhaseType): void;
  getClaimPhase(): ClaimPhaseType;
  beforeClaimPhase(): boolean;
  isClaimPhase(): boolean;
  shouldHighlightUpcomingPhase(): boolean;
  shouldHighlightWhitelistPhase(): boolean;
  shouldHighlightSwapPhase(): boolean;
  shouldHighlightClaimPhase(): boolean;
  hasVestingSchedule(): boolean;
}

export const IDO_PHASE_UPCOMING = 'upcoming';
export const IDO_PHASE_WHITELIST = 'whitelist';
export const IDO_PHASE_SWAP = 'swap';
export const IDO_PHASE_CLAIM = 'claim';

export const IDO_PHASE_LIST = [
  IDO_PHASE_UPCOMING,
  IDO_PHASE_WHITELIST,
  IDO_PHASE_SWAP,
  IDO_PHASE_CLAIM,
];

export type PhaseType = {
  startTime: number;
  endTime: number;
};

export type WhitelistPhaseType = {
  startTime: number;
  endTime: number;
};

export type SwapPhaseType = {
  privateSwap: PhaseType;
  publicSwap: PhaseType | null;
};

export type ScheduleType = {
  startTime: number;
  maxClaimablePercentage: number;
};

export type ClaimPhaseType = {
  startTime: number;
  endTime: number;
  schedules: ScheduleType[] | [];
};

export class IdoTimeline implements TimelineInterface {
  public whitelistPhase: WhitelistPhaseType = {
    startTime: 0,
    endTime: 0,
  };

  public swapPhase: SwapPhaseType = {
    privateSwap: {
      startTime: 0,
      endTime: 0,
    },
    publicSwap: {
      startTime: 0,
      endTime: 0,
    },
  };

  public claimPhase: ClaimPhaseType = {
    startTime: 0,
    endTime: 0,
    schedules: [],
  };
  constructor(poolDetails: any) {
    if (!poolDetails) {
      return;
    }
    const { whitelistSetting, saleSetting, freeBuySetting, idoDetails } =
      poolDetails;

    whitelistSetting &&
      this.setWhitelistPhase({
        startTime: whitelistSetting?.startTime * 1000,
        endTime: whitelistSetting?.endTime * 1000,
      });
    this.setSwapPhase({
      privateSwap: {
        startTime: saleSetting ? saleSetting?.startTime * 1000 : 0,
        endTime: saleSetting ? saleSetting?.endTime * 1000 : 0,
      },
      publicSwap:
        !freeBuySetting || !saleSetting
          ? null
          : {
              startTime: saleSetting ? saleSetting?.endTime * 1000 : 0,
              endTime:
                freeBuySetting?.endTime * 1000 || saleSetting?.endTime * 1000,
            },
    });

    const hasClaimPhase =
      idoDetails &&
      idoDetails.claimSchedules &&
      idoDetails.claimSchedules.length;
    hasClaimPhase &&
      this.setClaimPhase({
        startTime: idoDetails.claimSchedules[0].startTime * 1000,
        endTime:
          idoDetails.claimSchedules[idoDetails.claimSchedules.length - 1]
            .startTime * 1000,
        schedules: idoDetails.claimSchedules,
      });
  }
  shouldHighlightUpcomingPhase(): boolean {
    return this.beforeWhitelistPhase();
  }
  setWhitelistPhase(whitelistPhase: WhitelistPhaseType): void {
    this.whitelistPhase = whitelistPhase;
  }
  getWhitelistPhase(): WhitelistPhaseType {
    return this.whitelistPhase;
  }
  beforeWhitelistPhase(): boolean {
    return (
      !this.whitelistPhase.startTime ||
      new Date().getTime() < this.whitelistPhase.startTime
    );
  }
  isWhitelistPhase(): boolean {
    const currentTime = new Date().getTime();
    return (
      !!this.whitelistPhase.startTime &&
      !!this.whitelistPhase.endTime &&
      currentTime >= this.whitelistPhase.startTime &&
      currentTime <= this.whitelistPhase.endTime
    );
  }
  afterWhitelistPhase(): boolean {
    return (
      !!this.whitelistPhase.endTime &&
      new Date().getTime() > this.whitelistPhase.endTime
    );
  }
  shouldHighlightWhitelistPhase(): boolean {
    return this.isWhitelistPhase();
  }

  setSwapPhase(swapPhase: SwapPhaseType) {
    this.swapPhase = swapPhase;
  }
  getSwapPhase(): SwapPhaseType {
    return this.swapPhase;
  }
  getPrivateSwapPhase(): PhaseType {
    return this.swapPhase.privateSwap;
  }
  getPublicSwapPhase(): PhaseType | null {
    return this.swapPhase.publicSwap;
  }
  getSwapTime(): PhaseType {
    const privateSwapPhase = this.getPrivateSwapPhase();
    const publicSwapPhase = this.getPublicSwapPhase();
    return {
      startTime: privateSwapPhase.startTime,
      endTime: publicSwapPhase
        ? publicSwapPhase.endTime
        : privateSwapPhase.endTime,
    };
  }
  beforeSwapPhase(): boolean {
    const { startTime } = this.getSwapTime();
    return !startTime || new Date().getTime() < startTime;
  }
  isSwapPhase(): boolean {
    const { startTime, endTime } = this.getSwapTime();
    const currentTime = new Date().getTime();
    return (
      !!startTime &&
      !!endTime &&
      currentTime >= startTime &&
      currentTime <= endTime
    );
  }
  isPrivateSwapPhase(): boolean {
    const { startTime, endTime } = this.getPrivateSwapPhase();
    const currentTime = new Date().getTime();
    return currentTime >= startTime && currentTime <= endTime;
  }
  isPublicSwapPhase(): boolean {
    const publicSwapPhase = this.getPublicSwapPhase();
    if (!publicSwapPhase) {
      return false;
    }
    const currentTime = new Date().getTime();
    return (
      currentTime >= publicSwapPhase.startTime &&
      currentTime <= publicSwapPhase.endTime
    );
  }
  afterSwapPhase(): boolean {
    const { endTime } = this.getSwapTime();
    return !!endTime && new Date().getTime() > endTime;
  }
  shouldHighlightSwapPhase(): boolean {
    // Token Sale timeline = (after whitelist + before token sale) + token sale
    return (
      (this.afterWhitelistPhase() && this.beforeSwapPhase()) ||
      this.isSwapPhase()
    );
  }
  setClaimPhase(claimPhase: ClaimPhaseType) {
    this.claimPhase = claimPhase;
  }
  getClaimPhase(): ClaimPhaseType {
    return this.claimPhase;
  }
  beforeClaimPhase(): boolean {
    return (
      !this.claimPhase.startTime ||
      new Date().getTime() < this.claimPhase.startTime
    );
  }
  isClaimPhase(): boolean {
    return (
      !!this.claimPhase.startTime &&
      new Date().getTime() >= this.claimPhase.startTime
    );
  }
  shouldHighlightClaimPhase(): boolean {
    return (
      (this.afterSwapPhase() && this.beforeClaimPhase()) || this.isClaimPhase()
    );
  }
  hasVestingSchedule(): boolean {
    const { startTime, endTime } = this.getClaimPhase();
    const currentTime = new Date().getTime();
    return currentTime >= startTime && currentTime <= endTime;
  }
}

export class INOTimeline extends IdoTimeline {
  constructor(poolDetails: any) {
    super(poolDetails);
    if (!poolDetails) {
      return;
    }
    const { whitelistSetting, saleSetting, freeBuySetting, inoDetails } =
      poolDetails;

    this.setWhitelistPhase({
      startTime: whitelistSetting?.startTime * 1000,
      endTime: whitelistSetting?.endTime * 1000,
    });
    this.setSwapPhase({
      privateSwap: {
        startTime: saleSetting?.startTime * 1000,
        endTime: saleSetting?.endTime * 1000,
      },
      publicSwap: !freeBuySetting
        ? null
        : {
            startTime: saleSetting?.endTime * 1000,
            endTime:
              freeBuySetting?.endTime * 1000 || saleSetting?.endTime * 1000,
          },
    });

    const hasClaimPhase =
      inoDetails &&
      inoDetails.claimSchedules &&
      inoDetails.claimSchedules.length;
    hasClaimPhase &&
      this.setClaimPhase({
        startTime: inoDetails.claimSchedules[0].startTime * 1000,
        endTime:
          inoDetails.claimSchedules[inoDetails.claimSchedules.length - 1]
            .startTime * 1000,
        schedules: inoDetails.claimSchedules,
      });
  }
}
