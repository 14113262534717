import { SettingSlippageIcon, SwapIcon } from 'src/assets/icons';
import styles from 'src/styles/pages/LBPDetails/LBPSwap.module.scss';
import AppButton from 'src/components/AppButton';
import { useEffect, useMemo, useState, FC } from 'react';
import ModalSettingSlippage from 'src/modals/ModalSettingSlippage';
import { toggleConnectWalletModal } from 'src/store/authentication';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from 'src/hooks/useAuth';
import {
  getTokenBalance,
  getAllowance,
  makeApproveParams,
} from 'src/utils/utils-token';
import {
  formatWeiNumber,
  convertWeiToDec,
  convertDecToWei,
  formatNumber,
} from 'src/utils/utils-formats';
import config from 'src/config';
import abi from 'src/abi';
import { processTransaction } from 'src/store/transactions';
import { RootState } from 'src/store';
import { defaultAbiCoder } from '@ethersproject/abi';
import BigNumber from 'bignumber.js';
import {
  IAuctionResponseType,
  ITokenMain,
  ITokenBase,
  calcOutGivenIn,
} from 'src/utils/utils-auction';
import AppInputTokenMax from 'src/components/AppInputTokenMax';
import AppInputCurrency from 'src/components/AppInputCurrency';
import { roundNumber } from 'src/utils/utils-helpers';
import { MaxUint256 } from '@ethersproject/constants';
import rf from 'src/requests/RequestFactory';
import moment from 'moment';
import { switchNetwork } from 'src/utils/utils-auth';
import { Auction } from 'src/utils/auction';

interface IPartLBPSwap {
  auction: IAuctionResponseType;
  mainToken: ITokenMain;
  baseToken: ITokenBase;
  mainTokenIndex: number;
  baseTokenIndex: number;
  balanceCurrent: string[];
  tokenWeights: string[];
  swapFee: string;
  priceTokenMain: string;
  isEnabledSwap: boolean;
  fetchData: () => void;
}
const SWAP_KIND_GIVEN_OUT = '0';

const SLIPPAGE = 1;

const PartLBPSwap: FC<IPartLBPSwap> = ({
  auction,
  mainToken,
  baseToken,
  baseTokenIndex,
  mainTokenIndex,
  balanceCurrent,
  swapFee,
  tokenWeights,
  priceTokenMain,
  isEnabledSwap,
  fetchData,
}) => {
  const [amountTokenIn, setAmountTokenIn] = useState('');
  const [amountTokenOut, setAmountTokenOut] = useState('');
  const [tokenIn, setTokenIn] = useState<any>(baseToken);
  const [tokenOut, setTokenOut] = useState<any>(mainToken);
  const [balanceCurrentTokenIn, setBalanceCurrentTokenIn] =
    useState<string>('0');
  const [balanceCurrentTokenOut, setBalanceCurrentTokenOut] =
    useState<string>('0');
  const [isOpenModalSetting, setIsOpenModalSetting] = useState<boolean>(false);
  const [slippage, setSlippage] = useState<number>(SLIPPAGE);
  const [isExtractMain, setIsExtractMain] = useState<boolean>(true);
  const [isTokenAllowance, setIsTokenAllowance] = useState<boolean>(false);
  const [priceTokenBase, setPriceTokenBase] = useState<number>(0);

  const dispatch = useDispatch();
  const { user } = useAuth();
  const { address, network: currentNetwork } = useSelector(
    (state: RootState) => state.authentication,
  );

  const { pool: dataPool, network: poolNetwork } = auction;
  const vaultAddress = config.networks[poolNetwork].addresses.auctionVault;
  const lbpAuction = useMemo(() => new Auction(auction), [auction]);

  const startTime = lbpAuction.getStartTime();
  const endTime = lbpAuction.getEndTime();

  useEffect(() => {
    setTokenIn(isExtractMain ? baseToken : mainToken);
    setTokenOut(isExtractMain ? mainToken : baseToken);
  }, [isExtractMain]);

  const toggleSwapToken = () => {
    setIsExtractMain(!isExtractMain);
  };

  const getBalance = (tokenAddress: string | undefined) => {
    if (!tokenAddress || !user) {
      return 0;
    }
    return getTokenBalance(poolNetwork, tokenAddress, user?.getAddress());
  };

  const fetchAllowanceInfo = async () => {
    if (!poolNetwork || !user?.getAddress() || !tokenIn.address) {
      return;
    }

    const areTokenAllowance = await getAllowance(
      poolNetwork,
      tokenIn.address,
      user?.getAddress(),
      vaultAddress,
    );

    setIsTokenAllowance(new BigNumber(areTokenAllowance).gt(0));
  };

  useEffect(() => {
    fetchAllowanceInfo().then();
  }, [dataPool, user, tokenIn]);

  const getBalanceTokenOut = async () => {
    const balanceTokenOut = await getBalance(tokenOut.address);
    setBalanceCurrentTokenOut(balanceTokenOut);
  };

  const getBalanceTokenIn = async () => {
    const balanceTokenIn = await getBalance(tokenIn.address);
    setBalanceCurrentTokenIn(balanceTokenIn);
  };

  const getPriceTokenBase = async () => {
    const params = {
      vs_currency: 'usd',
      ids: baseToken.coingeckoId,
    };
    const price = await rf.getRequest('CoingeckoRequest').getTokenPrice(params);

    price && price.length && setPriceTokenBase(price[0].current_price);
  };

  useEffect(() => {
    getPriceTokenBase();
  }, []);

  useEffect(() => {
    getBalanceTokenOut();
    getBalanceTokenIn();
  }, [tokenOut, tokenIn, user]);

  useEffect(() => {
    if (+amountTokenIn > 0) {
      let amountOut = '';

      if (isExtractMain) {
        amountOut = calcOutGivenIn(
          balanceCurrent[baseTokenIndex],
          tokenWeights[baseTokenIndex].toString(),
          balanceCurrent[mainTokenIndex],
          tokenWeights[mainTokenIndex].toString(),
          amountTokenIn,
          swapFee,
        );
      } else {
        amountOut = calcOutGivenIn(
          balanceCurrent[mainTokenIndex],
          tokenWeights[mainTokenIndex].toString(),
          balanceCurrent[baseTokenIndex],
          tokenWeights[baseTokenIndex].toString(),
          amountTokenIn,
          swapFee,
        );
      }

      setAmountTokenOut(roundNumber(amountOut) || '0');
    } else {
      setAmountTokenOut('');
    }
  }, [amountTokenIn, tokenWeights, balanceCurrent, swapFee, isExtractMain]);

  const isMaxValue = useMemo(
    () => balanceCurrent[isExtractMain ? baseTokenIndex : mainTokenIndex],
    [balanceCurrent, isExtractMain],
  );

  const handleChangeAmountTokenIn = async (value: string) => {
    setAmountTokenIn(value);
  };

  const _renderButtonConnect = () => {
    const handleClickConnectWallet = () => {
      dispatch(toggleConnectWalletModal(true));
    };

    return (
      <AppButton onClick={handleClickConnectWallet} sizes="big">
        Connect Wallet
      </AppButton>
    );
  };

  const _renderButtonAfterConnect = () => {
    if (wrongChain) {
      const onSwitchToAcceptedNetwork = () =>
        user && switchNetwork(poolNetwork, user?.getProvider());
      return (
        <AppButton onClick={onSwitchToAcceptedNetwork} sizes="big">
          Change Network
        </AppButton>
      );
    }

    return (
      <>
        {_renderButtonApprove()}
        {_renderButtonGetToken()}
      </>
    );
  };

  const messageValidation = useMemo(() => {
    if (
      +amountTokenIn > +convertWeiToDec(balanceCurrentTokenIn, tokenIn.decimals)
    ) {
      return 'The number you entered exceeds the limit.';
    }

    if (+amountTokenIn > +isMaxValue * 0.3) {
      return 'The number you entered exceeds the 30% balance in the pool.';
    }

    return '';
  }, [amountTokenIn, isMaxValue, balanceCurrentTokenIn]);

  const getMaxValue = () => {
    if (isExtractMain) {
      return Number(balanceCurrent[baseTokenIndex]) >
        Number(formatWeiNumber(balanceCurrentTokenIn, tokenIn.decimals))
        ? Number(formatWeiNumber(balanceCurrentTokenIn, tokenIn.decimals)) * 0.3
        : Number(balanceCurrent[baseTokenIndex]) * 0.3;
    }
    return Number(balanceCurrent[mainTokenIndex]) >
      Number(formatWeiNumber(balanceCurrentTokenIn, tokenIn.decimals))
      ? Number(formatWeiNumber(balanceCurrentTokenIn, tokenIn.decimals)) * 0.3
      : Number(balanceCurrent[mainTokenIndex]) * 0.3;
  };

  const _renderButtonApprove = () => {
    const handleApprove = async () => {
      const addressApprove = tokenIn.address;
      const params = makeApproveParams(addressApprove, vaultAddress);
      await dispatch(
        processTransaction({ provider: user?.getProvider(), params }),
      );

      fetchAllowanceInfo();
    };

    return (
      <AppButton
        onClick={handleApprove}
        sizes="big"
        isDisable={isTokenAllowance || !isEnabledSwap || !isDuring}
        className={
          styles[!isEnabledSwap || !isDuring ? 'btn-approve-disable' : '']
        }
      >
        {isTokenAllowance
          ? ` You're approved to swap`
          : `Approve ${tokenIn?.symbol} usage`}
      </AppButton>
    );
  };

  const wrongChain = useMemo(() => {
    return currentNetwork !== poolNetwork;
  }, [currentNetwork]);

  const now = moment().unix();
  const isDuring = now >= startTime && now <= endTime;

  const _renderButtonGetToken = () => {
    const isNoSwapAvailable =
      !+amountTokenIn ||
      +amountTokenIn >
        +convertWeiToDec(balanceCurrentTokenIn, tokenIn.decimals) ||
      +amountTokenIn > +isMaxValue * 0.3;

    const isDisableButtonGetToken =
      isNoSwapAvailable || !isEnabledSwap || wrongChain || !isDuring;

    const limitAmountOut = new BigNumber(amountTokenOut)
      .multipliedBy(1 - Number(slippage / 100))
      .toFixed(8, BigNumber.ROUND_DOWN);

    //singleSwap.kind == SwapKind.GIVEN_IN ? amountOut >= limit : amountIn <= limit, Errors.SWAP_LIMIT

    const handleGetToken = async () => {
      const userData = defaultAbiCoder.encode(
        ['uint256'],
        [convertDecToWei(amountTokenIn.toString(), tokenIn.decimals)],
      );

      const singleSwap = [
        dataPool?.onchainId,
        SWAP_KIND_GIVEN_OUT,
        tokenIn.address,
        tokenOut.address,
        convertDecToWei(amountTokenIn.toString(), tokenIn.decimals),
        userData,
      ];
      const fundMan = [address, false, address, false];
      const paramsSend = [
        singleSwap,
        fundMan,
        convertDecToWei(limitAmountOut, tokenOut.decimals),
        MaxUint256,
      ];
      const params = [abi['Vault'], vaultAddress, 'swap', paramsSend, {}];

      await dispatch(
        processTransaction({ provider: user?.getProvider(), params }),
      );
      setAmountTokenIn('');
      setAmountTokenOut('');
      fetchData();
    };

    return (
      <AppButton
        onClick={handleGetToken}
        sizes="big"
        isDisable={isDisableButtonGetToken}
      >
        {isNoSwapAvailable ? 'No swaps available' : `Get ${tokenOut?.symbol}`}
      </AppButton>
    );
  };

  return (
    <div className={styles['form-swap']}>
      <div className={styles['form-header']}>
        <div className={styles['title']}>Get {tokenOut?.symbol}</div>
        <SettingSlippageIcon
          onClick={() => setIsOpenModalSetting(true)}
          style={{ cursor: 'pointer' }}
        />
      </div>
      <div className={styles['form-body']}>
        <div
          className={`${styles['input-group']} ${
            styles[!isDuring || !isEnabledSwap ? 'input-disabled' : '']
          }`}
        >
          <div className={styles['header-input']}>
            <div className={styles['label']}>
              {isExtractMain ? 'Base' : 'Main'} Token
            </div>
            {user && (
              <div className={styles['balance']}>
                Balance:{' '}
                {formatWeiNumber(balanceCurrentTokenIn, tokenIn.decimals)}
              </div>
            )}
          </div>

          <AppInputTokenMax
            handleChange={(value) => handleChangeAmountTokenIn(value)}
            value={amountTokenIn}
            icon={<img src={tokenIn.icon} alt="" />}
            tokenDetails={tokenIn}
            maxValue={roundNumber(getMaxValue())}
            error={Boolean(messageValidation)}
            readOnly={!isDuring || !isEnabledSwap}
          />
          <div className={styles['message-error']}>{messageValidation}</div>
          {amountTokenIn && (
            <div className={styles['balance-value']}>
              ~${' '}
              {formatNumber(
                (isExtractMain
                  ? priceTokenBase
                  : +priceTokenMain * priceTokenBase) * +amountTokenIn,
              )}
            </div>
          )}
        </div>
        <div
          className={`${styles['swap-icon']} ${
            styles[isExtractMain ? 'rotated' : '']
          }`}
        >
          <SwapIcon onClick={() => toggleSwapToken()} />
        </div>
        <div
          className={`${styles['input-group']} ${
            styles[!isDuring || !isEnabledSwap ? 'input-disabled' : '']
          }`}
        >
          <div className={styles['header-input']}>
            <div className={styles['label']}>
              {isExtractMain ? 'Main' : 'Base'} Token
            </div>
            <div className={styles['balance']}>
              Balance:{' '}
              {formatWeiNumber(balanceCurrentTokenOut, tokenOut.decimals)}
            </div>
          </div>
          <div className={styles['input']}>
            <AppInputCurrency
              value={amountTokenOut}
              readOnly
              endAdornment={
                <div className={styles['adornment-end']}>
                  <img
                    src={tokenOut.icon}
                    alt=""
                    className={styles['logo-brand']}
                  />
                  <span className={styles['symbol']}>{tokenOut?.symbol}</span>
                </div>
              }
            />
          </div>
          <div className={styles['balance-value']}>
            {/*~${' '}*/}
            {/*{formatNumber(*/}
            {/*  (isExtractMain*/}
            {/*    ? +priceTokenMain * priceTokenBase*/}
            {/*    : priceTokenBase) * +amountTokenOut,*/}
            {/*)}*/}
          </div>
        </div>

        <div className={styles['button']}>
          {!user ? _renderButtonConnect() : <>{_renderButtonAfterConnect()}</>}
        </div>
      </div>

      <ModalSettingSlippage
        open={isOpenModalSetting}
        onClose={() => setIsOpenModalSetting(false)}
        slippage={slippage}
        onSetSlippage={setSlippage}
      />
    </div>
  );
};

export default PartLBPSwap;
