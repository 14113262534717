import { ErrorSignIcon } from 'src/assets/icons';
import styles from 'src/styles/modals/ModalWarningLBP.module.scss';
import { FC } from 'react';
import { useHistory } from 'react-router';
import BaseModal from './BaseModal';

interface ModalWarningLBPProps {
  open: boolean;
  onClose: () => void;
}

const ModalWarningLBP: FC<ModalWarningLBPProps> = ({ open, onClose }) => {
  const history = useHistory();

  return (
    <BaseModal
      open={open}
      title="This LBP is not verified by Metaverse Starter"
      onActionRight={onClose}
      textActionLeft="Back to all LBPs"
      textActionRight="Proceed to LBP page"
      onActionLeft={() => history.push('/lbp-list')}
      isCloseIcon={false}
    >
      <div className={styles['modal-warning-lbp']}>
        <div className={styles['main']}>
          <ErrorSignIcon />
          <div className={styles['description']}>
            Do you want to proceed to the LBP page in spite of its increased
            risk profile?
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

export default ModalWarningLBP;
