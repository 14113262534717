import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import rf from 'src/requests/RequestFactory';
import config from 'src/config';
import { ITierInfo } from 'src/utils/common';
import _ from 'lodash';

interface MetadataState {
  tiers: ITierInfo[];
  lpRate: number;
  tokensUsdPrice: any;
  metadataStakingPools: any;
}

const initialState: MetadataState = {
  tiers: [],
  lpRate: 12500,
  tokensUsdPrice: {},
  metadataStakingPools: [],
};

export const getTiers = createAsyncThunk('metadata/getTiers', async () => {
  const res = await rf.getRequest('MetaDataRequest').getStakingTiers();
  if (res.docs && res.docs.length) return res.docs;
});

export const getMetadataStakingPools = createAsyncThunk(
  'metadata/getStakingPools',
  async () => {
    const res = await rf.getRequest('MetaDataRequest').getStakingPools();
    if (res.docs && res.docs.length) return res.docs;
  },
);

function getAvailableCurrencies() {
  let availableCurrencies = [''];
  for (const network in config.networks) {
    availableCurrencies = availableCurrencies.concat(
      Object.keys(config.networks[network].currencies).map(
        (currency: any) =>
          config.networks[network].currencies[currency].coingeckoId,
      ),
    );
  }
  return _.uniq(availableCurrencies);
}

export const getTokensUsdPrice = createAsyncThunk(
  'metadata/getTokensUsdPrice',
  async () => {
    const supportedTokens = getAvailableCurrencies();
    const params = {
      vs_currency: 'usd',
      ids: supportedTokens.join(','),
    };
    const resObject = await rf
      .getRequest('CoingeckoRequest')
      .getTokenPrice(params);
    if (resObject) {
      return resObject.reduce(function (result: any, item: any) {
        const key = item.symbol.toLowerCase();
        result[key] = item.current_price;
        return result;
      }, {});
    }
  },
);

export const init = createAsyncThunk(
  'metadata/init',
  async (_, { dispatch }) => {
    dispatch(getMetadataStakingPools());
    dispatch(getTiers());
    dispatch(getTokensUsdPrice());
  },
);

const metadataSlice = createSlice({
  name: 'metadata',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTiers.fulfilled, (state, { payload }) => {
        state.tiers = payload;
      })
      .addCase(getTokensUsdPrice.fulfilled, (state, { payload }) => {
        state.tokensUsdPrice = payload;
      })
      .addCase(getMetadataStakingPools.fulfilled, (state, { payload }) => {
        state.metadataStakingPools = payload;
      });
  },
});

export default metadataSlice.reducer;
