import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { isMobile } from 'react-device-detect';
import styles from 'src/styles/pages/LBPDetails/LBPDetailPage.module.scss';
import { useParams } from 'react-router-dom';
import rf from 'src/requests/RequestFactory';
import LoadingIcon from 'src/assets/icons/LoadingIcon';
import PartLBPOverview from 'src/pages/PageAuctionDetail/parts/PartLBPOverview';
import PartLBPDetail from 'src/pages/PageAuctionDetail/parts/PartLBPDetail';
import PartLBPChart from './parts/PartLBPChart';
import PartLBPSwap from './parts/PartLBPSwap';
import { checkIsTokenBase, getToken } from 'src/utils/utils-token';
import { convertWeiToDec } from 'src/utils/utils-formats';
import { multicall } from 'src/utils/utils-multicall';
import { appRetry } from 'src/utils/utils-helpers';
import abi from 'src/abi';
import NoResult from 'src/assets/icons/NoResult';
import {
  ITokenAuction,
  calcOutGivenIn,
  getCurrentBalances,
  getStatusEnableSwap,
} from 'src/utils/utils-auction';
import AppAlertWarning from 'src/components/AppAlertWarning';
import ModalWarningLBP from 'src/modals/ModalWarningLBP';
import useAuth from 'src/hooks/useAuth';
import moment from 'moment';
import { Auction } from 'src/utils/auction';

const STATUS_POOL = {
  JOIN: 'JOIN',
  EXIT: 'EXIT',
};

const LBPDetails = () => {
  const { id: auctionId } = useParams() as any;
  const [auction, setAuction] = useState<any>(null);
  const [baseToken, setBaseToken] = useState<any>({});
  const [mainToken, setMainToken] = useState<any>({});
  const [mainTokenIndex, setMainTokenIndex] = useState<number>(0);
  const [baseTokenIndex, setBaseTokenIndex] = useState<number>(1);
  const [balanceStart, setBalanceStart] = useState<string[]>([]);
  const [balanceCurrent, setBalanceCurrent] = useState<string[]>([]);
  const [tokenWeights, setTokenWeights] = useState<string[]>([]);
  const [swapFee, setSwapFee] = useState<string>('0');
  const [loading, setLoading] = useState<boolean>(true);
  const [isEnabledSwap, setIsEnabled] = useState<boolean>(true);
  const [isOpenModalWaringLBP, setIsOpenModalWaringLBP] =
    useState<boolean>(false);
  const { user } = useAuth();
  const reloadAuctionDetailInterval: { current: NodeJS.Timeout | null } =
    useRef(null);

  const lbpAuction = useMemo(() => new Auction(auction), [auction]);

  const onHandleAuctionDetail = (auction: any) => {
    const { pool, network: poolNetwork, isVerified } = auction;
    // if pool is not available yet, use draftInfo instead
    const tokens = lbpAuction.getTokens();

    if (reloadAuctionDetailInterval.current) {
      clearInterval(reloadAuctionDetailInterval.current);
    }
    if (!pool) {
      // reload to get auction detail after every 30 seconds
      reloadAuctionDetailInterval.current = setInterval(() => {
        fetchAuctionDetail();
      }, 30000);
    }

    if (!isVerified) {
      setIsOpenModalWaringLBP(true);
    }

    // set baseToken
    let tokenBaseInPool = tokens.find((item: ITokenAuction) =>
      checkIsTokenBase(item.address, poolNetwork),
    );
    const tokenBaseIndex = tokens.findIndex(
      (item: ITokenAuction) => item.address === tokenBaseInPool.address,
    );
    tokenBaseInPool = getToken(
      poolNetwork,
      tokenBaseInPool.symbol.toLowerCase(),
    );
    setBaseToken(tokenBaseInPool);
    setBaseTokenIndex(tokenBaseIndex);

    // set mainToken
    let tokenMainInPool = tokens.find(
      (item: ITokenAuction) => !checkIsTokenBase(item.address, poolNetwork),
    );
    tokenMainInPool = { ...tokenMainInPool, icon: auction.logoUrl };
    const tokenMainIndex = tokens.findIndex(
      (item: ITokenAuction) => item.address === tokenMainInPool.address,
    );
    setMainToken(tokenMainInPool);
    setMainTokenIndex(tokenMainIndex);
  };

  const fetchAuctionDetail = useCallback(async () => {
    setLoading(true);
    try {
      const response = (await rf
        .getRequest('AuctionsRequest')
        .getAuctionDetail(auctionId)) as any;
      if (response) {
        setAuction(response);
        onHandleAuctionDetail(response);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  }, [auctionId]);

  const getBalancesCurrentPool = async () => {
    const { pool, network: poolNetwork } = auction;
    if (!pool) {
      return;
    }
    const balances = await getCurrentBalances(
      pool.onchainId,
      poolNetwork,
      pool.vault.address,
    );
    setBalanceCurrent(balances);
  };

  const getWeightsAndSwapFeePool = async () => {
    const { pool, network: poolNetwork } = auction;
    if (!pool) {
      return;
    }
    try {
      const calls = [
        {
          address: pool.address,
          name: 'getNormalizedWeights',
          params: [],
        },
        {
          address: pool.address,
          name: 'getSwapFeePercentage',
          params: [],
        },
      ];

      const [normalizedWeights, swapFeePercentage] = await multicall(
        abi['LBP'],
        calls,
        poolNetwork,
      );

      const weights = normalizedWeights[0];
      setTokenWeights(weights);
      setSwapFee(convertWeiToDec(swapFeePercentage));
    } catch (e) {
      console.error(e);
    }
  };

  const getInfoJoinExitHistoryPool = async () => {
    const { pool } = auction;
    if (!pool) {
      return;
    }
    try {
      const response = (await rf
        .getRequest('AuctionsRequest')
        .getInfoJoinExitHistoryPool(pool.id)) as any;
      if (response && response.docs) {
        const balancePoolStart = response.docs.find(
          (item: any) => item.type === STATUS_POOL.JOIN,
        );
        const balancePoolExit = response.docs.find(
          (item: any) => item.type === STATUS_POOL.EXIT,
        );
        setBalanceStart(balancePoolStart.amounts);

        if (balancePoolExit) {
          setBalanceCurrent(balancePoolExit.amounts);
        }
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  const getStatusEnabledSwap = async () => {
    const { network, pool } = auction;
    if (!pool) {
      return;
    }
    const { address: poolAddress } = pool;
    const isEnabled = await getStatusEnableSwap(network, poolAddress);
    setIsEnabled(isEnabled);
  };

  const getInfosPool = async () => {
    await getBalancesCurrentPool();
    await getInfoJoinExitHistoryPool();
    await getWeightsAndSwapFeePool();
    await getStatusEnabledSwap();
  };

  useEffect(() => {
    if (!auction) return;
    appRetry(getInfosPool()).then();
    const getInfos = setInterval(() => appRetry(getInfosPool()), 300000);
    return () => {
      clearInterval(getInfos);
    };
  }, [auction]);

  useEffect(() => {
    fetchAuctionDetail();
  }, [auctionId]);

  const mainTokenReleased = useMemo(() => {
    return +balanceStart[mainTokenIndex] - +balanceCurrent[mainTokenIndex];
  }, [balanceCurrent, balanceStart]);

  const baseTokenAccrued = useMemo(() => {
    return +balanceCurrent[baseTokenIndex] - +balanceStart[baseTokenIndex];
  }, [balanceCurrent, balanceStart]);

  const _renderAlertWarning = () => {
    const { network: poolNetwork, pool, draftInfo } = auction;
    const startTime = pool ? pool.startTime : draftInfo.startTime;
    const endTime = pool ? pool.endTime : draftInfo.endTime;
    const now = moment().unix();
    const isBeforeStart = now < startTime;
    const isEnded = now > endTime;

    const getMessage = () => {
      switch (true) {
        case isBeforeStart:
          return 'The auction has not started yet.';
        case !isEnabledSwap:
          return `Auction host hasn't enabled swapping yet.`;
        case isEnded:
          return 'The auction has ended.';
      }
    };

    if (isBeforeStart) {
      return <AppAlertWarning>{getMessage()}</AppAlertWarning>;
    }

    if (pool && user?.getNetwork() !== poolNetwork) {
      return (
        <AppAlertWarning acceptedNetwork={poolNetwork}>
          Connected wallet does not match target network of {pool.name}
        </AppAlertWarning>
      );
    }

    if (!isEnabledSwap || isEnded) {
      return <AppAlertWarning>{getMessage()}</AppAlertWarning>;
    }

    return;
  };

  const priceTokenMain =
    calcOutGivenIn(
      balanceCurrent[mainTokenIndex],
      tokenWeights[mainTokenIndex]?.toString(),
      balanceCurrent[baseTokenIndex],
      tokenWeights[baseTokenIndex]?.toString(),
      '1',
      swapFee,
    ) || '0';

  const _renderLBPDetail = () => {
    if (!auction) {
      return <NoResult />;
    }

    return (
      <>
        <div className={styles['lbp-detail']}>
          {_renderAlertWarning()}
          <div className={styles['detail-body']}>
            <PartLBPOverview
              auction={auction}
              mainToken={mainToken}
              baseToken={baseToken}
              baseTokenIndex={baseTokenIndex}
              mainTokenIndex={mainTokenIndex}
              mainTokenReleased={mainTokenReleased}
              baseTokenAccrued={baseTokenAccrued}
              balanceStart={balanceStart}
              balanceCurrent={balanceCurrent}
              priceTokenMain={priceTokenMain}
              isEnabledSwap={isEnabledSwap}
              fetchStatusEnableSwap={getStatusEnabledSwap}
            />
            <div className={styles['detail-content']}>
              {!isMobile && (
                <div
                  className={`${styles['content-item']} ${styles['after-rounded']}`}
                >
                  <div className={styles['lbp-swap']}>
                    <div
                      className={`${styles['swap-aside']} ${styles['chart']}`}
                    >
                      <PartLBPChart
                        auction={auction}
                        mainToken={mainToken}
                        baseToken={baseToken}
                        baseTokenIndex={baseTokenIndex}
                        mainTokenIndex={mainTokenIndex}
                        balanceStart={balanceStart}
                        balanceCurrent={balanceCurrent}
                        tokenWeights={tokenWeights}
                        swapFee={swapFee}
                      />
                    </div>
                    <div
                      className={`${styles['swap-aside']} ${styles['form']}`}
                    >
                      <PartLBPSwap
                        auction={auction}
                        mainToken={mainToken}
                        baseToken={baseToken}
                        baseTokenIndex={baseTokenIndex}
                        mainTokenIndex={mainTokenIndex}
                        balanceCurrent={balanceCurrent}
                        tokenWeights={tokenWeights}
                        swapFee={swapFee}
                        priceTokenMain={priceTokenMain}
                        isEnabledSwap={isEnabledSwap}
                        fetchData={getInfosPool}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div
                className={`${styles['content-item']} ${styles['group-tab-desktop']}`}
              >
                <PartLBPDetail
                  auction={auction}
                  mainToken={mainToken}
                  baseToken={baseToken}
                  baseTokenIndex={baseTokenIndex}
                  mainTokenIndex={mainTokenIndex}
                  mainTokenReleased={mainTokenReleased}
                  baseTokenAccrued={baseTokenAccrued}
                  balanceStart={balanceStart}
                  balanceCurrent={balanceCurrent}
                  tokenWeights={tokenWeights}
                  swapFee={swapFee}
                  priceTokenMain={priceTokenMain}
                  isEnabledSwap={isEnabledSwap}
                  fetchData={getInfosPool}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={styles['single-page']}>
      <section className={styles['max-width-content']}>
        {loading ? <LoadingIcon /> : <>{_renderLBPDetail()}</>}
      </section>

      <ModalWarningLBP
        open={isOpenModalWaringLBP}
        onClose={() => setIsOpenModalWaringLBP(false)}
      />
    </div>
  );
};

export default LBPDetails;
