import { Slider, SliderProps } from '@material-ui/core';
import { ChangeEvent, FC } from 'react';
import styles from 'src/styles/components/AppInputRange.module.scss';

interface AppInputRangeProps extends SliderProps {
  variant?: 'secondary';
  // eslint-disable-next-line no-use-before-define
  handleChange: (event: ChangeEvent<any>, value: number | number[]) => void;
}

const AppInputRange: FC<AppInputRangeProps> = ({
  variant = 'secondary',
  handleChange,
  ...props
}) => {
  return (
    <Slider
      {...props}
      onChange={handleChange}
      classes={{
        root: styles[`root-${variant}`],
        rail: `${styles['rail']} ${styles['rail-' + variant]}`,
        track: `${styles['track']} ${styles['track-' + variant]}`,
        thumb: `${styles['thumb']} ${styles['thumb-' + variant]}`,
      }}
    />
  );
};

export default AppInputRange;
