import {
  forwardRef,
  Ref,
  useImperativeHandle,
  useState,
  ReactNode,
} from 'react';
import { Tooltip, Collapse } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import moment from 'moment';
import BigNumber from 'bignumber.js';
import { isMobile } from 'react-device-detect';
import styles from 'src/styles/pages/LPB/steps/StepAuctionPreview.module.scss';
import {
  formatShortAddress,
  formatTimestamp,
  formatToPercent,
} from 'src/utils/utils-formats';
import { AuctionType, RefStep, TIME_FORMAT } from 'src/utils/utils-auction';
import AppButton from 'src/components/AppButton';
import { STEPS } from '../index';

interface AuctionPreviewProps {
  auction: AuctionType;
  onClickBack: () => void;
  onClickNext: () => void;
}

const AuctionPreviewCollapse = (props: {
  children: ReactNode;
  title: string;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <div className={styles['collapse']}>
      <div
        className={styles['collapse-header']}
        onClick={() => setOpen((prevState) => !prevState)}
      >
        <div className={styles['collapse-title']}>{props.title}</div>
        {isMobile && (
          <button
            className={`${styles['btn-collap']} ${open ? styles['open'] : ''}`}
          >
            <span className={styles['icon-collapse']} />
          </button>
        )}
      </div>

      <Collapse in={isMobile ? open : true} timeout="auto" unmountOnExit>
        <div className={styles['collapse-body']}>{props.children}</div>
      </Collapse>
    </div>
  );
};

const StepAuctionPreview = forwardRef(
  (props: AuctionPreviewProps, ref: Ref<RefStep>) => {
    const { auction, onClickBack, onClickNext } = props;
    const [isAgreeTermsOfUse, setIsAgreeTermsOfUse] = useState<boolean>(
      +auction.step > STEPS.POOL_PREVIEW,
    );

    const {
      token,
      depositToken,
      collateralToken,
      media,
      duration,
      weights,
      swapFee,
      permissions,
    } = auction;

    const validate = async () => {
      if (!isAgreeTermsOfUse) throw new Error('Invalid validation');
    };

    useImperativeHandle(ref, () => ({
      validate,
    }));

    const onCheckTermsOfUse = () =>
      setIsAgreeTermsOfUse((prevState) => !prevState);

    const _renderCheckbox = () => (
      <label className={styles['check-term']}>
        <span className={styles['checkbox']}>
          <input
            type="checkbox"
            checked={isAgreeTermsOfUse}
            onChange={onCheckTermsOfUse}
          />
          <span className={styles['as-input']}></span>
        </span>
        <span className={styles['text']}>
          By checking this box you indicate that I have read and agree to the
          Metaverse Platform{' '}
          <a
            href="#"
            className={styles['link']}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Use
          </a>{' '}
          and{' '}
          <a
            href="#"
            className={styles['link']}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          .
        </span>
      </label>
    );

    return (
      <>
        <div className={styles['wrap-item']}>
          <div className={`${styles['card']}`}>
            <div className={`${styles['card-body']}`}>
              <div className={styles['title-body']}>Auction Preview</div>
              {/* sub step 1 */}
              <div className={styles['grid']}>
                <div
                  className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
                >
                  <div className={styles['field']}>
                    <label className={styles['label-text']}>Launch token</label>
                  </div>
                </div>
                <div
                  className={`${styles['grid-item']} ${styles['grid-item-grow-full']} ${styles['grid-item-lg-grow']}`}
                >
                  <div
                    className={`${styles['field']} ${styles['field-lg-right']}`}
                  >
                    <Tooltip title={token.address} placement="top-end">
                      <span className={styles['value-text']}>
                        {formatShortAddress(token.address)}
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>

              <div className={`${styles['grid']}`}>
                <div
                  className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
                >
                  <div className={styles['field']}>
                    <label className={styles['label-text']}>
                      Token logo URL
                    </label>
                  </div>
                </div>
                <div
                  className={`${styles['grid-item']} ${styles['grid-item-grow-full']} ${styles['grid-item-lg-grow']}`}
                >
                  <div
                    className={`${styles['field']} ${styles['field-lg-right']}`}
                  >
                    <div className={`${styles['value-link']}`}>
                      {token.logo ? (
                        <>
                          <a
                            href={token.logo}
                            className={styles['link']}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {token.logo}
                          </a>
                          <img
                            src={token.logo}
                            alt="token.logo"
                            className={styles['icon']}
                          />
                        </>
                      ) : (
                        <p>No logo</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className={`${styles['grid']}`}>
                <div
                  className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
                >
                  <div className={styles['field']}>
                    <label className={styles['label-text']}>Token name</label>
                  </div>
                </div>
                <div
                  className={`${styles['grid-item']} ${styles['grid-item-grow-full']} ${styles['grid-item-lg-grow']}`}
                >
                  <div
                    className={`${styles['field']} ${styles['field-lg-right']}`}
                  >
                    <div className={styles['value-text']}>{token.name}</div>
                  </div>
                </div>
              </div>

              <div className={`${styles['grid']}`}>
                <div
                  className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
                >
                  <div className={styles['field']}>
                    <label className={styles['label-text']}>Token symbol</label>
                  </div>
                </div>
                <div
                  className={`${styles['grid-item']} ${styles['grid-item-grow-full']} ${styles['grid-item-lg-grow']}`}
                >
                  <div
                    className={`${styles['field']} ${styles['field-lg-right']}`}
                  >
                    <div className={styles['value-text']}>
                      {token.symbol}
                      {token.logo && (
                        <img
                          src={token.logo}
                          alt=""
                          className={styles['icon']}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className={`${styles['grid']} ${styles['grid-collapse']}`}>
                <div className={`${styles['grid-item']}`}>
                  <AuctionPreviewCollapse title={'Deposit Token'}>
                    <div className={`${styles['grid']}`}>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
                      >
                        <div className={styles['field']}>
                          <label className={styles['label-text']}>
                            Launch token
                          </label>
                        </div>
                      </div>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-grow']}`}
                      >
                        <div
                          className={`${styles['field']} ${styles['field-right']}`}
                        >
                          <div className={styles['value-text']}>
                            {depositToken.launch} {token.symbol}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles['grid']}`}>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
                      >
                        <div className={styles['field']}>
                          <label className={styles['label-text']}>
                            Collateral
                          </label>
                        </div>
                      </div>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-grow']}`}
                      >
                        <div
                          className={`${styles['field']} ${styles['field-right']}`}
                        >
                          <div className={styles['value-text']}>
                            {depositToken.collateral}{' '}
                            {collateralToken.symbol.toUpperCase()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </AuctionPreviewCollapse>
                </div>
                <div className={`${styles['grid-item']}`}>
                  <AuctionPreviewCollapse title={'Time'}>
                    <div className={`${styles['grid']}`}>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
                      >
                        <div className={styles['field']}>
                          <label className={styles['label-text']}>
                            Start time
                          </label>
                        </div>
                      </div>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-grow']}`}
                      >
                        <div
                          className={`${styles['field']} ${styles['field-right']}`}
                        >
                          <div className={styles['value-text']}>
                            {formatTimestamp(
                              duration.startDate.getTime(),
                              TIME_FORMAT,
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles['grid']}`}>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
                      >
                        <div className={styles['field']}>
                          <label className={styles['label-text']}>
                            End time
                          </label>
                        </div>
                      </div>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-grow']}`}
                      >
                        <div
                          className={`${styles['field']} ${styles['field-right']}`}
                        >
                          <div className={styles['value-text']}>
                            {formatTimestamp(
                              duration.endDate.getTime(),
                              TIME_FORMAT,
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles['grid']}`}>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
                      >
                        <div className={styles['field']}>
                          <label className={styles['label-text']}>
                            Duration
                          </label>
                        </div>
                      </div>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-grow']}`}
                      >
                        <div
                          className={`${styles['field']} ${styles['field-right']}`}
                        >
                          <div className={styles['value-text']}>
                            {moment(duration.endDate).diff(
                              moment(duration.startDate),
                              'hours',
                            )}{' '}
                            hours(
                            {moment(duration.endDate).diff(
                              moment(duration.startDate),
                              'days',
                            )}{' '}
                            days)
                          </div>
                        </div>
                      </div>
                    </div>
                  </AuctionPreviewCollapse>
                </div>
                <div className={`${styles['grid-item']}`}>
                  <AuctionPreviewCollapse title={'Weights'}>
                    <div className={`${styles['grid']}`}>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
                      >
                        <div className={styles['field']}>
                          <label className={styles['label-text']}>
                            Starting Weight
                          </label>
                        </div>
                      </div>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-grow']}`}
                      >
                        <div
                          className={`${styles['field']} ${styles['field-right']}`}
                        >
                          <div className={styles['value-text']}>
                            {formatToPercent(weights.startWeight)}&nbsp;
                            {token.symbol}&nbsp;
                            {formatToPercent(
                              new BigNumber(1).minus(weights.startWeight),
                            )}
                            &nbsp;
                            {collateralToken.symbol.toUpperCase()}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles['grid']}`}>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
                      >
                        <div className={styles['field']}>
                          <label className={styles['label-text']}>
                            End weight
                          </label>
                        </div>
                      </div>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-grow']}`}
                      >
                        <div
                          className={`${styles['field']} ${styles['field-right']}`}
                        >
                          <div className={styles['value-text']}>
                            {formatToPercent(weights.endWeight)}&nbsp;
                            {token.symbol}&nbsp;
                            {formatToPercent(
                              new BigNumber(1).minus(weights.endWeight),
                            )}
                            &nbsp;
                            {collateralToken.symbol.toUpperCase()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </AuctionPreviewCollapse>
                </div>
                <div className={`${styles['grid-item']}`}>
                  <AuctionPreviewCollapse title={'Description'}>
                    <div className={`${styles['grid']}`}>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
                      >
                        <div className={styles['field']}>
                          <label className={styles['label-text']}>
                            Swap Fee
                          </label>
                        </div>
                      </div>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-grow']}`}
                      >
                        <div
                          className={`${styles['field']} ${styles['field-right']}`}
                        >
                          <div className={styles['value-text']}>
                            {+swapFee * 100}%
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles['grid']}`}>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-4']}`}
                      >
                        <div className={styles['field']}>
                          <label className={styles['label-text']}>
                            Website
                          </label>
                        </div>
                      </div>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-8']}`}
                      >
                        <div
                          className={`${styles['field']} ${styles['field-right']}`}
                        >
                          <div className={styles['value-link']}>
                            {media.website ? (
                              <a
                                href={media.website}
                                className={styles['link']}
                              >
                                {media.website}
                              </a>
                            ) : (
                              <p>No website</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles['grid']}`}>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-4']}`}
                      >
                        <div className={styles['field']}>
                          <label className={styles['label-text']}>
                            Telegram
                          </label>
                        </div>
                      </div>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-8']}`}
                      >
                        <div
                          className={`${styles['field']} ${styles['field-right']}`}
                        >
                          <div className={styles['value-link']}>
                            {media.telegram ? (
                              <a
                                href={media.telegram}
                                className={styles['link']}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {media.telegram}
                              </a>
                            ) : (
                              <p>No telegram</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles['grid']}`}>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-4']}`}
                      >
                        <div className={styles['field']}>
                          <label className={styles['label-text']}>
                            Twitter
                          </label>
                        </div>
                      </div>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-8']}`}
                      >
                        <div
                          className={`${styles['field']} ${styles['field-right']}`}
                        >
                          <div className={styles['value-link']}>
                            {media.twitter ? (
                              <a
                                href={media.twitter}
                                className={styles['link']}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {media.twitter}
                              </a>
                            ) : (
                              <p>No twitter</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles['grid']}`}>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-4']}`}
                      >
                        <div className={styles['field']}>
                          <label className={styles['label-text']}>
                            Discord
                          </label>
                        </div>
                      </div>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-8']}`}
                      >
                        <div
                          className={`${styles['field']} ${styles['field-right']}`}
                        >
                          <div className={styles['value-link']}>
                            {media.discord ? (
                              <a
                                href={media.discord}
                                className={styles['link']}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {media.discord}
                              </a>
                            ) : (
                              <p>No discord</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles['grid']}`}>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-4']}`}
                      >
                        <div className={styles['field']}>
                          <label className={styles['label-text']}>Medium</label>
                        </div>
                      </div>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-8']}`}
                      >
                        <div
                          className={`${styles['field']} ${styles['field-right']}`}
                        >
                          <div className={styles['value-link']}>
                            {media.medium ? (
                              <a
                                href={media.medium}
                                className={styles['link']}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {media.medium}
                              </a>
                            ) : (
                              <p>No medium</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles['grid']}`}>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-4']}`}
                      >
                        <div className={styles['field']}>
                          <label className={styles['label-text']}>
                            Website
                          </label>
                        </div>
                      </div>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-8']}`}
                      >
                        <div
                          className={`${styles['field']} ${styles['field-right']}`}
                        >
                          <div className={styles['value-link']}>
                            {media.website ? (
                              <a
                                href={media.website}
                                className={styles['link']}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {media.website}
                              </a>
                            ) : (
                              <p>No website</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles['grid']}`}>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
                      >
                        <div className={styles['field']}>
                          <label className={styles['label-text']}>
                            Pause trading
                          </label>
                        </div>
                      </div>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-grow']}`}
                      >
                        <div
                          className={`${styles['field']} ${styles['field-right']}`}
                        >
                          <div className={styles['value-text']}>
                            {permissions.pauseTrading ? (
                              <span className={styles['icon-done']}>
                                <DoneIcon />
                              </span>
                            ) : (
                              <span className={styles['icon-fail']}>
                                <CloseIcon />
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${styles['grid']}`}>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-auto']}`}
                      >
                        <div className={styles['field']}>
                          <label className={styles['label-text']}>
                            Pull liquidity
                          </label>
                        </div>
                      </div>
                      <div
                        className={`${styles['grid-item']} ${styles['grid-item-grow']}`}
                      >
                        <div
                          className={`${styles['field']} ${styles['field-right']}`}
                        >
                          <div className={styles['value-text']}>
                            {permissions.pullLiquidity ? (
                              <span className={styles['icon-done']}>
                                <DoneIcon />
                              </span>
                            ) : (
                              <span className={styles['icon-fail']}>
                                <CloseIcon />
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </AuctionPreviewCollapse>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles['wrap-footer']}`}>
          <div className={styles['footer-fixed']}>
            {_renderCheckbox()}
            <div className={styles['row']}>
              <div className={styles['group-button-left']}>
                <AppButton
                  variant="outline"
                  onClick={onClickBack}
                  isDisable={false}
                  className={styles['btn']}
                >
                  Back
                </AppButton>
              </div>
              <div className={styles['group-button-right']}>
                <AppButton
                  variant="primary"
                  onClick={onClickNext}
                  isDisable={!isAgreeTermsOfUse}
                  className={styles['btn']}
                >
                  Next
                </AppButton>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  },
);

export default StepAuctionPreview;
