import { ChangeEvent, FC } from 'react';
import { OutlinedInput, OutlinedInputProps } from '@material-ui/core';
import styles from 'src/styles/components/AppInput.module.scss';
import { useForceRender } from 'src/hooks/useForceRender';
import SimpleReactValidator from 'simple-react-validator';

interface ValidatorProps {
  validator: SimpleReactValidator;
  name: string;
  rule: string | Array<string | { [key: string]: any }>;
  options?: { [key: string]: any };
}

interface AppInputProps extends OutlinedInputProps {
  size?: 'small' | 'medium';
  search?: boolean;
  validate?: ValidatorProps;
  multiline?: boolean;
  handleChange: (value: string) => void;
  handleBlur?: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

const AppInput: FC<AppInputProps> = (props) => {
  const {
    validate,
    handleChange,
    handleBlur,
    multiline,
    size = 'medium',
    classes,
    ...rest
  } = props;
  const forceRender = useForceRender();

  const onBlur = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    validate?.validator.showMessageFor(validate.name);
    forceRender();
    handleBlur && handleBlur(e);
  };

  return (
    <>
      <OutlinedInput
        {...rest}
        multiline={multiline}
        classes={{
          ...classes,
          root: `${styles['root']} ${!multiline ? styles[`root-${size}`] : ''}`,
          input: `${!multiline ? styles['input'] : styles['textare']}`,
          disabled: styles['disabled'],
          focused: styles['focused'],
          error: styles['error'],
          notchedOutline: styles['notchedOutline'],
          inputAdornedStart: styles['inputAdornedStart'],
        }}
        onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
          handleChange(e.target.value);
        }}
        onBlur={onBlur}
      />
      {validate &&
        validate.validator.message(
          validate.name,
          props.value,
          validate.rule,
          validate.options,
        )}
    </>
  );
};

export default AppInput;
