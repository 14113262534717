import { useState } from 'react';
import { Collapse, withWidth } from '@material-ui/core';
import {
  formatShortAddress,
  formatWeiNumber,
  formatTimestamp,
} from 'src/utils/utils-formats';
import rf from 'src/requests/RequestFactory';
import styles from 'src/styles/pages/StakingPools.module.scss';
import { DiamondTierIcon, SnapshotIcon } from 'src/assets/icons';
import { withRouter } from 'react-router';
import { isMobile } from 'react-device-detect';
import AppDataTable from 'src/components/AppDataTable';

const PartDiamondUsers = () => {
  const [diamondUsers, setDiamondUsers] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [isReadMore, setIsReadMore] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);

  const fetchDiamondUsers = async () => {
    const response = await rf.getRequest('StakingRequest').getDiamondUsers();
    setDiamondUsers(response.docs || []);
    return response;
  };

  const _renderUserForDesktop = (diamondUser: any, index: number) => {
    const stakedBpt =
      diamondUser.stakingTokens && diamondUser.stakingTokens[1]
        ? diamondUser.stakingTokens[1].stakedAmount
        : 0;
    const stakedBuni =
      diamondUser.stakingTokens && diamondUser.stakingTokens[0]
        ? diamondUser.stakingTokens[0].stakedAmount
        : 0;
    return (
      <div className={`${styles['item']}`} key={diamondUser.userAddress}>
        <div className={`row`}>
          <div className="col-1">
            <div>{index + 1}</div>
          </div>
          <div className="col-2">
            <div>{formatShortAddress(diamondUser.userAddress)}</div>
          </div>
          <div className="col-2">
            <div>{formatWeiNumber(diamondUser.amountStaked)} BUNI</div>
          </div>

          <div className="col-2">
            <div>{formatWeiNumber(stakedBpt)} BPT</div>
          </div>
          <div className="col-2">
            <div>{formatWeiNumber(stakedBuni)} BUNI</div>
          </div>
          <div className="col-3">
            <div>
              {formatTimestamp(
                diamondUser.lastTimeStaked,
                'HH:MM - YYYY/MM/DD',
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const _renderUserForMobile = (diamondUser: any, index: number) => {
    return (
      <div className={styles['card-staking']} key={diamondUser.userAddress}>
        <div
          onClick={() => setSelectedUser(diamondUser)}
          className={styles['card-staking-header']}
        >
          <div className={styles['flex-header']}>
            <span className={styles['icon-position']}>{index + 1}</span>
            <h5 className={styles['title']}>
              {formatShortAddress(diamondUser.userAddress)}
            </h5>
            <span
              className={`${styles['icon-collapse']} ${
                selectedUser?.userAddress !== diamondUser.userAddress
                  ? styles['collapsed']
                  : ''
              }`}
            />
          </div>
        </div>
        <div className={styles['card-staking-subheader']}>
          <label className={styles['label']}>Total BUNI Value</label>
          <h6 className={styles['text']}>
            {formatWeiNumber(diamondUser.amountStaked)} BUNI
          </h6>
        </div>
        <Collapse
          in={selectedUser?.userAddress === diamondUser.userAddress}
          timeout="auto"
          unmountOnExit
        >
          <div className={styles['card-staking-body']}>
            <div className={styles['card-staking-item']}>
              <label className={styles['label']}>BPT Staked</label>
              <h6 className={styles['text']}>
                {formatWeiNumber(diamondUser.stakingTokens[1]?.stakedAmount)}{' '}
                BPT
              </h6>
            </div>
            <div className={styles['card-staking-item']}>
              <label className={styles['label']}>BUNI Staked</label>
              <h6 className={styles['text']}>
                {formatWeiNumber(diamondUser.stakingTokens[0]?.stakedAmount)}{' '}
                BUNI
              </h6>
            </div>
            <div className={styles['card-staking-item']}>
              <label className={styles['label']}>Last Updated</label>
              <h6 className={styles['text']}>
                {formatTimestamp(
                  diamondUser.lastTimeStaked,
                  'HH:MM - YYYY/MM/DD',
                )}
              </h6>
            </div>
          </div>
        </Collapse>
      </div>
    );
  };

  const _renderInfoIntro = () => {
    return (
      <div className={styles['text']}>
        Diamond users are top 10 BUNI stakers and will get guaranteed allocation
        for every IDO projects when they register for whitelisting.
        <br />
        At first, a staking competition will be hosted to select the top 10
        stakers and reward them Diamond tier.{' '}
        {isReadMore ? (
          <>
            <span>
              After that, for other users who want to join Diamond Tier, a
              staking competition would be hosted on monthly basis or when any
              diamond user unstakes BUNI/LP and loses their tiers. In the
              competition, at least 3 last Diamond slots would be available to
              auctioned.{' '}
            </span>
            <span
              className={styles['read-more']}
              onClick={() => setIsReadMore(false)}
            >
              Read less
            </span>
          </>
        ) : (
          <span
            className={styles['read-more']}
            onClick={() => setIsReadMore(true)}
          >
            Read more...
          </span>
        )}
      </div>
    );
  };

  const _renderEmptyUsers = () => {
    return (
      <p className={styles['warning']}>
        The first Diamond user list will be announced after our upcoming staking
        competition
      </p>
    );
  };

  const _renderSnapshot = () => {
    return (
      <div className={styles['snapshot']}>
        <div className={styles['snapshot-detail']}>
          <div className={styles['label']}>
            <SnapshotIcon className={styles['icon']} />
            Snapshot Time :
          </div>
          <div className={styles['snap-time']}>
            {formatTimestamp(
              diamondUsers[0]?.finalizedTime,
              'HH:MM - YYYY/MM/DD',
            )}
          </div>
        </div>
        <div className={styles['snap-time']} />
        <div className={styles['token-rate']}>
          (Conversion Rate: <span>1BPT = 12,500 BUNI</span>)
        </div>
      </div>
    );
  };

  const _renderForMobile = () => {
    return (
      <section className={styles['staking-table-mobile']}>
        <div className={styles['card-staking']}>{_renderInfoIntro()}</div>
        <AppDataTable
          fetchData={fetchDiamondUsers}
          renderHeader={() => (
            <div className={styles['card-staking']}>{_renderSnapshot()}</div>
          )}
          renderBody={(users: any[]) =>
            users.map((diamondUser: any, index: number) => {
              return _renderUserForMobile(diamondUser, index);
            })
          }
          renderNoData={_renderEmptyUsers}
        />
      </section>
    );
  };

  const _renderForDesktop = () => {
    return (
      <div
        className={`${styles['collapse-tier']} ${
          open ? styles['collapse-tier-show'] : ''
        }`}
      >
        <div
          className={styles['header']}
          onClick={() => setOpen((open) => !open)}
        >
          <div className={styles['title']}>Diamond Tier Ranking</div>
          <div>
            <span
              className={`${styles['icon-collapse']} ${
                !open ? styles['collapsed'] : ''
              }`}
            />
          </div>
        </div>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div className={styles['diamond-tier']}>
            <div className={styles['note']}>
              <DiamondTierIcon />
              {_renderInfoIntro()}
            </div>
            <div>{_renderSnapshot()}</div>
            <AppDataTable
              wrapperClassName={styles['table']}
              fetchData={fetchDiamondUsers}
              renderHeader={() => (
                <div className={`row ${styles['thead']}`}>
                  <div className="col-1">
                    <div className={styles.name}>Position</div>
                  </div>
                  <div className="col-2">
                    <div className={styles.name}>Wallet Address</div>
                  </div>
                  <div className="col-2">
                    <div className={styles.name}>Total Buni Value</div>
                  </div>
                  <div className="col-2">
                    <div className={styles.name}>BPT Staked</div>
                  </div>
                  <div className="col-2">
                    <div className={styles.name}>BUNI Staked</div>
                  </div>
                  <div className="col-3">
                    <div className={styles.name}>Last Updated</div>
                  </div>
                </div>
              )}
              renderBody={(users: any[]) =>
                users.map((diamondUser: any, index: number) => {
                  return _renderUserForDesktop(diamondUser, index);
                })
              }
              renderNoData={_renderEmptyUsers}
            />
          </div>
        </Collapse>
      </div>
    );
  };

  return (
    <>{isMobile ? <>{_renderForMobile()}</> : <>{_renderForDesktop()}</>}</>
  );
};

export default withWidth()(withRouter(PartDiamondUsers));
