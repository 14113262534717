import { FC, useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ModalComingSoon from 'src/modals/ModalComingSoon';
import BasePage from 'src/pages/BasePage';
import rf from 'src/requests/RequestFactory';
import PartBanner from './part/PartBanner';
import PartContactUs from './part/PartContactUs';
import PartListProject from './part/PartListProject';
import PartOverViewLBP from './part/PartOverViewLBP';
import PartStaking from './part/PartStaking';

const HomePage: FC = (props: any) => {
  const [highlightPools, setHighlightPools] = useState<any>([]);
  const [featuredPools, setFeaturedPools] = useState([]);
  const [isModalComingSoon, setIsModalComingSoon] = useState<boolean>(false);

  useEffect(() => {
    getHighlightPools();
    getFeaturedPools();
  }, []);

  const getHighlightPools = useCallback(async () => {
    const res = await rf.getRequest('PoolRequest').getHighlightPools();
    setHighlightPools(res.docs || []);
  }, []);

  const getFeaturedPools = useCallback(async () => {
    const res = await rf.getRequest('PoolRequest').getFeaturedPools();
    setFeaturedPools(res.docs || []);
  }, []);

  return (
    <BasePage>
      <div className="homepage">
        <PartBanner pools={highlightPools} />
        <PartContactUs />
        <PartListProject featuredPools={featuredPools} />
        <PartStaking />
        <PartOverViewLBP setIsModalComingSoon={setIsModalComingSoon} />
      </div>
      <ModalComingSoon
        open={isModalComingSoon}
        onClose={() => setIsModalComingSoon(false)}
      />
    </BasePage>
  );
};

export default withRouter(HomePage);
