import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { AuthUser, UserInterface } from 'src/utils/user';
import { BaseProvider, Web3Provider } from '@ethersproject/providers';
import {
  setAddress,
  setChainId,
  setConnector,
  clearAuth,
  setLoadingWallet,
  setNetwork,
  setProvider,
  setToken,
  toggleSignatureRequiredModal,
} from 'src/store/authentication';
import { clearAccount } from 'src/store/myAccount';
import Storage from 'src/utils/storage';
import BaseConnector from 'src/connectors/BaseConnector';
import { AppBroadcast } from 'src/utils/utils-broadcast';
import {
  generateJwtToken,
  setAuthorizationToRequest,
} from 'src/utils/utils-auth';
import web3 from 'web3';
import config, { Network } from 'src/config';
import _ from 'lodash';
import ConnectorFactory from 'src/connectors';
import { useMemo } from 'react';
import { getErrorMessage } from 'src/utils/utils-helpers';
import { toastError } from '../utils/utils-notify';

type ReturnType = {
  user: UserInterface | null;
  connectWallet: (connectorId: string, network: string) => Promise<void>;
  disconnectWallet: () => void;
  createAccessToken: (connector: BaseConnector) => Promise<void>;
};

const useAuth = (): ReturnType => {
  const {
    address,
    provider: authProvider,
    connector,
    network,
  } = useSelector((state: RootState) => state.authentication);
  const { myTier, balance, userInfo, linkedAccounts, linkedProviders } =
    useSelector((state: RootState) => state.myAccount);

  const dispatch = useDispatch();

  const getUser = () => {
    if (!address) {
      return null;
    }
    const user = new AuthUser(address);
    user.setNetwork(network);
    user.setTier(myTier?.tier || '');
    user.setKYCStatus(userInfo?.kycStatusText || '');
    user.setBalance(balance || '');
    user.setLinkedAccounts(linkedAccounts || []);
    user.setLinkedProviders(linkedProviders || []);
    authProvider && user.setProvider(new Web3Provider(authProvider));
    return user;
  };

  const createAccessToken = async (connector: BaseConnector) => {
    try {
      const signature = await connector.signMessage();
      const newToken = await generateJwtToken(
        connector.account,
        signature || '',
      );

      await _saveAuthentication(newToken, Storage.getChainId());
      dispatch(toggleSignatureRequiredModal(false));
    } catch (error: any) {
      toastError({ message: getErrorMessage(error) });
    }
  };

  const _saveAuthentication = async (accessToken: string, chainId: string) => {
    if (accessToken) {
      await dispatch(setToken(accessToken));
      setAuthorizationToRequest(accessToken, chainId);
    }
    chainId && (await dispatch(setChainId(chainId)));

    AppBroadcast.dispatch('LOAD_USER_INFORMATION');
  };

  const _onAccountsChanged = async () => {
    const connectorId = Storage.getConnectorId() || '';
    const network = Storage.getNetwork();
    const connector = ConnectorFactory.getConnector(connectorId, network);
    if (!connector) {
      console.warn(
        '[onAccountedChange] throw warning: Not found connector',
        'connectorId:',
        connectorId,
        'network:',
        network,
      );
      return;
    }
    // get new provider, then get new account
    const provider = await connector.connect();
    if (!provider) {
      throw new Error('Not found provider');
    }
    Storage.clearAuthentication();
    await _onConnectWallet(connector, provider);
  };

  const _onChainChanged = (hexChainId: string) => {
    const chainId = web3.utils.hexToNumber(hexChainId);
    const selectedNetwork: Network | undefined = _.find(
      config.networks,
      (network) => Number(network.chainId) === Number(chainId),
    );

    selectedNetwork && dispatch(setNetwork(selectedNetwork.id));
    selectedNetwork && dispatch(setChainId(selectedNetwork.chainId));

    if (Storage.getAddress()) {
      // check user login
      AppBroadcast.dispatch('LOAD_USER_NATIVE_BALANCE');
    }
    dispatch(toggleSignatureRequiredModal(true));
  };

  const _onSaveProvider = (provider: BaseProvider) => {
    dispatch(setProvider(provider));
    if (provider.removeAllListeners) {
      provider.removeAllListeners();
    }
    if (!provider.on) {
      return;
    }
    provider.on('chainChanged', async (chainId: string) => {
      _onChainChanged(chainId);
    });
    provider.on('accountsChanged', async () => {
      await _onAccountsChanged();
    });
  };

  const _onConnectWallet = async (
    connector: BaseConnector,
    provider: BaseProvider,
  ) => {
    const storageAccount = Storage.getAddress(); // NOTE: get before getBasicUserInfo because getBasicUserInfo saves new address to localStorage
    const accessToken = Storage.getAccessToken();

    const account = await connector.getAccount(provider);
    if (!account) {
      throw new Error('Not found connected account from provider');
    }
    dispatch(setAddress(account));
    _onSaveProvider(provider);
    if (account !== storageAccount || !accessToken) {
      // if hasn't have access token or account has changed
      return dispatch(toggleSignatureRequiredModal(true));
    }
    // save to redux
    const chainId = connector.network.chainId;
    await _saveAuthentication(accessToken, chainId);
  };

  const connectWallet = async (connectorId: string, network: string) => {
    const connector = ConnectorFactory.getConnector(connectorId, network);
    if (!connector) {
      return;
    }
    dispatch(setLoadingWallet(true));
    try {
      const provider = await connector.connect();
      if (!provider) {
        throw new Error('No provider was found');
      }

      dispatch(setConnector(connector));
      await _onConnectWallet(connector, provider);
    } catch (error: any) {
      disconnectWallet();
      console.error(`[ConnectWallet] throw exception: ${error.message}`, error);
      throw error;
    } finally {
      dispatch(setLoadingWallet(false));
    }
  };

  const _onDisconnectWallet = () => {
    dispatch(clearAuth());
    dispatch(clearAccount());
    Storage.logout();
  };

  const disconnectWallet = () => {
    if (connector && connector.isLoggedIn()) {
      connector.logout();
    }
    _onDisconnectWallet();
  };

  const user = useMemo(
    () => getUser(),
    [
      address,
      network,
      myTier,
      balance,
      userInfo,
      authProvider,
      linkedAccounts,
      linkedProviders,
    ],
  );

  return {
    user,
    connectWallet,
    disconnectWallet,
    createAccessToken,
  };
};

export default useAuth;
