import { Drawer, Menu, MenuItem } from '@material-ui/core';
import { MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {
  formatShortAddress,
  formatTierAsString,
  formatWeiNumber,
} from 'src/utils/utils-formats';
import styles from 'src/styles/components/DropdownMyAccount.module.scss';
import { RootState } from 'src/store';
import { BackIcon, UnverifiedIcon, VerifiedIcon } from 'src/assets/icons';
import config from 'src/config';
import { KYC_STATUS } from 'src/constants';
import { NOT_AVAILABLE_TEXT } from 'src/utils/common';
import { isMobile } from 'react-device-detect';
import useAuth from 'src/hooks/useAuth';
import AppBadge from './AppBadge';

const DropdownMyAccount = () => {
  const {
    authentication: { network },
    myAccount: { balance, isLoadingBalance, userInfo, myTier },
  } = useSelector((state: RootState) => state);

  const { user, disconnectWallet } = useAuth();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [chainCurrency, setChainCurrency] = useState<string>('ETH');

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDisconnect = async () => {
    disconnectWallet();
    handleClose();
  };

  useEffect(() => {
    const networkInfo = config.networks[network];
    if (!networkInfo) return;
    setChainCurrency(networkInfo.currency || 'ETH');
  }, [network]);

  const _renderProfileAccountItem = () => {
    return (
      <div className={styles['profile-item']}>
        <div className={styles['address']}>
          {userInfo?.email || NOT_AVAILABLE_TEXT}
        </div>
        <div className={styles['user-status']}>
          <div className={styles['tier']}>
            {myTier?.icon && (
              <img className={styles['tier-icon']} alt="" src={myTier?.icon} />
            )}
            <span className={styles['tier-text']}>
              {formatTierAsString(myTier?.tier) + ' User'}
            </span>
          </div>
          {userInfo.kycStatusText !== KYC_STATUS.APPROVED && (
            <div className={styles['kyc-status']}>
              <AppBadge color="orange" className={styles['verified']}>
                <UnverifiedIcon />
                <span>Unverified</span>
              </AppBadge>
            </div>
          )}
          {userInfo.kycStatusText === KYC_STATUS.APPROVED && (
            <div className={styles['kyc-status']}>
              <AppBadge color="green" className={styles['verified']}>
                <VerifiedIcon />
                <span>Verified</span>
              </AppBadge>
            </div>
          )}
        </div>
      </div>
    );
  };

  const _renderMenuOnDesktop = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        getContentAnchorEl={null}
        open={!!anchorEl}
        onClose={handleClose}
        classes={{ paper: styles['paper-menu'], list: styles['list-menu'] }}
      >
        <MenuItem classes={{ root: styles['root-drawer-item'] }}>
          {_renderProfileAccountItem()}
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          classes={{ root: styles['root-drawer-item'] }}
        >
          <Link to={'/account'} className={styles['account-item']}>
            <img
              className={styles['account-item-icon']}
              src="/images/header/icon-profile.svg"
              alt="Profile"
            />
            <span className={styles['account-item-text']}>My Profile</span>
          </Link>
        </MenuItem>
        <MenuItem
          onClick={handleDisconnect}
          className={styles['item-disconnect']}
          classes={{ root: styles['root-drawer-item'] }}
        >
          <div
            className={styles['slot-disconnect']}
            aria-labelledby={'on desktop'}
          >
            <img
              className={styles['slot-disconnect-icon']}
              alt="Disconnect"
              src="/images/header/icon-disconnect.svg"
            />
            <span>Disconnect</span>
          </div>
        </MenuItem>
      </Menu>
    );
  };

  const _renderMenuOnMobile = () => {
    return (
      <Drawer anchor={'right'} open={!!anchorEl} onClose={handleClose}>
        <div className={styles['drawer-menu']}>
          <div className={styles['drawer-top']}>
            <div className={styles['header']}>
              <BackIcon onClick={() => handleClose()} />
              <div className={styles['title']}> My wallet</div>
            </div>

            <div className={styles['body']}>
              <MenuItem classes={{ root: styles['root-drawer-item'] }}>
                {_renderProfileAccountItem()}
              </MenuItem>
              <MenuItem
                onClick={handleClose}
                classes={{ root: styles['root-drawer-item'] }}
              >
                <Link to={'/account'} className={styles['account-item']}>
                  <img
                    className={styles['account-item-icon']}
                    src="/images/header/icon-profile.svg"
                    alt="Profile"
                  />
                  <span className={styles['account-item-text']}>
                    My Profile
                  </span>
                </Link>
              </MenuItem>
            </div>
          </div>
          <div className={styles['drawer-bottom']}>
            <div className={styles['wallet-address']}>
              <img
                className={`${styles['wallet-address-icon']} ${styles['img-wallet']}`}
                src="/images/header/icon-wallet.svg"
                alt="icon wallet"
              />

              <span className={styles['wallet-address-text']}>
                {user && formatShortAddress(user.getAddress())}
              </span>
            </div>
            <div className={styles['my-balance']}>
              <div className={styles['label']}>Balance </div>
              <div className={styles['value']}>{`${formatWeiNumber(
                balance,
              )} ${chainCurrency}`}</div>
            </div>
            <MenuItem
              onClick={handleDisconnect}
              classes={{ root: styles['root-drawer-item'] }}
              className={styles['item-disconnect']}
            >
              <div className={styles['slot-disconnect']}>
                <img
                  className={styles['slot-disconnect-icon']}
                  src="/images/header/icon-disconnect.svg"
                  alt="Disconnect"
                />
                <span>Disconnect</span>
              </div>
            </MenuItem>
          </div>
        </div>
      </Drawer>
    );
  };

  return (
    <div className={styles['info-account']}>
      {!isMobile && (
        <div className={styles['asset-account']}>
          <div className={styles['asset-amount']}>
            {isLoadingBalance ? (
              <>
                <BeatLoader color="white" size={8} /> {chainCurrency}
              </>
            ) : (
              `${formatWeiNumber(balance)} ${chainCurrency}`
            )}
          </div>
        </div>
      )}
      <>
        <button
          type="button"
          onClick={handleClick}
          className={styles['btn-popover']}
        >
          <div className={styles['wallet-address']}>
            <img
              className={`${styles['img-contain']} ${styles['img-wallet']}`}
              src="/images/header/icon-wallet.svg"
              alt="icon wallet"
            />
            {!isMobile && (
              <>
                <span className={styles['address']}>
                  {user && formatShortAddress(user.getAddress())}
                </span>
                <img
                  className={styles['img-contain']}
                  src="/images/header/icon-arrow-down.svg"
                  alt="icon wallet"
                  style={{
                    transform: anchorEl ? 'rotate(0)' : 'rotate(180deg)',
                  }}
                />
              </>
            )}
          </div>
        </button>
        {isMobile ? _renderMenuOnMobile() : _renderMenuOnDesktop()}
      </>
    </div>
  );
};

export default DropdownMyAccount;
