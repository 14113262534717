import { FC, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import {
  formatNumber,
  formatTierAsString,
  formatWeiNumber,
  convertWeiToDec,
} from 'src/utils/utils-formats';
import styles from 'src/styles/modals/ModalStakeFarm.module.scss';
import config from 'src/config';
import { getTokenBalance } from 'src/utils/utils-token';
import useAuth from 'src/hooks/useAuth';
import { makeStakeParams } from 'src/utils/utils-farm';
import { processTransaction } from 'src/store/transactions';
import { AppBroadcast } from 'src/utils/utils-broadcast';
import { ethers } from 'ethers';
import { getUserStacked } from 'src/store/myAccount';
import AppInputTokenMax from 'src/components/AppInputTokenMax';
import BaseModal, { BaseModalProps } from './BaseModal';

interface ModalStakeFarmProps extends BaseModalProps {
  poolAddress?: string;
  tokenDetails: any;
  stakingAmount: string | BigNumber;
}

const ModalStakeFarm: FC<ModalStakeFarmProps> = ({
  tokenDetails,
  poolAddress,
  stakingAmount,
  onClose,
  ...props
}) => {
  const {
    myAccount: {
      myTier,
      stakedInfo,
      userInfo: { buniToNextTier },
    },
    metadata: { lpRate: buniLPRatio },
  } = useSelector((state: RootState) => state);
  const { user } = useAuth();

  const { buniStake, lpStake } = stakedInfo;

  const [tokenBalance, setTokenBalance] = useState<string>('0');
  const [amount, setAmount] = useState<string>('');

  const dispatch = useDispatch();

  const stake = async () => {
    try {
      if (!amount || !poolAddress || !ethers.utils.isAddress(poolAddress)) {
        return;
      }
      const params = makeStakeParams(poolAddress, amount);
      setAmount('');
      handleClose();
      await dispatch(
        processTransaction({ provider: user?.getProvider(), params }),
      );
      AppBroadcast.dispatch('LOAD_USER_INFORMATION');
      await dispatch(getUserStacked());
    } catch (err) {
      console.log(err);
    }
  };

  const fetchTokenBalance = (tokenAddress: string | undefined) => {
    if (!tokenAddress || !user) {
      return 0;
    }
    getTokenBalance(
      config.supportStakingNetwork,
      tokenAddress,
      user.getAddress(),
    ).then((balance) => {
      setTokenBalance(balance);
    });
  };

  useEffect(() => {
    fetchTokenBalance(tokenDetails?.contractAddress);
  }, [tokenDetails?.contractAddress, user?.getAddress(), poolAddress]);

  const LitsItem = ({ name = '', value = '' }) => {
    return (
      <div className={styles['view-row']}>
        <div className={styles['aside']}>
          <span className={styles['adornment']}></span>
          <span className={styles['label']}>{name}</span>
        </div>
        <div className={`${styles['aside']} ${styles['grow']}`}>
          <div className={styles['overflow-ellip']}>
            <span className={styles['value']}>{value}</span>
          </div>
        </div>
      </div>
    );
  };

  const changeAmountInput = (value: string) => {
    if (+value < 0) {
      setAmount('0');
      return;
    }

    if (+value > +convertWeiToDec(tokenBalance, tokenDetails?.decimals)) {
      setAmount(convertWeiToDec(tokenBalance, tokenDetails?.decimals));
      return;
    }

    setAmount(value);
  };

  const handleClose = () => {
    onClose && onClose();
    setAmount('');
  };

  return (
    <BaseModal
      title={'Stake BUNI Token'}
      onClose={handleClose}
      onActionLeft={handleClose}
      onActionRight={stake}
      fullScreenMobile
      {...props}
    >
      <div className={styles['container-list']}>
        <LitsItem name="Token" value={tokenDetails?.symbol} />
        {tokenBalance && (
          <LitsItem
            name="Available balance"
            value={formatWeiNumber(tokenBalance)}
          />
        )}
        <LitsItem
          name="Total BUNI staked"
          value={formatWeiNumber(
            (Number(lpStake) * buniLPRatio + Number(buniStake)).toString(),
          )}
        />
        {!!myTier && (
          <LitsItem
            name="Current Tier"
            value={formatTierAsString(myTier.tier)}
          />
        )}
        <LitsItem
          name={`${tokenDetails?.symbol} staked in the pool`}
          value={formatWeiNumber(stakingAmount)}
        />
        <LitsItem
          name="BUNI staked to next tier"
          value={formatWeiNumber(buniToNextTier?.toString() || '')}
        />
      </div>
      <div className={styles['token-rate']}>
        (Conversation rate: 1 BPT = {formatNumber(buniLPRatio)} BUNI)
      </div>
      <div className={styles['input-control']}>
        <span className={styles['label']}>Stake amount</span>
        <AppInputTokenMax
          tokenDetails={tokenDetails}
          icon={
            tokenDetails?.symbol?.includes('BUNI') ? (
              <img src="/images/BuniLogo.svg" alt="" />
            ) : (
              ''
            )
          }
          value={amount}
          handleChange={changeAmountInput}
        />
      </div>
    </BaseModal>
  );
};

export default ModalStakeFarm;
