import { useMemo, useState } from 'react';
import styles from 'src/styles/components/MyPool.module.scss';
import MyIDOPool from 'src/components/profile/MyIDOPool';
import rf from 'src/requests/RequestFactory';
import AppDropdown from 'src/components/AppDropdown';
import { SearchIcon } from 'src/assets/icons';
import { isMobile } from 'react-device-detect';
import { PoolResponseType } from 'src/utils/pool';
import AppInput from 'src/components/AppInput';
import AppDataTable from 'src/components/AppDataTable';

const TAB_MY_POOL = {
  IDO: 0,
  INO: 1,
};

type MyPoolResponseType = {
  id: string;
  poolId: string;
  userAddress: string;
  userInfo: {
    tier: string;
    lotteryTickets: number;
  };
  pool: PoolResponseType;
};

const POOL_TYPE_OPTIONS = [
  {
    value: TAB_MY_POOL.IDO,
    label: 'IDO Pools',
  },
  {
    value: TAB_MY_POOL.INO,
    label: 'INO Pools',
  },
];

const PartMyPools = () => {
  const [valueSearch, setValueSearch] = useState<string>('');
  const [activeTab, setActiveTab] = useState<number>(TAB_MY_POOL.IDO);
  /** start:: state for mobile */
  const [isSearch, setIsSearch] = useState<boolean>(false);
  /** end:: state for mobile */

  const handleSearch = (value: string) => {
    setValueSearch(value);
  };

  const isActiveTab = (tab: number) => {
    return activeTab === tab;
  };

  const requestParams = useMemo(
    () => ({
      searchKey: valueSearch,
      poolType: isActiveTab(TAB_MY_POOL.IDO) ? 'IDO' : 'INO',
    }),
    [valueSearch, activeTab],
  );

  const _renderForMobile = () => {
    return (
      <div className={styles['filter-bar']}>
        {!isSearch && (
          <AppDropdown
            className={styles['select-tab']}
            value={activeTab}
            onChange={(e: any) => setActiveTab(e as number)}
            options={POOL_TYPE_OPTIONS}
            variant="outlined"
          />
        )}
        <div className={styles['field-search']}>
          {isSearch && (
            <div className={`${styles['type-search']}`}>
              <AppInput
                placeholder="Search"
                handleChange={handleSearch}
                value={valueSearch}
                endAdornment={<SearchIcon />}
              />
            </div>
          )}
          <button
            className={styles['btn-search']}
            onClick={() => {
              setIsSearch((isSearch) => !isSearch);
            }}
          >
            <SearchIcon />
          </button>
        </div>
      </div>
    );
  };

  const _renderForDesktop = () => {
    return (
      <>
        <div className={styles['header']}>
          <div className={styles['title']}>My Pool</div>
        </div>
        <div className={styles['tab-search']}>
          <div className={styles['tabs-project']}>
            <div
              className={`
                  ${styles['tabs-item']} 
                  ${styles[isActiveTab(TAB_MY_POOL.IDO) ? 'active' : '']}
                `}
              onClick={() => setActiveTab(TAB_MY_POOL.IDO)}
            >
              IDO Pools
            </div>
            <div
              className={`
                  ${styles['tabs-item']} 
                  ${styles[isActiveTab(TAB_MY_POOL.INO) ? 'active' : '']}
                `}
              onClick={() => setActiveTab(TAB_MY_POOL.INO)}
            >
              INO Pools
            </div>
          </div>
          <div>
            <AppInput
              className={styles['input-search']}
              placeholder="Search"
              handleChange={handleSearch}
              value={valueSearch}
              endAdornment={<SearchIcon />}
            />
          </div>
        </div>
      </>
    );
  };
  return (
    <section className={`${styles[isMobile ? 'mypool-mobile' : 'my-pool']}`}>
      {isMobile ? _renderForMobile() : _renderForDesktop()}
      <AppDataTable
        wrapperClassName={styles['pool-list']}
        requestParams={requestParams}
        limit={10}
        fetchData={(payload) =>
          rf.getRequest('PoolRequest').getMyPools(payload)
        }
        renderHeader={() => {
          if (isMobile) {
            return null;
          }
          return (
            <div className={`${styles['header-pool-list']} row`}>
              <div className="col-md-3">Pool Name</div>
              <div className={`${styles['header-step']} col-md-5`}>
                <div>Whitelist</div>
                <div className={styles['header-token-sale']}>Swap</div>
                <div>Rewards Claim</div>
              </div>
              <div className={`${styles['header-invest']} col-md-2`} />
              <div className={`${styles['header-invest']} col-md-2`}>
                Invest
              </div>
            </div>
          );
        }}
        renderBody={(pools: MyPoolResponseType[]) =>
          pools.map((pool: MyPoolResponseType) => {
            return <MyIDOPool key={pool.id} pool={pool.pool} />;
          })
        }
      />
    </section>
  );
};

export default PartMyPools;
