import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import BasePage from 'src/pages/BasePage';
import styles from 'src/styles/pages/Profile.module.scss';
import { withWidth } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import PartMyPools from './parts/PartMyPools';
import PartMyLBPs from './parts/PartMyLBP';
import PartAccountInfo from './parts/PartAccountInfo';
import ModalConnectWallet from 'src/modals/ModalConnectWallet';

const TAB_PROFILE = {
  profile: 0,
  myPool: 1,
  myLBP: 2,
};

const Profile = () => {
  const [activeTab, setActiveTab] = useState<number>(TAB_PROFILE.profile);

  const isActiveTab = (tab: number) => {
    return tab === activeTab;
  };

  const _renderForMobile = () => {
    return (
      <>
        <div className={styles['tabs-account']}>
          <nav className={styles['tab-nav']}>
            <ul className={styles['tab-list']}>
              <li
                className={`${styles['tab-item']} ${
                  isActiveTab(TAB_PROFILE.profile) ? styles['tab-active'] : ''
                }`}
                onClick={() => setActiveTab(TAB_PROFILE.profile)}
              >
                Profile
              </li>
              <li
                className={`${styles['tab-item']} ${
                  isActiveTab(TAB_PROFILE.myPool) ? styles['tab-active'] : ''
                }`}
                onClick={() => setActiveTab(TAB_PROFILE.myPool)}
              >
                My Pool
              </li>
              <li
                className={`${styles['tab-item']} ${
                  isActiveTab(TAB_PROFILE.myLBP) ? styles['tab-active'] : ''
                }`}
                onClick={() => setActiveTab(TAB_PROFILE.myLBP)}
              >
                My LBP
              </li>
            </ul>
          </nav>
        </div>
        <div
          className={styles['box-info'] + ' row'}
          style={{
            display: isActiveTab(TAB_PROFILE.profile) ? 'flex' : 'none',
          }}
        >
          <PartAccountInfo />
        </div>

        <div
          style={{
            display: isActiveTab(TAB_PROFILE.myPool) ? 'block' : 'none',
          }}
        >
          <PartMyPools />
        </div>

        <div
          style={{
            display: isActiveTab(TAB_PROFILE.myLBP) ? 'block' : 'none',
          }}
        >
          {/*<PartMyLBPs />*/}
        </div>
      </>
    );
  };

  const _renderForDesktop = () => {
    return (
      <>
        <div
          className={styles['box-info'] + ' row'}
          style={{ display: 'flex' }}
        >
          <PartAccountInfo />
          <ModalConnectWallet />
        </div>
        <PartMyPools />
        {/*<PartMyLBPs />*/}
      </>
    );
  };

  return (
    <BasePage isPrivate>
      <div className={styles['profile']}>
        {isMobile ? _renderForMobile() : _renderForDesktop()}
      </div>
    </BasePage>
  );
};

export default withWidth()(withRouter(Profile));
