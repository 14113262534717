import { Link } from 'react-router-dom';
import {
  DiscordIcon,
  MediumIcon,
  TelegramIcon,
  TwitterIcon,
  WebIcon,
} from 'src/assets/icons';
import config from 'src/config';
import styles from 'src/styles/layout/Footer.module.scss';
import { withWidth } from '@material-ui/core';
import { withRouter } from 'react-router';

const footerLinks = [
  {
    title: 'General',
    links: [
      {
        text: 'Apply for IDO',
        link: 'https://forms.gle/3eioR7RsjxLSBjFZ6',
      },
      {
        text: 'Terms of use',
        link: '/pdf/terms-of-use.pdf',
      },
      {
        text: 'Blog',
        link: 'https://blog.buni.finance/',
      },
    ],
  },
  {
    title: 'Help',
    links: [
      {
        text: 'Documentation',
        link: 'https://bunicornswap.medium.com/',
      },
      {
        text: 'How to participate in IDO',
        link: 'https://app.gitbook.com/o/-M_orTZyXJ1FRRspcLsz/s/0jEFbwARmohbyeeSC5YD/',
      },
      {
        text: 'Press Kit',
        link: 'https://app.gitbook.com/o/-M_orTZyXJ1FRRspcLsz/s/0jEFbwARmohbyeeSC5YD/press-kit',
      },
    ],
  },
];

const TheFooter = () => {
  return (
    <footer className={styles['footer']}>
      <div className={styles['footer-top']}>
        <div className={styles['intro-footer']}>
          <div className={styles['intro-content']}>
            <Link to={'/'} className={styles['logo']}>
              <img src={`/images/header/img-logo.svg`} alt="" />
            </Link>
            <p>
              Metaverse Starter - Accelerate Growth at Any Scale.
              <br/>
              Bring out your projects. Reinforce your success. Experience seamless, multichain solutions.
            </p>
          </div>
        </div>
        {footerLinks.map((item, index) => (
          <div
            className={`${styles['list-link-footer']} ${
              index !== 0 ? styles['lg-right'] : ''
            }`}
            key={index}
          >
            <div>
              <h4>{item.title}</h4>
              <ul>
                {item.links.map((m, i) => (
                  <li key={i}>
                    <a
                      href={m.link}
                      className={styles['text-link-footer']}
                      rel="noreferrer"
                      target="_blank"
                    >
                      {m.text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
      <div className={styles['footer-bottom']}>
        <div className={styles['list-link-social']}>
          <ul>
            <li>
              <a
                href={config.socialLinks.medium}
                target="_blank"
                rel="noreferrer"
              >
                <MediumIcon />
              </a>
            </li>
            <li>
              <a
                href={config.socialLinks.telegram}
                target="_blank"
                rel="noreferrer"
              >
                <TelegramIcon />
              </a>
            </li>
            <li>
              <a
                href={config.socialLinks.twitter}
                target="_blank"
                rel="noreferrer"
              >
                <TwitterIcon />
              </a>
            </li>
            <li>
              <a
                href={config.socialLinks.discord}
                target="_blank"
                rel="noreferrer"
              >
                <DiscordIcon />
              </a>
            </li>
            <li>
              <a
                href={config.socialLinks.globe}
                target="_blank"
                rel="noreferrer"
              >
                <WebIcon />
              </a>
            </li>
          </ul>
        </div>
        <div className={styles['copyright']}>
          <p>&copy; Copyright Metaversestarter 2022.&nbsp;</p>
          <p>All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default withWidth()(withRouter(TheFooter));
